const EyeIcon = () => {
  return (
    <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.6005 5.58283C16.0542 2.11052 12.6976 0.374359 9.34104 0.374359C5.98448 0.374359 2.62791 2.11052 0.081543 5.58283C5.17427 12.5275 13.5078 12.5275 18.6005 5.58283ZM6.33171 2.68923C6.04235 3.21008 5.86873 3.7888 5.86873 4.42539C5.86873 6.33516 7.43127 7.8977 9.34104 7.8977C11.2508 7.8977 12.8134 6.33516 12.8134 4.42539C12.8134 3.7888 12.6397 3.21008 12.3504 2.68923C13.7972 3.26795 15.1861 4.25178 16.4014 5.58283C14.3759 7.83983 11.9453 9.05514 9.39892 9.05514C6.85255 9.05514 4.36406 7.83983 2.33855 5.58283C3.49598 4.25178 4.88491 3.26795 6.33171 2.68923Z"
        fill="#364FB3"
      />
    </svg>
  );
};

export default EyeIcon;
