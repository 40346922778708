import { makeStyles } from "@mui/styles";

import pxToRem from "assets/theme/functions/pxToRem";

export default makeStyles(({ breakpoints }) => {
  return {
    card_Box: {
      margin: `${pxToRem(16)} 0 ${pxToRem(16 * 3)}`,
      padding: pxToRem(16),
      paddingRight: 0,
      minHeight: "450px",
      [breakpoints.down("sm")]: {
        paddingLeft: 0,
        paddingTop: 0,
      },
    },
    side_card_Box: {
      boxShadow: "none",
      backgroundColor: "#F2F4FF",
      height: "100%",
      minHeight: "418px",
      overflow: "hidden",
      padding: pxToRem(10),
      [breakpoints.down("sm")]: {
        minHeight: "unset",
        padding: pxToRem(8),
        borderRadius: "0.7rem 0.7rem 0.3rem 0.3rem",
      },
    },
    form_box: {
      padding: `${pxToRem(12)} ${pxToRem(16)}`,
      overflowY: "overlay",
      height: "100%",
    },
  };
});
