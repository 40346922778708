import { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale);

import configs from "../Config";
import BoxLegends from "../ChartLegends/BoxLegends";
import SuiTypography from "components/SuiTypography";

const DefaultDidStatusChart = ({ height, chart, id, status, statuses }) => {
  const { data } = configs(chart.labels, chart.datasets, chart.cutout);
  const myChartRef = useRef(null);
  const updatedOptions = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: function (tooltipItem) {
              const dataset = tooltipItem.dataset;
              const dataIndex = tooltipItem.dataIndex;
              const label = tooltipItem.label;
              if (label === "On Hold") {
                return [
                  `Waiting for payment: ${status || "N/A"}`,
                  `Request for removal: ${statuses || "N/A"}`,
                ];
              } else {
                const value = dataset.data[dataIndex];
                return `${value !== undefined ? value : "N/A"}`;
              }
            },
          },
          backgroundColor: "#000000bd",
          titleFont: { size: 14, weight: 400 },
          bodyFont: { size: 14 },
          boxShadow: "0px 6px 14px 0px #EDEDED",
          displayColors: false,
          titleMarginBottom: 8,
        },
      },
      scales: {
        y: {
          display: false,
        },
      },
    }),
    [status, statuses]
  );

  const centerText = {
    id: `centerText${id}`,
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      const xCoor = chart.getDatasetMeta(0).data[0]?.x;
      const yCoor = chart.getDatasetMeta(0).data[0]?.y;
      const total = data.datasets[0]?.data.reduce((totalVal, num) => totalVal + num, 0);
      ctx.save();
      ctx.font = "800 32px Poppins";
      ctx.fillStyle = "#FFFFFF";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      if (xCoor && yCoor) {
        ctx.fillText(total, xCoor, yCoor - 25);
        ctx.font = "400 12px Poppins";
        ctx.fillText("Total", xCoor, yCoor + 10);
      }
      ctx.restore();
    },
  };
  const toggleLegend = (e, index) => {
    const myChart = myChartRef.current;
    if (myChart) {
      myChart.legend.chart.toggleDataVisibility(index);
      myChart.update();
    }
  };
  const renderChart = (
    <Grid container spacing={1} alignItems="center" py={1}>
      <Grid item xs={12} sx={{ paddingTop: "0 !important", paddingBottom: "10px" }}>
        <SuiTypography
          textAlign={"left"}
          variant="h6"
          fontWeight={600}
          textColor="white"
          sx={{
            color: "#fff",
          }}
        >
          DID Inventory
        </SuiTypography>
      </Grid>
      <Grid item xs={12} height={235} pt="2">
        <Box p={1} sx={{ height: "100%", display: "flex", justifyContent: "center" }}>
          {useMemo(
            () => (
              <Doughnut
                id={id}
                ref={myChartRef}
                data={data}
                options={updatedOptions}
                plugins={[centerText]}
                style={{ boxSizing: "content-box" }}
              />
            ),
            [chart, height]
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={2}
          pt={0}
          alignItems="center"
          justifyContent="center"
          className="set-text-graph-sub-100"
        >
          {chart.labels.map((label, index) => (
            <Grid item xs={12} lg={4} key={index}>
              <BoxLegends
                id={`${id}_${index}`}
                color={chart.datasets.backgroundColors[index]}
                label={label}
                onLegendFn={(e) => toggleLegend(e, index)}
                labelTextColor={`#F2F4FF`}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  return renderChart;
};

DefaultDidStatusChart.defaultProps = {
  height: "100%",
};

DefaultDidStatusChart.propTypes = {
  chart: PropTypes.shape({
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    datasets: PropTypes.shape({
      backgroundColors: PropTypes.arrayOf(PropTypes.string).isRequired,
      data: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
    cutout: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
};

export default DefaultDidStatusChart;
