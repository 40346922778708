import colors from "../../base/colors";
import borders from "../../base/borders";
import pxToRem from "../../functions/pxToRem";
import boxShadow from "../../functions/boxShadow";

const { inputColors, white } = colors;
const { borderWidth, borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      display: "flex !important",
      padding: 0,
      "& fieldset": {
        border: "none",
      },
    },

    input: {
      height: pxToRem(25),
      padding: `${pxToRem(8)} ${pxToRem(12)}`,
      color: inputColors.borderColor.focus,
      backgroundColor: `${inputColors.borderColor.main}`,
      border: `${borderWidth[2]} solid ${inputColors.borderColor.main}`,
      borderRadius: `${borderRadius.md}`,
      "&:focus": {
        backgroundColor: `${white.main}`,
        border: `${borderWidth[2]} solid ${inputColors.borderColor.focus}`,
      },
      "&:disabled": {
        backgroundColor: inputColors.borderColor.main,
        opacity: "0.5",
      },
      "&:read-only:not(.MuiSelect-select)": {
        backgroundColor: inputColors.borderColor.main,
        opacity: "0.7",
      },
      "&:-webkit-autofill": {
        borderRadius: `${borderRadius.md}`,
        WebkitBoxShadow: `${boxShadow(
          inputColors.borderColor.main,
          1,
          [0, 0],
          [0, 30],
          "inset"
        )} !important`,
        "&:focus": {
          WebkitBoxShadow: `${boxShadow(white.main, 1, [0, 0], [0, 30], "inset")} !important`,
        },
      },
    },

    inputSizeSmall: {
      height: pxToRem(22),
    },
    inputSizeLarge: {
      height: pxToRem(40),
    },
  },
};
