const DidIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ marginLeft: "10px", paddingTop: "5px" }}
      width="24"
      height="19"
      viewBox="0 0 24 19"
      fill="none"
    >
      <path
        d="M13.6706 3.96738L9.07401 7.43127L4.41469 3.84902L4.89697 3.32252C5.74386 2.40313 7.02985 2.04321 8.23303 2.39015L13.6706 3.96738ZM22.1911 4.89298L18.7717 7.79336L19.3103 15.1582C19.4061 16.666 18.6069 17.9528 17.417 18.1729L15.8437 18.4639L14.0064 11.3901L8.33353 15.3582C7.03067 16.3416 5.24001 16.3372 3.93025 15.3592L0.50384 12.7794C0.113862 12.4854 0.021834 11.9329 0.304151 11.5349L0.408336 11.3835C0.632734 11.0572 1.03299 10.912 1.4099 11.0253L3.82609 11.7173C4.37487 11.8802 4.97469 11.7693 5.42889 11.4209L18.959 1.21961C20.1814 0.240936 22.2675 -0.246653 23.2331 1.06968C23.9274 2.01923 23.7326 3.5502 22.1911 4.89298ZM21.3682 2.25872C21.0169 2.00846 20.2379 1.75593 19.0017 2.87954L19.9341 3.90707L21.374 2.67461C21.5051 2.55883 21.4982 2.35671 21.3682 2.25872Z"
        fill="#55DED0"
      />
    </svg>
  );
};

export default DidIcon;
