import pxToRem from "../../functions/pxToRem";

export default {
  styleOverrides: {
    root: {
      display: "flex !important",
      padding: 0,
      "& fieldset": {
        border: "none",
      },
    },

    input: {
      height: pxToRem(25),
      padding: `${pxToRem(8)} ${pxToRem(12)}`,
      "&:disabled": {
        backgroundColor: "#E5EBED",
        opacity: "0.7",
      },
    },

    inputSizeSmall: {
      height: pxToRem(22),
    },
    inputSizeLarge: {
      height: pxToRem(40),
    },
  },
};
