const DeleteIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="19.471" viewBox="0 0 15 19.471">
      <path
        id="Union_38"
        d="M3.823,19.471A2.461,2.461,0,0,1,1.4,16.985V6.665H1.21A1.247,1.247,0,0,1,0,5.421V3.382A1.215,1.215,0,0,1,1.21,2.139h3V1.368A1.362,1.362,0,0,1,5.54,0H9.46A1.362,1.362,0,0,1,10.79,1.368v.771h3A1.215,1.215,0,0,1,15,3.382V5.421a1.215,1.215,0,0,1-1.21,1.243H13.6v10.32a2.461,2.461,0,0,1-2.419,2.487ZM2.371,16.985a1.477,1.477,0,0,0,1.452,1.492h7.355a1.477,1.477,0,0,0,1.452-1.492V6.665H2.371Zm-1.4-13.6V5.421a.232.232,0,0,0,.242.249H13.79a.232.232,0,0,0,.242-.249V3.382a.232.232,0,0,0-.242-.249H1.21A.232.232,0,0,0,.968,3.382Zm4.21-2.014v.771H9.8V1.368A.377.377,0,0,0,9.435.995H5.54A.377.377,0,0,0,5.177,1.368Zm4.331,14.7V8.8h.968v7.261Zm-2.492,0V8.8h.968v7.261Zm-2.492,0V8.8h.968v7.261Z"
        fill="#fc3c3c"
      />
    </svg>
  );
};

export default DeleteIcon;
