import { baseAPIService } from ".";

const InvoiceApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    InvoiceDetails: build.mutation({
      query: ({ params, id }) => {
        return {
          url: `admin/subscription/invoices/${id}`,
          method: "GET",
          params: params,
        };
      },
    }),
  }),
  overrideExisting: true,
});
export const { useInvoiceDetailsMutation } = InvoiceApi;
