import { makeStyles } from "@mui/styles";

export default makeStyles(({ palette }) => ({
  breadcrumbs: {
    "&> .MuiBreadcrumbs-ol": {
      flexWrap: "nowrap",
      alignItems: "flex-start",
    },
    "& .MuiBreadcrumbs-separator": {
      color: ({ light }) => (light ? palette.white.main : palette.grey[600]),
    },
  },
}));
