import { Toolbar, Typography } from "@mui/material";
import SuiBox from "components/SuiBox";

const TableToolbar = ({ title, children }) => {
  return (
    <Toolbar
      sx={{
        px: 1,
        justifyContent: "space-between",
        flexWrap: "wrap",
        rowGap: 2,
        mb: { xs: 2, sm: 0 },
      }}
    >
      <Typography color="text.main" fontSize={{ xs: 16, md: 18 }} fontWeight={600} component="h5">
        {title}
      </Typography>
      <SuiBox ml="auto">{children}</SuiBox>
    </Toolbar>
  );
};

export default TableToolbar;
