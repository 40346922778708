import React from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

const StatusSelector = ({ id, selectData, onStateChange }) => {
  const style = {
    "& .MuiPaper-root": {
      p: "8px",
      borderRadius: "4px",
      bgcolor: "#2B2B2Bde !important",
      boxShadow: "0px 6px 14px 0px #EDEDED",
      "& .MuiMenuItem-root": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 5,
        borderRadius: "4px !important",
        border: "none",
        minWidth: 98,
        color: "#fff",
        "&:hover, &:focus": {
          bgcolor: "#2B2B2B",
        },
        "&:last-of-type": {
          borderBottom: "0",
        },
      },
    },
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        id={`expand-status-button-${id}`}
        aria-controls={open ? `status-menu-${id}` : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={{ bgcolor: "#e2e5e6 !important", p: 0 }}
        aria-label="Expand-status"
      >
        {anchorEl ? (
          <ExpandLessIcon sx={{ width: 18, height: 18 }} />
        ) : (
          <ExpandMoreIcon sx={{ width: 18, height: 18 }} />
        )}
      </IconButton>
      <Menu
        id={`status-menu-${id}`}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": `expand-status-button-${id}`,
        }}
        sx={style}
      >
        {selectData.map((item) => (
          <MenuItem
            key={item.id}
            component="div"
            onClick={() => onStateChange({ id, val: item.number })}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StatusSelector;
