import {
  Card,
  FormLabel,
  Grid,
  TableCell,
  TableRow,
  Typography,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import { orderTableHeader } from "../data";
import dayjs from "dayjs";
import SuiButton from "components/SuiButton";
import { Link, useParams } from "react-router-dom";
import SuiSearchBox from "components/SuiSearchBox";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import { useFormik } from "formik";
import SuiDateRangePicker from "components/SuiDateRangePicker";
import { useGetTransactionDetailsMutation } from "app/features/api/CustomerDetailsApi";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setTransactionDetails } from "app/features/CustomeDetailsSlice";
import useNavigateSearch from "hooks/useNavigateSearch";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import SuiDialog from "components/SuiDialog";
import SuiTypography from "components/SuiTypography";
import { useGetOrderDetailsMutation } from "app/features/api/CustomerDetailsApi";
import { setOrderDetails } from "app/features/CustomeDetailsSlice";
import Nodata from "./Nodata";
import colors from "assets/theme/base/colors";
import CommonPagination from "components/SuiTablePagination/CommonPagination";

const PopupItems = ({ heading, children }) => (
  <Grid container justifyContent="start" py={3} px={1.5} rowSpacing={2}>
    <Grid item>
      <SuiTypography
        px={2}
        py={0.5}
        bgcolor={colors.primary.main}
        borderRadius="20px"
        fontSize={14}
      >
        {heading}
      </SuiTypography>
    </Grid>
    <Grid item xs={12}>
      <Grid container justifyContent="start" rowSpacing={2}>
        {children}
      </Grid>
    </Grid>
  </Grid>
);

const PopupItem = ({ title, subTitle, channel }) => (
  <Box display="flex" alignItems="start" justifyContent="space-between" gap={1.5} flexWrap="wrap">
    <SuiTypography variant="h6" fontSize={{ xs: 14, md: 15, xl: 16 }}>
      {title}
    </SuiTypography>
    <SuiTypography
      component="p"
      variant="body2"
      sx={{ color: `${colors.text.focus} !important` }}
      fontSize={{ xs: 13, md: 14.5, xl: 14 }}
    >
      {channel}
    </SuiTypography>
    <SuiTypography
      component="p"
      variant="body2"
      fontSize={{ xs: 13, md: 14.5, xl: 14 }}
      sx={{ color: `${colors.text.focus} !important` }}
    >
      {subTitle}
    </SuiTypography>
  </Box>
);

const OrderDetails = () => {
  const [count, setCount] = useState(0);
  const [dialog, setDialog] = useState({ open: false, order_id: "" });
  const { id, accnt_num } = useParams();
  const dispatch = useDispatch();
  const { transactions, orders } = useSelector((state) => state.customerDetails);
  const navigateSearch = useNavigateSearch();
  let page = new URLSearchParams(window.location.search).get("page");
  const cus_id = localStorage.getItem("cus_id");
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const formik = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      order_id: "",
      transaction_id: "",
      payment_mode: "",
      payment_status: "",
    },
    // enableReinitialize: true,
    onSubmit: () => {
      getTransactionDetailsFunc(1);
    },
  });

  const handleDateChange = (a) => {
    if (a?.length) {
      formik.setFieldValue("startDate", new Date(a[0]));
      formik.setFieldValue("endDate", new Date(a[1]));
    } else {
      formik.setFieldValue("startDate", null);
      formik.setFieldValue("endDate", null);
    }
  };
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("", { page: 1 });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("", { page: page });
  };
  const [getTransactionDetails, { isLoading: transactionDetailsLoading }] =
    useGetTransactionDetailsMutation();
  const [getOrderDetails] = useGetOrderDetailsMutation();
  const getTransactionDetailsFunc = async (pageNumber, values = formik.values) => {
    navigateSearch(`/customer/edit-customer/order-details/${id}/${accnt_num}`, {
      page: pageNumber,
    });
    let params = { results_per_page: perPage, page: pageNumber };
    if (values.startDate) {
      params.order_start_date = dayjs(new Date(values.startDate)).format("YYYY-MM-DD");
    }

    if (values.endDate) {
      params.order_end_date = dayjs(new Date(values.endDate)).format("YYYY-MM-DD");
    }
    if (values.order_id) {
      params.order_id = values.order_id?.trim();
    }

    try {
      const response = await getTransactionDetails({
        id: id,
        cus_id: cus_id,
        params: params,
      }).unwrap();
      dispatch(setTransactionDetails(response.data.rows));
      setCount(response.data.count);
    } catch (error) {
      console.log(error);
      dispatch(setTransactionDetails([]));
      setCount(0);
    }
  };

  const getOrderDetailsFunc = async (id) => {
    setDialog({ open: true, order_id: id });
    try {
      const response = await getOrderDetails(id).unwrap();
      dispatch(setOrderDetails(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  let sipOrderLogs = [];
  let sipCallBundleOrderLogs = [];
  const siplogs = orders?.map((order) => {
    if (order?.order_sip_username !== null && order?.order_sip_channels !== null) {
      sipOrderLogs.push({
        sipName: order?.order_sip_username,
        status: order?.order_logs_status,
        sipChannel: order?.order_sip_channels,
      });
      // setOrderLog(sipOrderLogs?.map((item) => item?.callBundle));
    } else {
      sipCallBundleOrderLogs.push({
        sipName: order?.order_sip_username,
        status: order?.order_logs_status,
        callBundle: order?.order_sip_call_bundle,
        callBundleChannel: order?.order_sip_call_bundle_units,
      });
    }
  });
  let didOrderLogs = [];
  const didlogs = orders?.map((order) => {
    if (order?.order_number !== null) {
      didOrderLogs.push({
        didNumber: order?.order_number,
        status: order?.order_logs_status,
      });
    }
  });

  console.log(siplogs, didlogs);
  const handleReset = () => {
    formik.handleReset();
    getTransactionDetailsFunc(1, {});
  };

  useEffect(() => {
    if (page) {
      getTransactionDetailsFunc(page);
    } else {
      getTransactionDetailsFunc(1);
    }
  }, [perPage, currentPage]);
  const rows =
    transactions && Array.isArray(transactions)
      ? transactions.map((transaction) => {
          return (
            <TableRow key={transaction.order_id}>
              <TableCell>
                {transaction.order_type === "checkout" && (
                  <ControlPointRoundedIcon
                    color="success"
                    sx={{ width: 20, height: 20, cursor: "pointer" }}
                    onClick={() => getOrderDetailsFunc(transaction.order_id)}
                  />
                )}
              </TableCell>
              <TableCell>{transaction.order_id}</TableCell>

              <TableCell>{transaction.order_type}</TableCell>

              <TableCell>{dayjs(new Date(transaction.createdAt)).format("DD/MM/YYYY")}</TableCell>
              <TableCell>{`£${transaction.order_total_price}`}</TableCell>

              <TableCell>
                <Typography
                  color={transaction.order_status === "Failed" ? "error.main" : "primary.focus"}
                  fontSize="inherit"
                >
                  {transaction.order_status}
                </Typography>
              </TableCell>
            </TableRow>
          );
        })
      : [];
  return (
    <>
      <SuiSearchBox title="Search">
        <SuiBox mx={1}>
          <Grid container component="form" spacing={2}>
            <Grid item xs={12} md={6} xxl={4}>
              <FormLabel>Order Date</FormLabel>
              <SuiDateRangePicker
                fullWidth
                placeholder="Order Date"
                value={
                  formik.values.startDate === "" && formik.values.endDate === ""
                    ? []
                    : formik.values.startDate === null && formik.values.endDate === null
                    ? []
                    : [new Date(formik.values.startDate), new Date(formik.values.endDate)]
                }
                onDateChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={12} md={6} xxl={4}>
              <FormLabel htmlFor="order_id">Order ID</FormLabel>
              <SuiInput
                inputSettungs={{ autoComplete: "off" }}
                type="search"
                placeholder="Enter Order ID"
                id="order_id"
                name="order_id"
                value={formik.values.order_id}
                onChange={formik.handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SuiButton
                type="submit"
                size="small"
                buttonColor="primary"
                sx={{ px: "1rem", marginRight: "14px" }}
                onClick={formik.handleSubmit}
                disabled={!formik.dirty}
              >
                Search
              </SuiButton>
              <SuiButton
                // type="reset"
                size="small"
                variant="outlined"
                buttonColor="dark"
                sx={{ px: "1rem", py: "0.4rem" }}
                // disabled={!formik.dirty}
                onClick={() => {
                  // formik.handleReset();
                  handleReset();
                }}
              >
                Reset
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiSearchBox>
      <Card sx={{ border: "1px solid #E9E9E9", boxShadow: "none", width: "100%", mt: 3 }}>
        <EnhancedTable
          customClass="no-border"
          tableMinWidth={870}
          tablehead={orderTableHeader}
          rowsData={rows}
          isLoading={transactionDetailsLoading}
        />
        {transactions.length > 0 && (
          <Grid
            container
            py={3}
            spacing={1.5}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "flex-end" }}
            alignItems="center"
          >
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <span>Show</span>
                </Grid>
                <Grid item>
                  {/* Select dropdown for items per page */}
                  <Select
                    value={perPage}
                    onChange={handlePerPageChange}
                    variant="standard"
                    sx={{ minWidth: 100 }}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <span>out of {count} Results</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CommonPagination
                mt={0}
                totalData={count}
                pageSize={perPage}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </Grid>
          </Grid>
        )}
        <SuiDialog
          open={dialog.open}
          onClose={() => {
            setDialog({ open: false, order_id: "" });
            dispatch(setOrderDetails([]));
          }}
          haveCloseIcon
          closeByBackdrop
        >
          <Grid
            container
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems="center"
            px={{ xs: 1.5, lg: 2 }}
            py={2}
          >
            <Grid item>
              <SuiTypography fontWeight="semiBold" variant="h6">
                Product Details for : {dialog.order_id}
              </SuiTypography>
            </Grid>
          </Grid>
          <hr style={{ borderColor: "#D5D5D5", marginTop: "0px", marginBottom: "0px" }} />
          <Box height="100%" sx={{ overflowY: "overlay" }}>
            {sipOrderLogs?.length === 0 &&
              didOrderLogs?.length === 0 &&
              sipCallBundleOrderLogs?.length === 0 && <Nodata />}
            {sipOrderLogs?.length > 0 && (
              <PopupItems heading="SIP Trunk">
                {sipOrderLogs?.map((order) => {
                  return (
                    <Grid item xs={12} key={order.sipName}>
                      <PopupItem
                        title={order.sipName}
                        subTitle={`Status: ${order?.status}`}
                        channel={`No. of Channels: ${order?.sipChannel}`}
                      />
                    </Grid>
                  );
                })}
              </PopupItems>
            )}
            {sipCallBundleOrderLogs?.length > 0 &&
              sipCallBundleOrderLogs.some((order) => order.callBundle !== null) && (
                <PopupItems heading="Call Bundles">
                  {sipCallBundleOrderLogs?.map((order) => {
                    if (order.callBundle !== null) {
                      return (
                        <Grid key={order.id} item xs={12}>
                          <PopupItem
                            title={`${order?.sipName} - ${order.callBundle}`}
                            subTitle={`Status: ${order?.status}`}
                            channel={`No. of Bundles: ${order?.callBundleChannel}`}
                            // sipTrunckName={`SIP Trunk: ${order?.sipName}`}
                          />
                        </Grid>
                      );
                    } else {
                      return null; // Skip rendering if callBundle is null
                    }
                  })}

                  {/* {order?.order_details?.dids?.map((did) => {
                        return (
                          <Grid item xs={12}>
                            <PopupItem
                              title={did.DDI}
                              // subTitle="State Name, City Name "
                              subTitle={`Range: ${did.RangeDynCount}`}
                            />
                          </Grid>
                        );
                      })} */}
                </PopupItems>
              )}
            {didOrderLogs?.length > 0 && (
              <PopupItems heading="DID Numbers">
                {didOrderLogs?.map((order) => {
                  return (
                    <Grid item xs={12} key={order.didNumber}>
                      <PopupItem
                        title={order.didNumber}
                        // subTitle="State Name, City Name "
                        subTitle={`Status: ${order.status}`}
                      />
                    </Grid>
                  );
                })}
              </PopupItems>
            )}
          </Box>
        </SuiDialog>
      </Card>
      <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
        <Grid item>
          <SuiButton
            size="small"
            component={Link}
            to="/customer"
            variant="outlined"
            buttonColor="dark"
            sx={{ px: "1rem", py: "0.4rem" }}
          >
            Cancel
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderDetails;
