const EyeOffIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" fill="none">
      <path
        d="M19.2809 8.74471C16.7346 5.2724 13.378 3.53624 10.0214 3.53624C6.66486 3.53624 3.30829 5.2724 0.761932 8.74471C5.85466 15.6893 14.1882 15.6893 19.2809 8.74471ZM7.01209 5.85111C6.72273 6.37196 6.54912 6.95068 6.54912 7.58727C6.54912 9.49704 8.11166 11.0596 10.0214 11.0596C11.9312 11.0596 13.4937 9.49704 13.4937 7.58727C13.4937 6.95068 13.3201 6.37196 13.0308 5.85111C14.4776 6.42983 15.8665 7.41365 17.0818 8.74471C15.0563 11.0017 12.6257 12.217 10.0793 12.217C7.53294 12.217 5.04445 11.0017 3.01894 8.74471C4.17637 7.41365 5.5653 6.42983 7.01209 5.85111Z"
        fill="#364FB3"
      />
      <path
        d="M15.2664 16.3078L3.96361 2.11248L5.69644 0.73274L16.9993 14.9281L15.2664 16.3078Z"
        fill="#F2F4FF"
      />
      <path
        d="M14.7527 16.4328L3.44982 2.23746L4.77637 1.18121L16.0792 15.3765L14.7527 16.4328Z"
        fill="#364FB3"
      />
    </svg>
  );
};

export default EyeOffIcon;
