import typography from "../../base/typography";
import borders from "../../base/borders";
import colors from "../../base/colors";
import pxToRem from "../../functions/pxToRem";
const { size, fontWeightMedium } = typography;
const { borderRadius } = borders;
const { text, white, transparent } = colors;
export default {
  styleOverrides: {
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "row",
      textAlign: "center",
      flexGrow: 1,
      maxWidth: "unset !important",
      minWidth: "unset !important",
      minHeight: "unset !important",
      fontSize: size.regular,
      fontWeight: fontWeightMedium,
      textTransform: "none",
      lineHeight: "inherit",
      backgroundColor: transparent.main,
      padding: `${pxToRem(8)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      color: `${text.main} !important`,
      marginBottom: pxToRem(10),
      opacity: "1 !important",
      transition: "all 100ms ease",
      "&:last-of-type": {
        marginBottom: 0,
      },
      "@media (max-width: 768px)": {
        fontSize: size.sm,
      },
      "@media (max-width: 576px)": {
        marginBottom: 0,
      },
      "&.Mui-selected": {
        color: `${text.main} !important`,
        fontWeight: fontWeightMedium,
        backgroundColor: `${white.main} !important`,
      },
    },

    labelIcon: {
      paddingTop: pxToRem(4),
    },
  },
};
