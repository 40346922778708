export const assignDIDsTableHeader = [
  {
    id: "1",
    align: "left",
    width: "150px",
    label: "DID Number",
  },
  {
    id: "2",
    align: "left",
    width: "150px",
    label: "First Name",
  },
  {
    id: "10",
    align: "left",
    width: "150px",
    label: "Last Name",
  },
  {
    id: "3",
    align: "left",
    width: "250px",
    label: "Customer Account number",
  },
  {
    id: "4",
    align: "left",
    width: "auto",
    label: "Provision to SRE",
  },
  {
    id: "5",
    align: "left",
    width: "150",
    label: "Provision to SIP",
  },
  {
    id: "6",
    align: "left",
    width: "auto",
    label: "SIP Server",
  },
  {
    id: "12",
    align: "left",
    width: "150px",
    label: "DID Number Price",
  },
  {
    id: "10",
    align: "left",
    width: "auto",
    label: "Porting Fees",
  },
  {
    id: "11",
    align: "left",
    width: "150px",
    label: "status",
  },

  {
    id: "7",
    align: "left",
    width: "auto",
    label: "Payment Status",
  },
  {
    id: "8",
    align: "left",
    width: "auto",
    label: "Last Update",
  },

  // {
  //   id: "9",
  //   align: "right",
  //   width: "auto",
  //   label: "Action",
  // },
];
export const GeoCodeHeader = [
  {
    id: "1",
    align: "left",
    width: "50px",
    label: "Caption",
  },
  {
    id: "2",
    align: "left",
    width: "50px",
    label: "Code",
  },
  {
    id: "3",
    align: "left",
    width: "50px",
    label: "Country",
  },
  // {
  //   id: "4",
  //   align: "right",
  //   width: "auto",
  //   label: "Action",
  // },
];
export const NonGeoCodeHeader = [
  {
    id: "1",
    align: "left",
    width: "50px",
    label: "Caption",
  },
  {
    id: "2",
    align: "left",
    width: "50px",
    label: "Code",
  },

  // {
  //   id: "4",
  //   align: "right",
  //   width: "auto",
  //   label: "Action",
  // },
];
