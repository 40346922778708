import { baseAPIService } from ".";

const DashboardApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    getDashboardInfo: build.mutation({
      query: (params) => {
        return {
          url: "admin/dashboard",
          method: "GET",
          params: params,
        };
      },
    }),
  }),
  overrideExisting: true,
});
export const { useGetDashboardInfoMutation } = DashboardApi;
