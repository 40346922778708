import { Box, FormLabel, Grid, Typography } from "@mui/material";
import { spendLimitSchema } from "Validation";
import { useSpendLimitDataMutation } from "app/features/api/AuthApi";
import { useGetSpendLimitDataMutation } from "app/features/api/AuthApi";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import NumberInput from "components/SuiInput/NumberInput";
import CommonNumberInput from "components/SuiInput/commonNumberInput";
import { useFormik } from "formik";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import React, { useEffect, useState } from "react";
// import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
const SpendLimit = () => {
  const [SpendLimitData] = useSpendLimitDataMutation();
  const [setError] = useState("");
  const [check, setSpendLimitData] = useState("");
  const [updatedLimit, setUpdatedLimit] = useState("");
  const { id } = useParams();
  const [getSpendLimitData] = useGetSpendLimitDataMutation();
  const snackbarFn = useSuiSnackbar();
  const spendLimitForm = useFormik({
    initialValues: {
      daily_limit: updatedLimit.cus_spend_limit_daily_amount
        ? updatedLimit.cus_spend_limit_daily_amount
        : check.cus_spend_limit_daily_amount,
      weekly_limit: updatedLimit.cus_spend_limit_weekly_amount
        ? updatedLimit.cus_spend_limit_weekly_amount
        : check.cus_spend_limit_weekly_amount,
      monthly_limit: updatedLimit.cus_spend_limit_monthly_amount
        ? updatedLimit.cus_spend_limit_monthly_amount
        : check.cus_spend_limit_monthly_amount,
      warning_cus_email: updatedLimit.cus_spend_limit_warning_email
        ? updatedLimit.cus_spend_limit_warning_email
        : check.cus_spend_limit_warning_email,
      warning_daily_limit: updatedLimit.cus_warning_spend_limit_daily
        ? updatedLimit.cus_warning_spend_limit_daily
        : check.cus_warning_spend_limit_daily,
      warning_weekly_limit: updatedLimit.cus_warning_spend_limit_weekly
        ? updatedLimit.cus_warning_spend_limit_weekly
        : check.cus_warning_spend_limit_weekly,
      warning_monthly_limit: updatedLimit.cus_warning_spend_limit_monthly
        ? updatedLimit.cus_warning_spend_limit_monthly
        : check.cus_warning_spend_limit_monthly,
    },
    enableReinitialize: true,
    validationSchema: spendLimitSchema,

    onSubmit: async (values) => {
      const body = {
        cus_spend_limit_warning_email: values.warning_cus_email,
        cus_spend_limit_daily_amount: Number(values.daily_limit),
        cus_spend_limit_weekly_amount: Number(values.weekly_limit),
        cus_spend_limit_monthly_amount: Number(values.monthly_limit),
        cus_warning_spend_limit_daily: Number(values.warning_daily_limit),
        cus_warning_spend_limit_weekly: Number(values.warning_weekly_limit),
        cus_warning_spend_limit_monthly: Number(values.warning_monthly_limit),
        id,
      };
      try {
        const response = await SpendLimitData({
          cus_spend_limit_warning_email: values.warning_cus_email,
          cus_spend_limit_daily_amount: Number(values.daily_limit),
          cus_spend_limit_weekly_amount: Number(values.weekly_limit),
          cus_spend_limit_monthly_amount: Number(values.monthly_limit),
          cus_warning_spend_limit_daily: Number(values.warning_daily_limit),
          cus_warning_spend_limit_weekly: Number(values.warning_weekly_limit),
          cus_warning_spend_limit_monthly: Number(values.warning_monthly_limit),
          id,
        }).unwrap();
        if (response.status === 200) {
          setUpdatedLimit(body);
          snackbarFn(response?.message, "success");
        } else {
          setError(response.data.message);
          // Toast({ type: "error", message: loginData.data.status.message });
        }
      } catch (error) {
        setError(error.data.message);
      }
    },
  });
  const getSpendDetails = async () => {
    try {
      const response = await getSpendLimitData(id);
      setSpendLimitData(response.data.data);
    } catch (error) {
      setError(error.data.message);
    }
  };
  useEffect(() => {
    getSpendDetails();
  }, []);

  return (
    <div>
      <Grid container component="form" justifyContent="space-evenly" spacing={2}>
        <Grid item xs={12} md={6} style={{ display: "block", justifyContent: "center" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container gap={1} sx={{ justifyContent: "center" }} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Typography fontSize={18} fontWeight="semiBold" sx={{ pb: 2 }}>
                    Spend Limit Details
                  </Typography>
                  <Grid sx={{ display: "flex", alignItems: "center", pb: 2 }}>
                    <FormLabel htmlFor="to_number" sx={{ marginRight: "10px", fontSize: "15px" }}>
                      {" "}
                      Daily:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    </FormLabel>
                    <NumberInput
                      type="spent"
                      name="daily_limit"
                      placeholder="daily limit"
                      value={spendLimitForm.values.daily_limit}
                      setValue={spendLimitForm.setFieldValue}
                      onChange={spendLimitForm.handleChange}
                      onBlur={spendLimitForm.handleBlur}
                      required
                    />
                  </Grid>
                  <Grid>
                    <ErrorMessageComponent
                      touched={spendLimitForm.errors.daily_limit}
                      error={spendLimitForm.errors.daily_limit}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container gap={1} sx={{ justifyContent: "center" }} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid sx={{ display: "flex", alignItems: "center", pb: 2 }}>
                    <FormLabel htmlFor="to_number" sx={{ marginRight: "10px", fontSize: "15px" }}>
                      {" "}
                      Weekly:{" "}
                    </FormLabel>
                    <NumberInput
                      type="spent"
                      name="weekly_limit"
                      placeholder="Weekly limit"
                      value={spendLimitForm.values.weekly_limit}
                      step={1}
                      max={100}
                      setValue={spendLimitForm.setFieldValue}
                      onChange={spendLimitForm.handleChange}
                      onBlur={spendLimitForm.handleBlur}
                      required
                    />
                  </Grid>

                  <Grid>
                    <ErrorMessageComponent
                      touched={spendLimitForm.errors.weekly_limit}
                      error={spendLimitForm.errors.weekly_limit}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container gap={1} sx={{ justifyContent: "center" }} spacing={2}>
                <Grid item xs={12} md={6}>
                  <Grid sx={{ display: "flex", alignItems: "center" }}>
                    <FormLabel htmlFor="to_number" sx={{ marginRight: "10px", fontSize: "15px" }}>
                      {" "}
                      Monthly:
                    </FormLabel>
                    {/* <FormLabel htmlFor="to_number"> Monthly </FormLabel> */}
                    <NumberInput
                      type="spent"
                      name="monthly_limit"
                      placeholder="Monthly limit"
                      value={spendLimitForm.values.monthly_limit}
                      step={1}
                      max={100}
                      setValue={spendLimitForm.setFieldValue}
                      onChange={spendLimitForm.handleChange}
                      onBlur={spendLimitForm.handleBlur}
                      required
                    />
                  </Grid>

                  <Grid>
                    <ErrorMessageComponent
                      touched={spendLimitForm.errors.monthly_limit}
                      error={spendLimitForm.errors.monthly_limit}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* Second section: email and corresponding limits */}
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize={18} fontWeight="semiBold" sx={{ pb: 2 }}>
                Warning Limit Details
              </Typography>
              <Grid sx={{ display: "flex", alignItems: "center", pb: 2 }}>
                <FormLabel htmlFor="to_number" sx={{ marginRight: "10px", fontSize: "15px" }}>
                  Email ID:{" "}
                </FormLabel>
                {/* <FormLabel htmlFor="email">Email ID</FormLabel> */}
                <Box position={"relative"}>
                  <SuiInput
                    type="email"
                    size="medium"
                    name="warning_cus_email"
                    id="warning_cus_email"
                    fullWidth
                    placeholder="Warning Email Id"
                    inputSettings={{
                      autoComplete: "off",
                    }}
                    inputProps={{
                      style: { textTransform: "none" },
                      sx: { pr: "4rem" },
                    }}
                    value={spendLimitForm.values.warning_cus_email}
                    onChange={spendLimitForm.handleChange}
                    onBlur={spendLimitForm.handleBlur}
                  />
                </Box>
              </Grid>
              <ErrorMessageComponent
                touched={spendLimitForm.errors.warning_cus_email}
                error={spendLimitForm.errors.warning_cus_email}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid sx={{ display: "flex", alignItems: "center", pb: 2 }}>
              <FormLabel htmlFor="to_number" sx={{ marginRight: "30px", fontSize: "15px" }}>
                Daily:{" "}
              </FormLabel>
              <CommonNumberInput
                name="warning_daily_limit"
                placeholder="Daily limit"
                value={spendLimitForm.values.warning_daily_limit}
                step={1}
                max={1000}
                setValue={spendLimitForm.setFieldValue}
                onChange={spendLimitForm.handleChange}
                onBlur={spendLimitForm.handleBlur}
                required
              />
            </Grid>
            <Grid>
              <ErrorMessageComponent
                touched={spendLimitForm.errors.warning_daily_limit}
                error={spendLimitForm.errors.warning_daily_limit}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid sx={{ display: "flex", alignItems: "center", pb: 2 }}>
              <FormLabel htmlFor="to_number" sx={{ marginRight: "10px", fontSize: "15px" }}>
                Weekly:{" "}
              </FormLabel>
              <CommonNumberInput
                name="warning_weekly_limit"
                placeholder="Weekly limit"
                value={spendLimitForm.values.warning_weekly_limit}
                step={1}
                max={1000}
                setValue={spendLimitForm.setFieldValue}
                onChange={spendLimitForm.handleChange}
                onBlur={spendLimitForm.handleBlur}
                required
              />
            </Grid>
            <Grid>
              <ErrorMessageComponent
                touched={spendLimitForm.errors.warning_weekly_limit}
                error={spendLimitForm.errors.warning_weekly_limit}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid sx={{ display: "flex", alignItems: "center" }}>
              <FormLabel htmlFor="to_number" sx={{ marginRight: "7px", fontSize: "15px" }}>
                Monthly:{" "}
              </FormLabel>
              <CommonNumberInput
                name="warning_monthly_limit"
                placeholder="Monthly limit"
                value={spendLimitForm.values.warning_monthly_limit}
                step={1}
                max={1000}
                setValue={spendLimitForm.setFieldValue}
                onChange={spendLimitForm.handleChange}
                onBlur={spendLimitForm.handleBlur}
                required
              />
            </Grid>
            <Grid>
              <ErrorMessageComponent
                touched={spendLimitForm.errors.warning_monthly_limit}
                error={spendLimitForm.errors.warning_monthly_limit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container gap={1} sx={{ mx: "auto", pt: 20 }} spacing={2}>
        <SuiButton
          size="small"
          variant="outlined"
          component={Link}
          to="/customer"
          buttonColor="dark"
          sx={{
            px: 2,
            py: 1.2,
            borderRadius: 1,
            border: "1px solid #E9E9E9 !important",
          }}
        >
          Cancel
        </SuiButton>
        <SuiButton
          type="submit"
          size="small"
          variant="contained"
          buttonColor="primary"
          sx={{
            px: 2,
            py: 1.2,
            borderRadius: 1,
            boxShadow: "none !important",
          }}
          onClick={(e) => {
            e.preventDefault();
            spendLimitForm.handleSubmit();
          }}
          disabled={!spendLimitForm.dirty}
        >
          Update
        </SuiButton>
      </Grid>
    </div>
  );
};

export default SpendLimit;
