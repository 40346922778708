const ProfileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 22 21" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5574 18.6135H4.2435C3.52734 18.6135 2.99782 17.9065 3.26866 17.2562C4.52245 14.2496 7.46824 12.5272 10.8999 12.5272C14.3326 12.5272 17.2784 14.2496 18.5322 17.2562C18.803 17.9065 18.2735 18.6135 17.5574 18.6135ZM6.75817 6.44082C6.75817 4.20307 8.61653 2.38326 10.8999 2.38326C13.1843 2.38326 15.0417 4.20307 15.0417 6.44082C15.0417 8.67856 13.1843 10.4984 10.8999 10.4984C8.61653 10.4984 6.75817 8.67856 6.75817 6.44082ZM20.9992 18.2442C20.2465 14.8369 17.8911 12.3222 14.7921 11.181C16.4344 9.88566 17.392 7.79092 17.04 5.49738C16.6322 2.83664 14.3722 0.707476 11.6455 0.397073C7.88008 -0.032014 4.68678 2.83872 4.68678 6.44082C4.68678 8.35802 5.59365 10.0662 7.00872 11.181C3.90773 12.3222 1.55434 14.8369 0.800652 18.2442C0.526766 19.4828 1.54623 20.6423 2.83958 20.6423H18.9603C20.2546 20.6423 21.2731 19.4828 20.9992 18.2442Z"
        fill="#2B2B2B"
      />
    </svg>
  );
};

export default ProfileIcon;
