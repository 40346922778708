import { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale);

import configs from "../Config";
import BoxLegends from "../ChartLegends/BoxLegends";
import SuiTypography from "components/SuiTypography";

const DefaultSubscriptionChart = ({ height, chart, id }) => {
  const { data, options } = configs(chart.labels, chart.datasets, chart.cutout);
  const updatedOptions = {
    ...options,
    scales: {
      y: {
        display: false,
      },
    },
  };
  const myChartRef = useRef(null);
  const centerText = {
    id: `centerText${id}`,
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      const xCoor = chart.getDatasetMeta(0).data[0]?.x;
      const yCoor = chart.getDatasetMeta(0).data[0]?.y;
      const total = data.datasets[0].data.reduce((totalVal, num) => totalVal + num, 0);
      ctx.save();
      ctx.font = "800 32px Poppins";
      ctx.fillStyle = "#FFFFFF";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.fillText(total, xCoor, yCoor - 25);
      ctx.font = "400 12px Poppins";
      ctx.fillStyle = "#FFFFFF";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.fillText("Total", xCoor, yCoor + 10);
    },
  };
  const toggleLegend = (e, index) => {
    const myChart = myChartRef.current;
    myChart.legend.chart.toggleDataVisibility(index);
    myChart.update();
  };
  const renderChart = (
    <Grid container spacing={1} alignItems="center" py={1}>
      <Grid item xs={12} sx={{ paddingTop: "0 !important", paddingBottom: "10px" }}>
        <SuiTypography
          textAlign={"left"}
          variant="h6"
          fontWeight={600}
          textColor="white"
          sx={{
            color: "#fff",
          }}
        >
          Subscriptions
        </SuiTypography>
      </Grid>
      <Grid item xs={12} height={235} pt="2">
        <Box p={1} sx={{ height: "100%" }}>
          {useMemo(
            () => (
              <Doughnut
                id={id}
                ref={myChartRef}
                data={data}
                options={updatedOptions}
                plugins={[centerText]}
                style={{ boxSizing: "content-box" }}
              />
            ),
            [chart, height]
          )}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1.5}
          pt={0}
          alignItems="center"
          justifyContent="center"
          className="set-text-graph-sub"
        >
          {chart.labels.map((label, index) => (
            <Grid item xs="auto" key={index}>
              <BoxLegends
                id={`${id}_${index}`}
                color={chart.datasets.backgroundColors[index]}
                label={label}
                onLegendFn={(e) => toggleLegend(e, index)}
                labelTextColor={`#F2F4FF`}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  return renderChart;
};

DefaultSubscriptionChart.defaultProps = {
  height: "100%",
};

DefaultSubscriptionChart.propTypes = {
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default DefaultSubscriptionChart;
