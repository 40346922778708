import {
  Card,
  // CircularProgress,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import { paymentTableHeader } from "../data";
import SuiButton from "components/SuiButton";
import { Link, useParams } from "react-router-dom";
import SuiSearchBox from "components/SuiSearchBox";
import SuiBox from "components/SuiBox";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setPaymentInvoieReport } from "app/features/CustomeDetailsSlice";
// import Nodata from "./Nodata";
import { usePaymentInvoiceReportMutation } from "app/features/api/AuthApi";
import { useDownloadpaymentInvoicePDFReportMutation } from "app/features/api/AuthApi";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import DatePickerUi from "components/SuiDateRangePicker/commonSingleDatePicker";
import { useDownloadpaymentInvoiceCDRReportMutation } from "app/features/api/AuthApi";
import DownloadIcon from "Container/Icons/DownloadIcon";
import useSuiSnackbar from "hooks/useSuiSnackbar";

const PaymentDetails = () => {
  const { accnt_num } = useParams();
  const [loadingStates, setLoadingStates] = useState({});

  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  //   const { paymentInvoice } = useSelector((state) => state.reports);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentInvoiceReport, { isLoading: isLoading }] = usePaymentInvoiceReportMutation();
  const [downloadpaymentInvoicePDFReport] = useDownloadpaymentInvoicePDFReportMutation();
  const [downloadpaymentInvoiceCDRReport] = useDownloadpaymentInvoiceCDRReportMutation();
  const formik = useFormik({
    initialValues: {
      date_range: "",
    },
    onSubmit: () => {
      handleSubmitForm();
    },
  });
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handleSubmitForm = async () => {
    let urlParams = {};
    if (formik.values.date_range) {
      urlParams.date = formik.values.date_range;
    }
    try {
      const response = await paymentInvoiceReport({ id: accnt_num, params: urlParams }).unwrap();
      dispatch(setPaymentInvoieReport(response?.data));
      setData(response?.data);
      setCount(response?.data?.length);
      //   formik.setFieldValue(
      //     "date_range",
      //     `${formik.values.date_range.split(" ")[0]} to ${
      //       formik.values.date_range.split(" ")[2]
      //     }`
      //   );
    } catch (error) {
      console.log(error);
      setData([]);
    }
  };
  const handleResetForm = async () => {
    try {
      const response = await paymentInvoiceReport({ id: accnt_num }).unwrap();
      dispatch(setPaymentInvoieReport(response?.data));
      setData(response?.data);
      setCount(response?.data?.length);
    } catch (error) {
      console.log(error);
      setData([]);
    }
  };
  const getCurrentPageData = () => {
    const startIndex = (currentPage - 1) * perPage;
    const endIndex = startIndex + perPage;
    return data.slice(startIndex, endIndex);
  };
  const currentPageData = getCurrentPageData();

  function formatDate(inputDate) {
    const year = inputDate.substring(0, 4);
    const month = inputDate.substring(4, 6);
    const day = inputDate.substring(6, 8);
    const date = new Date(`${year}-${month}-${day}`);
    const formattedDay = String(date.getDate()).padStart(2, "0");
    const formattedMonth = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const formattedYear = date.getFullYear();
    const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;
    return formattedDate;
  }

  useEffect(() => {
    handleSubmitForm();
  }, []);
  const suiSnackbar = useSuiSnackbar();

  const downloadPDF = async (ids, i) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [`${ids}-${i}`]: true,
    }));

    const body = { id: accnt_num, No: ids };
    try {
      const response = await (i === "cdr"
        ? downloadpaymentInvoiceCDRReport(body).unwrap()
        : downloadpaymentInvoicePDFReport(body).unwrap());
      const byteCharacters = atob(response?.data?.base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      if (i === "cdr") {
        link.setAttribute("download", "invoice.csv");
      } else {
        link.setAttribute("download", "invoice.pdf");
      }
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.log(error, "eror");
      suiSnackbar(error.data.message, "error");
    } finally {
      setLoadingStates((prevState) => ({
        ...prevState,
        [`${ids}-${i}`]: false,
      }));
    }
  };

  // Handle page change
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const currentDate = new Date();

  const twelveMonthsAgo = new Date(currentDate);
  twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 12);
  const dateString = twelveMonthsAgo;
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const year1 = currentDate.getFullYear();
  const month1 = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1 and pad with leading zero if needed
  const day1 = String(currentDate.getDate()).padStart(2, "0"); // Pad with leading zero if needed

  const formattedDateCurrnet = `${year1}${month1}${day1}`;
  // Format the date as "YYYYMMDD"
  const formattedDate = `${year}${month}${day}`;
  const filteredData = data.filter((item) => {
    const invoiceDate = item.InvoiceDate;

    return invoiceDate >= formattedDate && invoiceDate <= formattedDateCurrnet;
  });

  const rows =
    currentPageData.length === 0
      ? data.map((res, i) => (
          <TableRow key={`row${i}`}>
            <TableCell>{res.InvoiceNumber}</TableCell>
            <TableCell>{formatDate(res.InvoiceDate)}</TableCell>
            <TableCell>{formatDate(res.PeriodStart)}</TableCell>
            <TableCell>{formatDate(res.DueDate)}</TableCell>
            <TableCell>£ {res.AmountExclVAT}</TableCell>
            <TableCell>£ {res.AmountInclVAT}</TableCell>
            <TableCell align="left">
              <IconButton>
                <Grid item>
                  <SuiButton
                    size="1"
                    isLoading={loadingStates[`${res.InvoiceNumber}-invoice`] || false}
                    onClick={() => downloadPDF(res.InvoiceNumber, "invoice")}
                    buttonColor="info"
                    sx={{
                      fontSize: { xs: 14, lg: 16 },
                      fontWeight: 500,
                      py: 1.2,
                      borderRadius: 5,
                    }}
                    startIcon={<DownloadIcon size={17} color="light" />}
                  >
                    Invoice
                  </SuiButton>
                </Grid>
                {filteredData.some((item) => item.InvoiceNumber === res.InvoiceNumber) && (
                  <Grid item>
                    <SuiButton
                      isLoading={loadingStates[`${res.InvoiceNumber}-cdr`] || false}
                      onClick={() => downloadPDF(res.InvoiceNumber, "cdr")}
                      buttonColor="info"
                      sx={{
                        fontSize: { xs: 14, lg: 16 },
                        fontWeight: 500,
                        py: 1.2,
                        ml: 1,
                        borderRadius: 5,
                      }}
                      startIcon={<DownloadIcon size={17} color="light" />}
                    >
                      CDR
                    </SuiButton>
                  </Grid>
                )}
              </IconButton>
            </TableCell>
          </TableRow>
        ))
      : currentPageData.map((res, i) => (
          <TableRow key={`row${i}`}>
            <TableCell>{res.InvoiceNumber}</TableCell>
            <TableCell>{formatDate(res.InvoiceDate)}</TableCell>
            <TableCell>{formatDate(res.PeriodStart)}</TableCell>
            <TableCell>{formatDate(res.DueDate)}</TableCell>
            <TableCell>£ {res.AmountExclVAT}</TableCell>
            <TableCell>£ {res.AmountInclVAT}</TableCell>
            <TableCell align="left">
              <IconButton>
                <Grid item>
                  <SuiButton
                    size="1"
                    isLoading={loadingStates[`${res.InvoiceNumber}-invoice`] || false}
                    onClick={() => downloadPDF(res.InvoiceNumber, "invoice")}
                    buttonColor="info"
                    sx={{
                      fontSize: { xs: 14, lg: 16 },
                      fontWeight: 500,
                      py: 1.2,
                      borderRadius: 5,
                    }}
                    startIcon={<DownloadIcon size={17} color="light" />}
                  >
                    Invoice
                  </SuiButton>
                </Grid>
                {filteredData.some((item) => item.InvoiceNumber === res.InvoiceNumber) && (
                  <Grid item>
                    <SuiButton
                      isLoading={loadingStates[`${res.InvoiceNumber}-cdr`] || false}
                      onClick={() => downloadPDF(res.InvoiceNumber, "cdr")}
                      buttonColor="info"
                      sx={{
                        fontSize: { xs: 14, lg: 16 },
                        fontWeight: 500,
                        py: 1.2,
                        ml: 1,
                        borderRadius: 5,
                      }}
                      startIcon={<DownloadIcon size={17} color="light" />}
                    >
                      CDR
                    </SuiButton>
                  </Grid>
                )}
              </IconButton>
            </TableCell>
          </TableRow>
        ));
  return (
    <>
      <SuiSearchBox title="Search">
        <SuiBox mx={1}>
          <Grid container component="form" spacing={2}>
            <Grid item xs={12} md={6} lg={3} xl={4}>
              <FormLabel htmlFor="to_number">Date Range</FormLabel>
              <DatePickerUi
                placeholder="Date range"
                value={formik.values.date_range}
                formik={formik}
                getValue={(value) => formik.setFieldValue("date_range", value)}
                color="primary"
                isCustomRange
              />
            </Grid>

            <Grid item xs={12}>
              <SuiButton
                type="submit"
                size="small"
                buttonColor="primary"
                sx={{ px: "1rem", marginRight: "14px" }}
                onClick={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
                disabled={!formik.dirty}
              >
                Search
              </SuiButton>
              <SuiButton
                type="reset"
                size="small"
                variant="outlined"
                buttonColor="dark"
                sx={{ px: "1rem", py: "0.4rem" }}
                onClick={() => {
                  handleResetForm();
                  formik.handleReset();
                  formik.resetForm();
                  // formik.setFieldValue("date_range", `${resetStart} to ${resetEnd}`);
                  setCurrentPage(1);
                }}
              >
                Reset
              </SuiButton>
            </Grid>
          </Grid>
        </SuiBox>
      </SuiSearchBox>
      <Card
        sx={{
          border: "1px solid #E9E9E9",
          boxShadow: "none",
          width: "100%",
          mt: 3,
        }}
      >
        <EnhancedTable
          customClass="no-border"
          tableMinWidth={870}
          isLoading={isLoading}
          tablehead={paymentTableHeader}
          rowsData={rows}
        />
        {!isLoading && currentPageData.length > 0 && data.length > 0 && (
          <Grid
            container
            py={3}
            spacing={1.5}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "flex-end" }}
            alignItems="center"
          >
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <span>Show</span>
                </Grid>
                <Grid item>
                  {/* Select dropdown for items per page */}
                  <Select
                    value={perPage}
                    onChange={handlePerPageChange}
                    variant="standard"
                    sx={{ minWidth: 100 }}
                  >
                    {[10, 20, 50, 100].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <span>out of {count} Results</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CommonPagination
                mt={0}
                totalData={count}
                pageSize={perPage}
                currentPage={Number(currentPage)}
                onPageChange={handlePageChange}
              />
            </Grid>
          </Grid>
        )}
        {!isLoading && currentPageData.length < 1 && data.length > 0 && (
          <Grid
            container
            py={3}
            spacing={1.5}
            flexDirection={{ xs: "column", md: "row" }}
            justifyContent={{ xs: "center", md: "flex-end" }}
            alignItems="center"
          >
            <Grid item>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <span>Show</span>
                </Grid>
                <Grid item>
                  {/* Select dropdown for items per page */}
                  <Select
                    value={perPage}
                    onChange={handlePerPageChange}
                    variant="standard"
                    sx={{ minWidth: 100 }}
                  >
                    {[10, 20, 50, 100].map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item>
                  <span>out of {count} Results</span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <CommonPagination
                mt={0}
                totalData={count}
                pageSize={perPage}
                currentPage={Number(currentPage)}
                onPageChange={handlePageChange}
              />
            </Grid>
          </Grid>
        )}
      </Card>
      <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
        <Grid item>
          <SuiButton
            size="small"
            component={Link}
            to="/customer"
            variant="outlined"
            buttonColor="dark"
            sx={{ px: "1rem", py: "0.4rem" }}
          >
            Cancel
          </SuiButton>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentDetails;
