import React from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import colors from "assets/theme/base/colors";
import CommonSpinner from "components/CommonSpinner";
import SuiButton from "components/SuiButton";

const WrapCard = ({ title, children, isFetching = true, button, onClick, disabled }) => {
  return (
    <Card sx={{ my: "30px" }}>
      <SuiBox
        px={{ xs: 1, md: 2, lg: 3 }}
        py={2}
        sx={{ borderBottom: "1px solid #D3E0E5", display: "flex", justifyContent: "space-between" }}
      >
        <SuiTypography fontSize="1rem" fontWeight="medium">
          {title}
        </SuiTypography>
        {button === undefined ? (
          ""
        ) : (
          <SuiButton
            size="small"
            onClick={onClick}
            disabled={disabled}
            buttonColor="info"
            sx={{ fontWeight: "500", px: "12px", ml: 2 }}
          >
            {button}
          </SuiButton>
        )}
      </SuiBox>
      <SuiBox component="form" px={{ xs: 1, md: 2, lg: 3 }} py={2}>
        {isFetching ? (
          children
        ) : (
          <SuiBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="calc(100vh - 390px)"
          >
            <CommonSpinner size="100px" color={colors.primary.main} />
          </SuiBox>
        )}
      </SuiBox>
    </Card>
  );
};

WrapCard.propTypes = {
  title: PropTypes.string,
  children: PropTypes.any,
};

export default WrapCard;
