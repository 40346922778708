import {
  TableCell,
  TableRow,
  Card,
  MenuItem,
  Select,
  Grid,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import useNavigateSearch from "hooks/useNavigateSearch";
import { useEffect, useState } from "react";
import { cronStatusHeader } from "layouts/customer/data";
import { useCronDetailsMutation } from "app/features/api/AuthApi";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import { useCronExportMutation } from "app/features/api/AuthApi";
import { format } from "date-fns";
import SuiDialog from "components/SuiDialog";

const CronDetailsTable = () => {
  let page = new URLSearchParams(window.location.search).get("page");
  const [count, setCount] = useState(0);
  const [getDIDs, setGetDids] = useState("");
  const [cronExport] = useCronExportMutation();

  const [cronDetails, { isLoading: isLoading }] = useCronDetailsMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  if (page === null) {
    page = 1;
  }
  const navigateSearch = useNavigateSearch();

  const handleSubmitForm = async (pageNo) => {
    let urlParamss = {
      page: pageNo,
      results_per_page: 10,
    };
    navigateSearch("/cron-history", { page: pageNo });

    try {
      const response = await cronDetails(urlParamss).unwrap();
      navigateSearch("/cron-history", { page: pageNo });
      setGetDids(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      setGetDids("");
      setCount("");
    }
  };

  const [open, setOpen] = useState(false);
  const [modalContent, setModalContent] = useState({
    cron_run_time: "",
    cron_run_date: "",
    requestBody: "",
    responseBody: "",
  });

  const handleOpen = (description) => {
    setModalContent({
      cron_run_time: description.cron_run_time,
      cron_run_date: description.cron_run_date,
      requestBody: description.cron_request,
      responseBody: description.cron_response,
    });
    setOpen(true);
  };

  useEffect(() => {
    handleSubmitForm(1);
  }, []);
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/cron-history", { page: 1 });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/cron-history", { page: page });
  };
  const getCustomerMultipleRecords = async (perPage, currentPage) => {
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };

    try {
      const response = await cronDetails(urlParamss).unwrap();
      setCount(response?.data?.count);
      setGetDids(response.data.rows);
    } catch (error) {
      console.log(error);
    }
  };
  const downloadDetails = async () => {
    const response = await cronExport().unwrap();
    return response?.data;
  };
  const onDownloadFn = async () => {
    const response = await downloadDetails();
    const downloadData = response?.map((item) => {
      return {
        "CRON RUN DATE": item?.cron_run_date,
        "CRON RUN TIME": item?.cron_run_time,
        "CRON EXECUTION TIME":
          item.cron_estimated_run_time == null ? "-" : item.cron_estimated_run_time,
        "CRON STATUS": item.cron_status,
        DESCRIPTION: item.cron_description,
      };
    });
    const csvHeaders = downloadData?.[0] && Object.keys(downloadData?.[0]);
    const csvFormat = [
      csvHeaders && csvHeaders.join(","),
      ...(downloadData || []).map(
        (row) => csvHeaders && csvHeaders.map((header) => row[header])?.join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvFormat], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Cron History List(${format(new Date(), "dd/MM/yyyy | hh:mm a")}).csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };
  useEffect(() => {
    getCustomerMultipleRecords(perPage, currentPage);
  }, [perPage, currentPage]);

  const renderDescription = (item) => {
    if (item.cron_description?.length > 24) {
      return `${item.cron_description.slice(0, 24)}...`;
    }
    return item.cron_description;
  };
  // const handleClose = () => setOpen(false);

  const rows =
    getDIDs && Array.isArray(getDIDs)
      ? getDIDs.map((item, i) => {
          const labelId = `item.cus_id_${i}`;
          return (
            <>
              <TableRow key={labelId}>
                <TableCell sx={{ textAlign: "center" }}>{item.cron_run_date}</TableCell>
                <TableCell sx={{ textAlign: "center", textTransform: "capitalize" }}>
                  {item.cron_run_time}
                </TableCell>
                <TableCell sx={{ textAlign: "center", textTransform: "capitalize" }}>
                  {item.cron_estimated_run_time == null ? "-" : item.cron_estimated_run_time}
                </TableCell>
                <TableCell sx={{ textAlign: "center" }}>{item.cron_status}</TableCell>
                <TableCell
                  key={i}
                  {...(item.cron_description.length > 24
                    ? { sx: { textAlign: "center", cursor: "pointer" } }
                    : { sx: { textAlign: "center" } })}
                  onClick={() => (item.cron_description.length > 24 ? handleOpen(item) : "")}
                >
                  {renderDescription(i)}{" "}
                  {item.cron_description?.length > 24
                    ? `${item.cron_description.slice(0, 24)}...`
                    : item.cron_description}
                </TableCell>
              </TableRow>
            </>
          );
        })
      : [];
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];

  return (
    <>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <SuiDialog open={open} onClose={() => setOpen(false)} haveCloseIcon maxWidth="lg">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <DialogTitle sx={{ fontWeight: 600 }}>{modalContent.cron_run_date}</DialogTitle>
              <DialogTitle sx={{ fontWeight: 600 }}>{modalContent.cron_run_time}</DialogTitle>
            </div>
            <DialogContent dividers>
              <div>
                <h4 className="api-title">
                  <span style={{ textTransform: "uppercase", marginRight: "10px" }}>
                    {modalContent.requestBody.method}
                  </span>
                  {modalContent.requestBody.url}
                  <span
                    style={{
                      textTransform: "uppercase",
                      marginLeft: "10px",
                      backgroundColor: "#ddd",
                      padding: "5px",
                      fontSize: "14px",
                      background: "#ddd",
                    }}
                  >
                    {modalContent.responseBody.status} ERR
                  </span>
                </h4>
              </div>
              <Grid
                container
                alignItems="start"
                spacing={1}
                mt={1}
                sx={{ maxHeight: "300px", overflow: "auto" }}
              >
                <Grid item xs={12} md={6}>
                  <h5>Request Body</h5>
                  <p
                    style={{
                      padding: "5px",
                      marginTop: "5px",
                      letterSpacing: "1px",
                      background: "#ddd",
                      wordBreak: "break-word",
                    }}
                  >
                    {JSON.stringify(modalContent.requestBody.request_body)}
                  </p>
                </Grid>
                <Grid item xs={12} md={6}>
                  <h5>Response Body</h5>
                  <p
                    style={{
                      padding: "5px",
                      marginTop: "5px",
                      letterSpacing: "1px",
                      background: "#ddd",
                      wordBreak: "break-word",
                    }}
                  >
                    {JSON.stringify(modalContent.responseBody)}
                  </p>
                </Grid>
              </Grid>
            </DialogContent>
          </SuiDialog>
          <TableToolbar title={"Cron History"}>
            <SuiButton
              size="small"
              buttonColor="info"
              sx={{ fontWeight: "500", px: "12px", ml: 2 }}
              onClick={onDownloadFn}
            >
              Export
            </SuiButton>
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={1200}
            tablehead={cronStatusHeader}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && getDIDs.length > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    {/* Select dropdown for items per page */}
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      // onClick={() => getCustomerMultipleRecords(perPage, currentPage)}
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default CronDetailsTable;
