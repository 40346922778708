import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "App";
import store from "app/store";
import { SnackbarProvider } from "notistack";
import { persistor } from "app/store";
import { PersistGate } from "redux-persist/integration/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <SnackbarProvider
        maxSnack={4}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        preventDuplicate
      >
        <App />
      </SnackbarProvider>
    </PersistGate>
  </Provider>
);
