import { baseAPIService } from ".";

const CustomerDetailsApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    getDidDetails: build.mutation({
      query: (values) => {
        return {
          url: `admin/customer/did/${values.id}`,
          method: "GET",
          params: values.params,
        };
      },
    }),
    getSipDetails: build.mutation({
      query: (cus_id) => {
        return {
          url: `admin/customer/sip-trunk/${cus_id}`,
          method: "GET",
        };
      },
    }),
    getTransactionDetails: build.mutation({
      query: (values) => {
        return {
          url: `admin/customer/transaction/${values.id}/${values.cus_id}`,
          method: "GET",
          params: values.params,
        };
      },
    }),
    getDiscountDetails: build.mutation({
      query: (id) => {
        return {
          url: `admin/customer/discount/${id}`,
          method: "GET",
        };
      },
    }),
    updateDiscountDetails: build.mutation({
      query: (values) => {
        return {
          url: `admin/customer/discount/${values.id}`,
          method: "POST",
          body: values.body,
        };
      },
    }),
    getOrderDetails: build.mutation({
      query: (id) => {
        return {
          url: `admin/customer/transaction/logs/${id}`,
          method: "GET",
        };
      },
    }),
    resetDiscount: build.mutation({
      query: (id) => {
        return {
          url: `admin/customer/discount/${id}`,
          method: "DELETE",
        };
      },
    }),
  }),
  overrideExisting: true,
});
export const {
  useGetDidDetailsMutation,
  useGetSipDetailsMutation,
  useGetTransactionDetailsMutation,
  useGetDiscountDetailsMutation,
  useUpdateDiscountDetailsMutation,
  useGetOrderDetailsMutation,
  useResetDiscountMutation,
} = CustomerDetailsApi;
