import { Card, FormLabel, Grid } from "@mui/material";
import { GeoCodeSechma } from "Validation";
import { useUpdateGeoAreaMutation } from "app/features/api/AuthApi";
import { useAddGeoAreaMutation } from "app/features/api/AuthApi";
import { useGetByGeoAreaMutation } from "app/features/api/AuthApi";
import { useGetAllCountryMutation } from "app/features/api/AuthApi";
import ErrorMessageComponent from "components/FormikErrorMessage";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { useFormik } from "formik";
import useNavigateSearch from "hooks/useNavigateSearch";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const GeoCodeAction = () => {
  const navigateSearch = useNavigateSearch();
  const suiSnackbar = useSuiSnackbar();
  const currentUrl = window.location.href.includes("edit-geo-area-code");
  const [GetAllCountry] = useGetAllCountryMutation();
  const [AddGeoArea] = useAddGeoAreaMutation();
  const [UpdateGeoArea] = useUpdateGeoAreaMutation();
  const [countryList, setCountryList] = useState();
  const [errorVal, setErrorVal] = useState("");
  const id = localStorage.getItem("geo_id");
  const formik = useFormik({
    initialValues: {
      did_area_code_caption: "",
      did_area_code_code: "",
      Country: "",
      country_id: "",
    },
    enableReinitialize: true,
    validationSchema: GeoCodeSechma,
    onSubmit: async (values) => {
      const body = {
        did_area_code_caption: values.did_area_code_caption.trim(),
        did_area_code_code: values.did_area_code_code.trim(),
        country_id: values.Country.id,
      };
      if (currentUrl) {
        body.id = id;
      }
      try {
        const response = await (currentUrl
          ? UpdateGeoArea(body).unwrap()
          : AddGeoArea(body).unwrap());
        navigateSearch("/geo-area-code", { page: 1 });
        setErrorVal("");
        suiSnackbar(response.message, "success");
      } catch (error) {
        if (error.data.message) {
          suiSnackbar(error.data.message, "error");
        }
        setErrorVal(error);
      }
    },
  });
  const [getByGeoArea] = useGetByGeoAreaMutation();
  const getCountry = async () => {
    try {
      const response = await GetAllCountry().unwrap();
      setCountryList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getGeoData = async () => {
    try {
      const response = await getByGeoArea(id).unwrap();
      formik.setFieldValue("did_area_code_caption", response.data.did_area_code_caption);
      formik.setFieldValue("did_area_code_code", response.data.did_area_code_code);
      formik.setFieldValue("country_id", response.data.countries.country_name);
      const transformedCountry = {
        id: response.data.countries.country_id,
        name: response.data.countries.country_name,
      };
      formik.setFieldValue("Country", transformedCountry);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCountry();
    if (id) {
      getGeoData();
    }
  }, []);
  const options = countryList?.map((item) => ({
    id: item.country_id,
    name: item.country_name,
  }));
  useEffect(() => {
    if (errorVal?.data?.errorMessages?.fieldErrors) {
      const fieldErrors = {};
      const errors = errorVal.data.errorMessages.fieldErrors;
      for (const field in errors) {
        if (Object.hasOwn(errors, field)) {
          fieldErrors[field] = errors[field].join(" ");
        }
      }
      formik.setErrors(fieldErrors);
    } else {
      setErrorVal("");
    }
  }, [errorVal, formik]);
  return (
    <Card sx={{ my: 3, p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SuiTypography fontWeight="semiBold" fontSize={16}>
            {currentUrl ? "Edit Geo Area Code" : "Add Geo Area Code"}
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormLabel htmlFor="did_num" required>
            Caption
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            placeholder="Enter Caption"
            id="did_area_code_caption"
            name="did_area_code_caption"
            value={formik.values.did_area_code_caption}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.did_area_code_caption}
            error={formik.errors.did_area_code_caption}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormLabel htmlFor="First_Name" required>
            Code
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            placeholder="Enter  Code"
            id="did_area_code_code"
            name="did_area_code_code"
            value={formik.values.did_area_code_code}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.did_area_code_code}
            error={formik.errors.did_area_code_code}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormLabel htmlFor="Country" required>
            Country
          </FormLabel>
          <AutocompleteSingleSelect
            id="Country"
            value={formik.values.Country}
            setValue={(value) => {
              formik.setFieldValue("Country", value);
              formik.setFieldValue("country_id", value.name);
            }}
            noOptionsText="No options"
            placeholder="Select"
            isLoading={false}
            disabled={false}
            options={options}
            formik={formik}
          />
          {formik.errors.country_id && formik.touched.Country && (
            <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>{formik.errors.country_id}</div>
          )}
        </Grid>
        <Grid item xs={12} mt={4}>
          <SuiButton
            size="small"
            component={Link}
            to="/geo-area-code"
            variant="outlined"
            buttonColor="dark"
            sx={{ px: "1rem", py: "0.4rem", marginRight: "14px" }}
            disabled={formik.isSubmitting}
          >
            Cancel
          </SuiButton>
          <SuiButton
            type="submit"
            size="small"
            buttonColor="primary"
            sx={{ px: "1rem" }}
            onClick={formik.handleSubmit}
            disabled={!formik.dirty || formik.isSubmitting}
          >
            {currentUrl ? "Update" : "Add"}
          </SuiButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default GeoCodeAction;
