import { styled, Tab, Tabs } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";

export const CutomeTabs = styled(Tabs)(({ theme }) => ({
  position: "relative",
  backgroundColor: "white",
  minHeight: "unset",
  padding: pxToRem(0),
  "& .MuiTabScrollButton-root.Mui-disabled": {
    display: "none",
  },
  "& .MuiTabs-scroller": {
    padding: "0 2px",
  },
  "& .MuiTabs-flexContainer": {
    height: "100%",
    gap: 0,
    width: "max-content",
    minWidth: "100%",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "0.7rem",
  },
}));

export const CutomeTab = styled(Tab)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
  textAlign: "center",
  flexGrow: 1,
  maxWidth: "unset !important",
  minWidth: "unset !important",
  minHeight: "unset !important",
  fontSize: "16px",
  marginBottom: "0",
  textTransform: "none",
  lineHeight: "inherit",
  backgroundColor: "transparent",
  padding: `${pxToRem(8)} ${pxToRem(16)}`,
  borderRadius: pxToRem(8),
  color: `${theme.palette.text.focus} !important`,
  opacity: "1 !important",
  transition: "all 100ms ease",
  "@media (max-width: 576px)": {
    fontSize: "14px",
  },
  "&.Mui-selected": {
    color: `${theme.palette.text.main} !important`,
    fontWeight: "500",
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
}));
