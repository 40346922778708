import pxToRem from "assets/theme/functions/pxToRem";

const IdealICon = ({ size = 19 }) => {
  return (
    <svg height={pxToRem(size)} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.3"
        d="M13.9056 27.3193C21.262 27.3193 27.2255 21.3558 27.2255 13.9995C27.2255 6.64318 21.262 0.679688 13.9056 0.679688C6.5493 0.679688 0.585815 6.64318 0.585815 13.9995C0.585815 21.3558 6.5493 27.3193 13.9056 27.3193Z"
        fill="#ECAC2F"
      />
      <path
        d="M13.9054 22.4273C18.5596 22.4273 22.3325 18.6544 22.3325 14.0003C22.3325 9.34616 18.5596 5.57324 13.9054 5.57324C9.25131 5.57324 5.47839 9.34616 5.47839 14.0003C5.47839 18.6544 9.25131 22.4273 13.9054 22.4273Z"
        fill="#ECAC2F"
      />
    </svg>
  );
};

export default IdealICon;
