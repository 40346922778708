import React, { useEffect, useState } from "react";
import { Card, Grid, FormLabel } from "@mui/material";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import ErrorMessageComponent from "components/FormikErrorMessage";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDropDownList from "components/SuiDropDownList";
import { addRangeHolderchema } from "Validation";
import { useUpdateRangeholderMutation } from "app/features/api/AuthApi";
import { useGetRangeholderByIdMutation } from "app/features/api/AuthApi";
import { useAddRangeholderMutation } from "app/features/api/AuthApi";

const AddRangeHolder = () => {
  const id = localStorage.getItem("did_range_holder_id");
  const [error, setError] = useState("");
  const [addRangeholder] = useAddRangeholderMutation();
  const [RangeholderByID] = useGetRangeholderByIdMutation();
  const suiSnackbar = useSuiSnackbar();
  const [updateRangeholder] = useUpdateRangeholderMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      did_range_holder_name: "",
      did_range_holder_code: "",
      did_range_holder_cupid: "",
      did_range_holder_status: "1",
    },
    validationSchema: addRangeHolderchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log("values:::::", values);
      const body = {
        did_range_holder_name: values.did_range_holder_name,
        did_range_holder_code: values.did_range_holder_code,
        did_range_holder_cupid: values.did_range_holder_cupid,
        did_range_holder_status: values.did_range_holder_status,
      };
      body.did_range_holder_cupid != ""
        ? (body.did_range_holder_cupid = values.did_range_holder_cupid)
        : delete body.did_range_holder_cupid;
      try {
        if (id) {
          body.id = id;
          console.log(body);
          let response = await updateRangeholder(body).unwrap();
          if (response.status === 200) {
            suiSnackbar(response.message, "success");
            navigate("/range-holder");
            setError("");
          }
        } else {
          // Add new role if no ID is present
          let response = await addRangeholder(body).unwrap();
          if (response.status === 200) {
            formik.resetForm();
            navigate("/range-holder");
            suiSnackbar(response.message, "success");
            setError("");
            console.log(error);
          }
        }
      } catch (error) {
        if (error?.data?.errorMessages?.fieldErrors) {
          const fieldErrors = error.data.errorMessages.fieldErrors;
          for (const field in fieldErrors) {
            if (Object.hasOwn(fieldErrors, field)) {
              fieldErrors[field].forEach((errorMessage) => {
                console.error(`Error in ${field}: ${errorMessage}`);
                suiSnackbar(errorMessage || "An error occurred", "error");
              });
            }
          }
        } else {
          suiSnackbar(error?.data?.message || "An error occurred", "error");
        }
      }
    },
  });
  const getRangeholderDetails = async () => {
    try {
      const response = await RangeholderByID(id).unwrap();
      const {
        did_range_holder_name,
        did_range_holder_code,
        did_range_holder_cupid,
        did_range_holder_status,
      } = response.data;
      formik.setValues({
        did_range_holder_name,
        did_range_holder_code,
        did_range_holder_cupid: did_range_holder_cupid === "-" ? "" : did_range_holder_cupid,
        did_range_holder_status,
      });
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getRangeholderDetails();
    }
  }, [id]);

  return (
    <>
      <Card sx={{ my: 3, p: 2 }}>
        <Grid
          component={"form"}
          container
          spacing={2}
          rowSpacing={3}
          onSubmit={formik.handleSubmit}
        >
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {id ? "Edit Range Holder" : "Add Range Holder"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required={id ? false : true}>
              Range Holder Code
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              type="text"
              placeholder="Enter Range Holder Code"
              id="did_range_holder_code"
              name="did_range_holder_code"
              value={formik.values.did_range_holder_code}
              onChange={formik.handleChange}
              disabled={id ? true : false}
            />
            <ErrorMessageComponent
              touched={formik.touched.did_range_holder_code}
              error={formik.errors.did_range_holder_code}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Range Holder Name
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              placeholder="Enter Range Holder Name"
              id="did_range_holder_name"
              name="did_range_holder_name"
              value={formik.values.did_range_holder_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.did_range_holder_name}
              error={formik.errors.did_range_holder_name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name">CUPID</FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off" }}
              placeholder="Enter CUPID"
              id="did_range_holder_cupid"
              name="did_range_holder_cupid"
              value={formik.values.did_range_holder_cupid}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.did_range_holder_cupid}
              error={formik.errors.did_range_holder_cupid}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="status" required>
              Status
            </FormLabel>
            <SuiDropDownList
              data={[
                { id: "1", label: "Active" },
                { id: "0", label: "Inactive" },
              ]}
              select={{
                key: "id",
                valueKey: "id",
                labelName: "label",
                label: "Status",
                name: "did_range_holder_status",
                val: formik.values.did_range_holder_status,
                fn: formik.handleChange,
              }}
            />
            <ErrorMessageComponent
              touched={formik.touched.did_range_holder_status}
              error={formik.errors.did_range_holder_status}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  size="small"
                  component={Link}
                  to="/range-holder"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem" }}
                  disabled={!formik.dirty}
                >
                  {id ? "Update" : "Add"}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AddRangeHolder;
