import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";

const ProtectedRoute = ({ children, permission, action }) => {
  const { permissions } = useSelector((state) => state.auth);
  const userPermissions = matchPermissions(permissions, Permission);

  if (!userPermissions?.[permission]?.[action]) {
    return <Navigate to="/not-authorized" />;
  }

  return children;
};

export default ProtectedRoute;
