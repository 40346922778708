import {
  Grid,
  TableCell,
  TableRow,
  Card,
  IconButton,
  MenuItem,
  Select,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useFormik } from "formik";
import useNavigateSearch from "hooks/useNavigateSearch";
import DeleteIcon from "Container/Icons/DeleteIcon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { adminTable } from "layouts/customer/data";
import EditIcon from "Container/Icons/EditIcon";
import { useGetAllAdminMutation } from "app/features/api/AuthApi";
import { formatDate } from "helper";
import DatePickerUi from "components/SuiDateRangePicker/commonSingleDatePicker";
import { useGetRolesDropdownMutation } from "app/features/api/AuthApi";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import { useDownloadAdminDetailsMutation } from "app/features/api/AuthApi";
import { format } from "date-fns";
import { useDeleteAdminMutation } from "app/features/api/AuthApi";
import { useImportAdminMutation } from "app/features/api/AuthApi";
import SuiDialog from "components/SuiDialog";
import FileUploadUI from "components/SuiFileUploadInput/FileUploadUI";
import SuiTypography from "components/SuiTypography";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { importCSV } from "Validation";
import { useImportFormAdminMutation } from "app/features/api/AuthApi";
import { useSelector } from "react-redux";
import { Permission } from "Permissions/Permission";
import { matchPermissions } from "Permissions/commonPermissionFunc";

const AdminTable = () => {
  let page = new URLSearchParams(window.location.search).get("page");
  const [count, setCount] = useState(0);
  const [getDids, setGetDids] = useState("");
  const [GetAllAdmin, { isLoading: isLoading }] = useGetAllAdminMutation();
  const [getRolesDropdown] = useGetRolesDropdownMutation();
  const [getRoles, setGetRoles] = useState();
  const suiSnackbar = useSuiSnackbar();
  const [deleteAdmin] = useDeleteAdminMutation();
  const [importFormAdmin] = useImportFormAdminMutation();
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [permissionInfo, setPermissionInfo] = useState();
  const [open, setOpen] = useState(false);
  const { permissions } = useSelector((state) => state.auth);
  if (page === null) {
    page = 1;
  }
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const navigateSearch = useNavigateSearch();
  const formik = useFormik({
    initialValues: {
      admin_name: "",
      last_name: "",
      admin_role: "",
      admin_role1: "",
      Email_id: "",
      // date_range: "",
      // page: 1,
    },
    enableReinitialize: true,
    onSubmit: () => {
      handleSubmitForm(1);
    },
  });

  const handleSubmitForm = async (pageNo) => {
    navigateSearch("/user-management", { page: pageNo });
    let urlParamss = {
      results_per_page: 10,
      page: 1,
    };
    if (formik.values.admin_name) {
      urlParamss.admin_name = formik.values.admin_name?.trim();
    }
    if (formik.values.last_name) {
      urlParamss.admin_last_name = formik.values.last_name?.trim();
    }
    if (formik.values.admin_role) {
      urlParamss.admin_role_id = formik.values.admin_role.id;
    }
    if (formik.values.Email_id) {
      urlParamss.admin_email = formik.values.Email_id?.trim();
    }
    if (formik.values.date_range) {
      urlParamss.createdAt = formik.values.date_range;
    }
    try {
      const response = await GetAllAdmin(urlParamss).unwrap();
      navigateSearch("/user-management", { page: pageNo });
      setGetDids(response.data?.rows);
      setCount(response?.data?.count);
    } catch (error) {
      setGetDids("");
      setCount("");
    }
  };
  const getRolesDetails = async () => {
    try {
      const response = await getRolesDropdown().unwrap();
      setGetRoles(response.data);
    } catch (error) {
      // console.log(error);
    }
  };

  const handleRestForm = async (pageNo) => {
    navigateSearch("/user-management", { page: pageNo });
    let urlParamss = {
      results_per_page: 10,
      page: pageNo,
    };
    formik.setFieldValue("date_range", "");
    try {
      const response = await GetAllAdmin(urlParamss).unwrap();
      navigateSearch("/user-management", { page: pageNo });
      setGetDids(response.data?.rows);
      setCount(response?.data?.count);
      formik.setFieldValue("customer_name", "");
    } catch (error) {
      setGetDids("");
      setCount("");
    }
  };

  const deleteModal = (id) => {
    setDeleteID(id);
    setOpenModal(true);
  };
  const handleDeleteIconClick = async (id) => {
    try {
      const response = await deleteAdmin(id).unwrap();
      if (response.status === 200) {
        suiSnackbar(response.message, "success");
        setOpenModal(false);
      }
    } catch (error) {
      // console.log(error);
    }
    handleSubmitForm(1);
  };
  const editRedirect = (item) => {
    localStorage.setItem("admin_id", item);
    navigateSearch(`edit-user`);
  };
  useEffect(() => {
    getRolesDetails();
    handleSubmitForm(1);
    localStorage.removeItem("admin_id");
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, []);

  const getCustomerMultipleRecords = async (perPage, currentPage) => {
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };

    try {
      const response = await GetAllAdmin(urlParamss).unwrap();
      setCount(response?.data?.count);
      setGetDids(response.data.rows);
    } catch (error) {
      // console.log(error);
    }
  };
  const [importAdmin] = useImportAdminMutation();
  const [formatCSVUrl, setFormatCSVUrl] = useState("");
  const formatCSV = async () => {
    try {
      const response = await importAdmin().unwrap();
      setFormatCSVUrl(response.data.url);
    } catch (error) {
      // console.log(error);
    }
  };
  const handleButtonClick = () => {
    window.location.href = formatCSVUrl;
  };
  useEffect(() => {
    getCustomerMultipleRecords(perPage, currentPage);
  }, [perPage, currentPage]);
  const [downloadAdminDetails] = useDownloadAdminDetailsMutation();

  const downloadDetails = async () => {
    let urlParamss = {};
    if (formik.values.admin_name) {
      urlParamss.admin_name = formik.values.admin_name?.trim();
    }
    if (formik.values.last_name) {
      urlParamss.admin_last_name = formik.values.last_name?.trim();
    }
    if (formik.values.admin_role) {
      urlParamss.admin_role_id = formik.values.admin_role.id;
    }
    if (formik.values.Email_id) {
      urlParamss.admin_email = formik.values.Email_id?.trim();
    }
    if (formik.values.date_range) {
      urlParamss.createdAt = formik.values.date_range;
    }
    const response =
      Object.keys(urlParamss)?.length > 0
        ? await downloadAdminDetails(urlParamss).unwrap()
        : await downloadAdminDetails().unwrap();
    return response?.data;
  };

  const onDownloadFn = async () => {
    const response = await downloadDetails();
    const downloadData = response?.map((item) => {
      return {
        "FIRST NAME": capitalizeFirstLetter(item?.admin_name),
        "LAST NAME":
          item?.admin_last_name == "" ? "-" : capitalizeFirstLetter(item?.admin_last_name),
        ROLE: capitalizeFirstLetter(item.roles.admin_role_name),
        "EMAIL ID": item.admin_email,
        STATUS: item?.admin_status == "1" ? "Active" : "Inactive",
        "CREATED ON": formatDate(item.createdAt),
      };
    });

    const csvHeaders = downloadData?.[0] && Object.keys(downloadData?.[0]);
    const csvFormat = [
      csvHeaders && csvHeaders.join(","),
      ...(downloadData || []).map(
        (row) => csvHeaders && csvHeaders.map((header) => row[header])?.join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvFormat], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `User Management List(${format(new Date(), "dd/MM/yyyy | hh:mm a")}).csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleFileChange = (file) => {
    importCsvFormik.setFieldValue("file", file);
  };
  const removeFileName = () => {
    return;
  };
  const isActions =
    permissionInfo?.user_management?.UPDATE || permissionInfo?.user_management?.DELETE
      ? {
          id: "8",
          align: "right",
          width: "150",
          label: "Actions",
        }
      : null;

  const capitalizeFirstLetter = (str) => {
    if (!str) return "";

    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const options = getRoles?.map((role) => ({
    id: role.admin_role_id,
    name: capitalizeFirstLetter(role.admin_role_name),
  }));
  const rows =
    getDids &&
    getDids?.map((item, i) => {
      const labelId = `item.cus_id_${i}`;
      return (
        <>
          <TableRow key={labelId}>
            <TableCell>{item.admin_name}</TableCell>
            <TableCell sx={{ textTransform: "capitalize" }}>
              {item.admin_last_name == "" ? "-" : item.admin_last_name}
            </TableCell>
            <TableCell sx={{ textTransform: "capitalize" }}>{item.roles.admin_role_name}</TableCell>
            <TableCell>{item.admin_email}</TableCell>
            <TableCell>
              {item.admin_status === "1" ? (
                <SuiTypography fontSize={14} sx={{ color: "#55DED0 !important" }}>
                  Active
                </SuiTypography>
              ) : (
                <SuiTypography fontSize={14} sx={{ color: "#848484 !important" }}>
                  Inactive
                </SuiTypography>
              )}
            </TableCell>
            <TableCell>{formatDate(item.createdAt)}</TableCell>
            {permissionInfo.user_management.UPDATE && !permissionInfo.user_management.DELETE && (
              <TableCell align="right">
                <IconButton onClick={() => editRedirect(item.admin_id)}>{<EditIcon />}</IconButton>
              </TableCell>
            )}
            {!permissionInfo.user_management.UPDATE && permissionInfo.user_management.DELETE && (
              <TableCell align="right">
                <IconButton onClick={() => deleteModal(item.admin_id)}>{<DeleteIcon />}</IconButton>
              </TableCell>
            )}
            {permissionInfo.user_management.UPDATE && permissionInfo.user_management.DELETE && (
              <TableCell align="right">
                <IconButton onClick={() => editRedirect(item.admin_id)}>{<EditIcon />}</IconButton>
                <IconButton onClick={() => deleteModal(item.admin_id)}>{<DeleteIcon />}</IconButton>
              </TableCell>
            )}
            {/* <TableCell>
              {permissionInfo.user_management.UPDATE ? (
              ) : (
                ""
              )}
              {permissionInfo.user_management.DELETE ? (
                <IconButton onClick={() => deleteModal(item.admin_id)}>{<DeleteIcon />}</IconButton>
              ) : (
                ""
              )}
            </TableCell> */}
          </TableRow>
        </>
      );
    });
  const importCsvFormik = useFormik({
    initialValues: {
      file: "",
    },
    validationSchema: importCSV,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formData = new FormData();
      const files = values.file;
      formData.append("file", files);
      try {
        const response = await importFormAdmin(formData).unwrap();
        if (response.status === 200) {
          suiSnackbar(response.message, "success");
          importCsvFormik.resetForm();
          setOpen(false);
          handleSubmitForm(1);
        }
      } catch (error) {
        const url = window.URL.createObjectURL(new Blob([error.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Unexpected_data.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        suiSnackbar("Unexpected data found in csv.", "error");
      }
    },
  });

  const optionsPerPage = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="tel"
                  placeholder="First Name"
                  id="admin_name"
                  name="admin_name"
                  value={formik.values.admin_name}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="tel"
                  placeholder="Last Name"
                  id="last_name"
                  name="last_name"
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <AutocompleteSingleSelect
                  id="admin_role"
                  value={formik.values.admin_role}
                  setValue={(value) => {
                    formik.setFieldValue("admin_role", value);
                    formik.setFieldValue("admin_role1", value.name);
                  }}
                  noOptionsText="No options"
                  placeholder="Select admin role"
                  isLoading={false}
                  disabled={false}
                  options={options}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="tel"
                  placeholder="Email ID"
                  id="Email_id"
                  name="Email_id"
                  value={formik.values.Email_id}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <DatePickerUi
                  placeholder="Created on"
                  value={formik.values.date_range}
                  formik={formik}
                  getValue={(value) => formik.setFieldValue("date_range", value)}
                  color="primary"
                  isCustomRange
                />
              </Grid>

              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.handleReset();
                    navigateSearch("/user-management", { page: 1 });
                    handleRestForm(1);
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title={"List of Users"}>
            {permissionInfo?.user_management.CREATE ? (
              <>
                <SuiButton
                  size="small"
                  buttonColor="secondary"
                  sx={{
                    fontWeight: "500",
                    px: "12px",
                    backgroundColor: "#E9E9E9 !important",
                    color: "#848484 !important",
                    marginLeft: "16px",
                  }}
                  onClick={() => {
                    setOpen(true);
                    formatCSV();
                  }}
                >
                  Import
                </SuiButton>
              </>
            ) : (
              ""
            )}
            <SuiButton
              size="small"
              buttonColor="secondary"
              sx={{
                fontWeight: "500",
                px: "12px",
                backgroundColor: "#E9E9E9 !important",
                color: "#848484 !important",
                marginLeft: "16px",
              }}
              onClick={onDownloadFn}
            >
              Export
            </SuiButton>
            {permissionInfo?.user_management.CREATE ? (
              <SuiButton
                component={Link}
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                to={"add-user"}
              >
                Add User
              </SuiButton>
            ) : (
              ""
            )}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={1200}
            tablehead={isActions ? [...adminTable, isActions] : [...adminTable]}
            rowsData={rows}
            isLoading={isLoading}
          />
          <SuiDialog open={open} onClose={() => setOpen(false)} haveCloseIcon>
            <DialogTitle sx={{ fontWeight: 600 }}>Import the file</DialogTitle>
            <DialogContent dividers>
              <Grid container rowSpacing={2}>
                <Grid item xs={12}>
                  <SuiButton
                    size="small"
                    sx={{ fontSize: "1rem", fontWeight: "500" }}
                    buttonColor="info"
                    component="button"
                    onClick={handleButtonClick}
                  >
                    Download Sample File
                  </SuiButton>
                  <SuiTypography textColor="text2" fontSize={14} pt={1}>
                    Download the sample file and update admin information according to the example
                    given.
                  </SuiTypography>
                </Grid>
                <Grid item xs={12}>
                  <FileUploadUI
                    name="file"
                    id="file"
                    label="Upload File"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    getFile={handleFileChange}
                    removeFileName={removeFileName}
                  />
                  {!importCsvFormik.values.file && (
                    <SuiTypography textColor="text2" fontSize={14} pt={1}>
                      Upload a CSV file that has Policies information.
                    </SuiTypography>
                  )}
                  <ErrorMessageComponent
                    touched={importCsvFormik.touched.file}
                    error={importCsvFormik.errors.file}
                  />
                </Grid>
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Grid container justifyContent={"end"} spacing={2}>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        size="small"
                        sx={{ px: "1rem" }}
                        onClick={() => setOpen(false)}
                        buttonColor="dark"
                        variant="outlined"
                        fullWidth
                        disabled={importCsvFormik.isSubmitting}
                      >
                        Cancel
                      </SuiButton>
                    </Grid>

                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        size="small"
                        sx={{ px: "1rem" }}
                        buttonColor="primary"
                        fullWidth
                        onClick={importCsvFormik.handleSubmit}
                        type="submit"
                        disabled={importCsvFormik.isSubmitting || !importCsvFormik.dirty}
                      >
                        {importCsvFormik.isSubmitting ? (
                          <CircularProgress size="20px" sx={{ color: "blue" }} />
                        ) : (
                          "Submit"
                        )}
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </SuiDialog>
          <SuiDialog open={openModal} onClose={() => setOpenModal(false)} haveCloseIcon>
            <DialogContent
              sx={{
                fontWeight: "semiBold",
              }}
            >
              Are you sure you want to delete this user ?
              <Grid container rowSpacing={2}>
                <Grid item xs={12} sx={{ marginTop: "20px" }}>
                  <Grid container justifyContent={"end"} spacing={2}>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        size="small"
                        sx={{ px: "1rem" }}
                        onClick={() => setOpenModal(false)}
                        buttonColor="dark"
                        variant="outlined"
                        fullWidth
                        disabled={importCsvFormik.isSubmitting}
                      >
                        Cancel
                      </SuiButton>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                      <SuiButton
                        size="small"
                        sx={{ px: "1rem" }}
                        buttonColor="info"
                        fullWidth
                        onClick={() => handleDeleteIconClick(deleteID)}
                      >
                        Delete
                      </SuiButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </SuiDialog>
          {!isLoading && getDids.length > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {optionsPerPage.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default AdminTable;
