import { useState } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import styles from "Container/Sidenav/styles/sidenavCollapse";
import SubLink from "./SubLink";
import { Icon } from "@mui/material";
import SuiBox from "components/SuiBox";

const SidenavCollapse = ({ icon, name, subMenu }) => {
  const { uiControl } = useSelector((store) => store);
  const { miniSidenav, transparentSidenav, sidenavColor } = uiControl;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];

  // Determine if the current location is active
  let active = subMenu.some(({ link }) => link === `/${collapseName}`);
  const classes = styles({
    active,
    miniSidenav,
    transparentSidenav,
    sidenavColor,
  });
  const [expanded, setExpanded] = useState(active);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderSubMenu = (menu) =>
    menu.map(({ id, order, label, link, sub_menu, icon }) => (
      <SuiBox key={id} sx={{ order: order }}>
        {/* Render icon and label if there is no nested submenu */}
        {!sub_menu || sub_menu.length === 0 ? (
          <SubLink to={link} name={label} active={link === `/${collapseName}`}>
            {icon && <Icon>{icon}</Icon>}
          </SubLink>
        ) : (
          // Render icon and label if there is a nested submenu
          <SidenavCollapse
            icon={icon} // Use the icon from the menu item
            name={label}
            subMenu={sub_menu}
          />
        )}
      </SuiBox>
    ));

  return (
    <Accordion
      expanded={expanded}
      onChange={handleChange(!expanded)}
      className={classes.collapse_item}
    >
      <AccordionSummary className={classes.collapse_summary} expandIcon={<ExpandMoreIcon />}>
        {icon ? (
          <ListItemIcon className={classes.collapse_iconBox}>
            {typeof icon === "string" ? (
              <Icon className={classes.collapse_icon}>{icon}</Icon>
            ) : (
              icon
            )}
          </ListItemIcon>
        ) : expanded ? (
          <ListItemIcon className={`${classes.collapse_iconBox} subParentMenuIcon`}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="6.90332"
                cy="6.55127"
                r="5"
                fill="#55DED0"
                stroke="#55DED0"
                strokeWidth="3"
              />
            </svg>
          </ListItemIcon>
        ) : (
          <ListItemIcon className={classes.collapse_iconBox}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="6.90332"
                cy="6.55127"
                r="5"
                fill="white"
                stroke="#848484"
                strokeWidth="3"
              />
            </svg>
          </ListItemIcon>
        )}
        <ListItemText primary={name} classes={{ root: classes.collapse_text }} />
      </AccordionSummary>
      <AccordionDetails sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        {renderSubMenu(subMenu)}
      </AccordionDetails>
    </Accordion>
  );
};

SidenavCollapse.defaultProps = {
  icon: null,
  subMenu: [],
};

SidenavCollapse.propTypes = {
  icon: PropTypes.any,
  name: PropTypes.string.isRequired,
  subMenu: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      order: PropTypes.number,
      label: PropTypes.string.isRequired,
      link: PropTypes.string,
      permission: PropTypes.string,
      sub_menu: PropTypes.array,
    })
  ),
};

export default SidenavCollapse;
