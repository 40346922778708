import colors from "assets/theme/base/colors";

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.278"
      height="15.278"
      viewBox="0 0 15.278 15.278"
    >
      <path
        id="Union_49"
        d="M2.6,15.278h0a2.6,2.6,0,0,1-2.6-2.6V2.6A2.6,2.6,0,0,1,2.6,0h5.89a.6.6,0,0,1,0,1.2H2.6A1.4,1.4,0,0,0,1.2,2.6V12.675a1.4,1.4,0,0,0,1.4,1.4H12.675a1.4,1.4,0,0,0,1.4-1.4V6.928a.6.6,0,0,1,1.2,0v5.747a2.6,2.6,0,0,1-2.6,2.6Zm2.388-5.152a.879.879,0,0,1-.149-.761l.545-2a.527.527,0,0,1,.141-.228L12.12.539a1.852,1.852,0,0,1,2.62,2.62L8.147,9.734a.527.527,0,0,1-.228.141h0l-2,.563-.229.035A.879.879,0,0,1,4.991,10.126Zm1.4-2.344-.44,1.565,1.565-.44L13.087,3.3,11.962,2.174Zm6.47-6.488-.141.141,1.125,1.125.141-.141a.8.8,0,0,0-1.125-1.125Z"
        fill={colors.info.main}
      />
    </svg>
  );
};

export default EditIcon;
