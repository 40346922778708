import moment from "moment";
import { getCountryCallingCode } from "react-phone-number-input";
import { getCountries } from "react-phone-number-input";
import { useSearchParams } from "react-router-dom";
import en from "react-phone-number-input/locale/en.json";

const stringToColor = (string) => {
  let hash = 0;
  let i;
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const stringAvatar = (name, image, size, font) => {
  return {
    sx: {
      bgcolor: image ? "transparent" : `${stringToColor(name)}44`,
      color: stringToColor(name),
      width: `${size || "40px"}`,
      height: `${size || "40px"}`,
      fontSize: `${font || "17px"}`,
    },
    children: `${name?.split(" ")[0][0]?.toUpperCase()}${name
      ?.split(" ")
      [name?.split(" ").length > 1 ? 1 : 0][0]?.toUpperCase()}`,
  };
};

export const capitalize = (word) => {
  return word
    ?.toLowerCase()
    ?.split(" ")
    ?.map((text) => {
      if (!text) return "";
      return text[0].toUpperCase() + text.substring(1);
    })
    ?.join(" ");
};
export function formatDates(inputDate) {
  const year = inputDate.substring(0, 4);
  const month = inputDate.substring(4, 6);
  const day = inputDate.substring(6, 8);
  const date = new Date(`${year}-${month}-${day}`);
  const formattedDay = String(date.getDate()).padStart(2, "0");
  const formattedMonth = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
  const formattedYear = date.getFullYear();
  const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;
  return formattedDate;
}

export const imageBase = (image) => {
  if (!image) {
    return "";
  } else if (image.indexOf("base64,") === -1) {
    return `data:image/jpeg;base64,${image}`;
  } else {
    return image;
  }
};

export const setDigits = (num) => {
  if (num) {
    return num <= 9 ? `0${num}` : num;
  } else {
    return "--";
  }
};

export const getNewObjectProperties = (prevObj, newObj) => {
  const results = prevObj.filter((obj) => {
    return !newObj.some((obj2) => {
      return JSON.stringify(obj) === JSON.stringify(obj2);
    });
  });
  return results;
};

export const getNewProperties = (prevObj, newObj) => {
  const resultObject = {};
  for (const key in prevObj) {
    if (prevObj[key] !== newObj[key]) {
      if (
        newObj[key]?.length > 0 ||
        !isNaN(newObj[key]) ||
        (newObj[key] && typeof newObj[key] === "object")
      ) {
        resultObject[key] = newObj[key];
      } else {
        resultObject[key] = null;
      }
    }
  }
  if (resultObject["mobile_no"]) {
    resultObject["country_code"] = newObj.country_code;
  }
  return resultObject;
};

export const checkIfEmpty = (value) => {
  if (value && value.length > 0) {
    return value;
  }
  return null;
};

export const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0;
};

export const getSearchQueryParams = () => {
  const [searchParams] = useSearchParams();
  let allSearchParams = {};
  for (const entry of searchParams.entries()) {
    const [param, value] = entry;
    allSearchParams[param] = isNaN(+value) ? value : +value;
  }
  return allSearchParams;
};

export const removeTags = (str) => {
  if (str === null || !str) return false;
  else str = str.toString();
  return str.replace(/(<([^>]+)>)/gi, "").trim();
};

// export const setFeildBySearchParams = (formik, getSearchParams) => {
//   for (const key in getSearchParams) {
//     const value = getSearchParams[key];
//     formik.setFieldValue(key, isNaN(+value) ? value : +value);
//   }
// };
export const formatDate = (date) => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1);
  let day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const bgColor = (name) => {
  let hash = 0;
  let i;
  let color = "#";
  for (i = 0; i < name?.length; i += 1) {
    // eslint-disable-next-line no-bitwise
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }
  for (i = 0; i < 3; i += 1) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0x77;
    color += `f0${value.toString(16)}`.slice(-2);
  }
  const style = {
    p: "10px",
    objectFit: "contain",
    bgcolor: `${color}55`,
    borderRadius: "7px 7px 0 0",
    m: 0,
  };
  return style;
};

export const appendZero = (number) => {
  if (number < 10) {
    return `0${number}`;
  }
  return number;
};

export const generateData = (obj, valueKey) => {
  if (valueKey === "date") {
    return obj?.map((item) => {
      const isNotNum = item[valueKey]?.split("-").every((i) => isNaN(+i));
      if (isNotNum) {
        return item[valueKey];
      } else {
        return moment(item[valueKey]).format("Do MMM YYYY");
      }
    });
  }
  return obj?.map((item) => item[valueKey]);
};

export const displayLabel = (apiArray, displayArray) => {
  const selectedJobTypeIds = apiArray.split(",").map(Number);

  const selectedJobTypes = displayArray
    .filter((jobType) => selectedJobTypeIds.includes(jobType.id))
    .map((jobType) => jobType.name);

  return selectedJobTypes.join(", ");
};

export const svgColorCode = (color) => {
  const hexCode = color.replace("#", "%23");
  return hexCode;
};

export const breadcrumbsHeadingGen = (str) => {
  let splitArray = str
    ?.toLowerCase()
    ?.split(" ")
    ?.map((text) => {
      if (!text) return "";
      return text[0].toUpperCase() + text.substring(1);
    });

  for (let i = 0; i < splitArray.length; i++) {
    if (splitArray[i] === "Port") {
      splitArray[i] = "Port-in";
      splitArray.splice(i + 1, 1);
    } else if (splitArray[i] === "Did") {
      splitArray[i] = "DID";
    } else if (splitArray[i] === "Dids") {
      splitArray[i] = "DIDs";
    } else if (splitArray[i] === "Sip") {
      splitArray[i] = "SIP";
    } else if (splitArray[i] === "Cdr") {
      splitArray[i] = "CDR";
    }
  }
  const resultString = splitArray.join(" ");
  return resultString;
};

export const countryCodes = getCountries()
  .sort((a, b) => en[a].localeCompare(en[b]))
  .map((country) => {
    const countryName = en[country];
    const countryCallingCode = `+${getCountryCallingCode(country)}`;
    return { countryShortCode: country, countryName, countryCallingCode };
  });

export const getCountryCode = (countryCode) => {
  return countryCode.slice(countryCode.indexOf("+"));
};

export const getCountryShortCode = (countryCode) => {
  const filterData = countryCodes.filter((obj) => obj.countryCallingCode === countryCode);
  return filterData[0].countryShortCode;
};

export function formatDateStringLocal(isoString) {
  const date = new Date(isoString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");
  hours = hours % 12;
  hours = hours ? hours : 12;
  const formattedDate = `${month}/${day}/${year}`;
  const formattedTime = `${String(hours).padStart(2, "0")}:${minutes}:${seconds}`;

  return `${formattedDate} | ${formattedTime}`;
}

export function formatDateNew(dateString) {
  const options = { day: "2-digit", month: "2-digit", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", options);
}

export function formatDateNotification(dateString, i) {
  const currentSystemDate = new Date();
  const inputDate = new Date(dateString);
  function formatTime(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
  }

  function convertToDay(date) {
    if (i == "menu") {
      const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      return days[date.getDay()];
    } else {
      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      return days[date.getDay()];
    }
  }
  if (currentSystemDate.toDateString() === inputDate.toDateString()) {
    return formatTime(inputDate);
  }
  const differenceInTime = currentSystemDate.getTime() - inputDate.getTime();
  const differenceInDays = differenceInTime / (1000 * 3600 * 24);
  if (differenceInDays <= 3) {
    return convertToDay(inputDate);
  }
  return inputDate.toISOString().split("T")[0];
}
export const formattedDates = (isoDateString) => {
  const date = new Date(isoDateString);
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  return `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
};
