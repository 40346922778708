import pxToRem from "assets/theme/functions/pxToRem";

const AlarmIcon = ({ size = 24 }) => {
  return (
    <svg height={pxToRem(size)} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.81806 21.355L5.8185 21.3545L7.22635 19.9467C8.77092 21.2206 10.7457 21.9917 12.9012 21.9917C15.0567 21.9917 17.0314 21.2206 18.576 19.9467L19.9839 21.3545C20.1523 21.523 20.3739 21.6071 20.5945 21.6071C20.8154 21.6071 21.0357 21.5227 21.2046 21.355L21.205 21.3545C21.5418 21.0178 21.5418 20.4709 21.205 20.1342L19.7972 18.7263C21.0711 17.1817 21.8422 15.2069 21.8422 13.0515C21.8422 8.12076 17.8309 4.11055 12.9011 4.11055C7.97125 4.11055 3.96016 8.12098 3.96016 13.0515C3.96016 15.2067 4.7312 17.1817 6.00518 18.7263L4.59733 20.1342C4.26059 20.4709 4.26059 21.0178 4.59733 21.3545C4.7658 21.523 4.98734 21.6071 5.20792 21.6071C5.42888 21.6071 5.64921 21.5227 5.81806 21.355ZM5.68644 13.0515C5.68644 9.07358 8.92257 5.83683 12.9011 5.83683C16.879 5.83683 20.1157 9.07296 20.1157 13.0515C20.1157 17.0293 16.8788 20.2655 12.9011 20.2655C8.92339 20.2655 5.68644 17.0293 5.68644 13.0515Z"
        fill="#C02B0A"
        stroke="#C02B0A"
        strokeWidth="0.4"
      />
      <path
        d="M15.6323 14.8532L15.6325 14.8529C15.8946 14.4549 15.7847 13.9199 15.3875 13.6576C15.3875 13.6576 15.3875 13.6576 15.3875 13.6576L13.7655 12.5862V8.93462C13.7655 8.45777 13.3792 8.07148 12.9024 8.07148C12.4255 8.07148 12.0393 8.45777 12.0393 8.93462V13.051C12.0393 13.3411 12.1848 13.6118 12.4266 13.7716L12.4266 13.7716L14.4351 15.0979L14.4351 15.0979L14.4364 15.0987C14.5825 15.1936 14.7464 15.2405 14.9109 15.2405C15.1905 15.2405 15.4659 15.1049 15.6323 14.8532Z"
        fill="#C02B0A"
        stroke="#C02B0A"
        strokeWidth="0.4"
      />
      <path
        d="M5.60965 3.8198L5.60942 3.82003L3.66974 5.75971C3.333 6.09645 3.333 6.64333 3.66974 6.98007L3.67019 6.98052C3.8386 7.14785 4.05872 7.23344 4.28033 7.23344C4.50127 7.23344 4.72162 7.14913 4.89047 6.98134L4.89091 6.9809L6.83059 5.04122C7.16733 4.70448 7.16733 4.1576 6.83059 3.82086C6.49306 3.48333 5.9472 3.48333 5.60965 3.8198Z"
        fill="#C02B0A"
        stroke="#C02B0A"
        strokeWidth="0.4"
      />
      <path
        d="M20.1919 3.82016L20.1919 3.82013C19.8551 3.48339 19.3082 3.48339 18.9715 3.82013C18.6348 4.15687 18.6348 4.70376 18.9715 5.0405L20.9112 6.98017C21.079 7.14802 21.2994 7.23355 21.5209 7.23355C21.7419 7.23355 21.9622 7.14924 22.1311 6.98145L22.1315 6.981C22.4682 6.64427 22.4683 6.09741 22.1316 5.76067C22.1315 5.76066 22.1315 5.76065 22.1315 5.76064L20.1919 3.82016Z"
        fill="#C02B0A"
        stroke="#C02B0A"
        strokeWidth="0.4"
      />
    </svg>
  );
};

export default AlarmIcon;
