import React from "react";
import {
  Grid,
  Card,
  TableCell,
  TableRow,
  IconButton,
  Select,
  MenuItem,
  DialogContent,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useState } from "react";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import TableToolbar from "components/SuiTable/TableToolbar";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import { pagesTableHeader } from "./data";
import StatusDropdown from "components/SuiDropDownList/StatusDropdown";
import { useGetRoleMutation } from "app/features/api/AuthApi";
import { useEffect } from "react";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import useNavigateSearch from "hooks/useNavigateSearch";
import SuiTypography from "components/SuiTypography";
import { useFormik } from "formik";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useDeleteRoleMutation } from "app/features/api/AuthApi";
import SuiDialog from "components/SuiDialog";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import DeleteIcon from "Container/Icons/DeleteIcon";
import EditIcon from "Container/Icons/EditIcon";

const Roles = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [roleInfo, setRoleInfo] = useState("");
  const [GetRole, { isLoading }] = useGetRoleMutation();
  const navigateSearch = useNavigateSearch();
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const suiSnackbar = useSuiSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const [deleteRole] = useDeleteRoleMutation();
  const [deleteID, setDeleteID] = useState("");
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/roles-&-permission", { page: page });
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/roles-&-permission", { page: 1 });
  };
  const deleteModal = (id) => {
    setDeleteID(id);
    setOpenModal(true);
  };
  const handleDeleteIconClick = async (id) => {
    try {
      const response = await deleteRole(id).unwrap();
      if (response.status === 200) {
        suiSnackbar(response.message, "success");
        setOpenModal(false);
        // Refresh the data after successful deletion
        showData(perPage, currentPage);
      }
    } catch (error) {
      suiSnackbar(error?.data?.message || "Failed to delete role", "error");
    }
  };
  // Edit Role
  const editRedirect = (item) => {
    localStorage.setItem("admin_role_id", item);
    navigateSearch(`edit-role`);
  };
  const formik = useFormik({
    initialValues: {
      admin_role_name: "",
      admin_role_status: "",
    },

    // enableReinitialize: true,
    onSubmit: () => {
      showData(perPage, 1);
    },
    onReset: () => {
      formik.values.admin_role_name = "";
      formik.values.admin_role_status = "";
      showData(perPage, 1); // Call showData with initial page
    },
  });
  let showData = async (perPage, currentPage) => {
    navigateSearch("/roles-&-permission", { page: currentPage });
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.admin_role_name) {
      urlParamss.admin_role_name = formik.values.admin_role_name;
    }
    if (formik.values.admin_role_status) {
      urlParamss.admin_role_status = formik.values.admin_role_status;
    }
    try {
      const response = await GetRole(urlParamss).unwrap();
      setRoleInfo(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      if (!error.response || !error.response.status) {
        // console.error("Network error:", error.message);
      } else {
        // console.error("Request failed with status:", error.response.status);
      }
      setRoleInfo(null);
      setCount(null);
    }
  };
  useEffect(() => {
    showData(perPage, currentPage);
    localStorage.removeItem("admin_role_id");
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, [perPage, currentPage]);
  const rows =
    roleInfo &&
    roleInfo?.map((item) => {
      // const editRedirect = (item) => {
      //   localStorage.setItem("cus_id", item.cus_account_type);
      //   navigateSearch(`edit-customer/account-details/${cus_id}/${item.cus_account_number}`);
      // };
      // dispatch(setID(cus_id));

      return (
        <>
          <TableRow key={item.id}>
            <TableCell>{`${item.admin_role_name}`}</TableCell>
            <TableCell>
              {item.admin_role_status === "1" ? (
                <SuiTypography fontSize={14} sx={{ color: "#55DED0 !important" }}>
                  Active
                </SuiTypography>
              ) : (
                <SuiTypography fontSize={14} sx={{ color: "#848484 !important" }}>
                  Inactive
                </SuiTypography>
              )}
            </TableCell>
            {permissionInfo.roles.UPDATE && !permissionInfo.roles.DELETE && (
              <TableCell align="right">
                <IconButton onClick={() => editRedirect(item.admin_role_id)}>
                  {<EditIcon />}
                </IconButton>
              </TableCell>
            )}
            {!permissionInfo.roles.UPDATE && permissionInfo.roles.DELETE && (
              <TableCell align="right">
                <IconButton onClick={() => deleteModal(item.admin_role_id)}>
                  {<DeleteIcon />}
                </IconButton>
              </TableCell>
            )}
            {permissionInfo.roles.UPDATE && permissionInfo.roles.DELETE && (
              <TableCell align="right">
                {item.admin_role_name !== "Super Admin" && (
                  <>
                    <IconButton onClick={() => editRedirect(item.admin_role_id)}>
                      {<EditIcon />}
                    </IconButton>
                    <IconButton onClick={() => deleteModal(item.admin_role_id)}>
                      {<DeleteIcon />}
                    </IconButton>
                  </>
                )}
              </TableCell>
            )}
            {/* <TableCell align="right">
              {item.admin_role_name !== "Super Admin" && (
                <>
                  <IconButton onClick={() => editRedirect(item.admin_role_id)}>
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => deleteModal(item.admin_role_id)}>
                    {<Delete />}
                  </IconButton>
                </>
              )}
            </TableCell> */}
          </TableRow>
        </>
      );
    });
  const handlePostPaidSelection = () => {};
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  const isActions =
    permissionInfo?.roles?.UPDATE || permissionInfo?.roles?.DELETE
      ? {
          id: "3",
          align: "right",
          width: "auto",
          label: "Action",
        }
      : null;
  return (
    <>
      <SuiDialog open={openModal} onClose={() => setOpenModal(false)} haveCloseIcon>
        <DialogContent
          sx={{
            fontWeight: "semiBold",
          }}
        >
          Are you sure you want to delete this role?
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"end"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => setOpenModal(false)}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="info"
                    fullWidth
                    onClick={() => handleDeleteIconClick(deleteID)}
                  >
                    Delete
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Role Name"
                  id="admin_role_name"
                  name="admin_role_name"
                  value={formik.values.admin_role_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <StatusDropdown
                  name="admin_role_status"
                  value={formik.values.admin_role_status}
                  setValue={formik.handleChange}
                  handlePostPaidSelection={handlePostPaidSelection}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.resetForm();
                    navigateSearch("/roles-&-permission", { page: 1 });
                    showData(perPage, 1);
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>

      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title="List of Roles">
            {permissionInfo?.roles?.CREATE ? (
              <SuiButton
                component={Link}
                size="small"
                buttonColor="info"
                sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                to={"add-role"}
              >
                Add Role
              </SuiButton>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={540}
            tablehead={isActions ? [...pagesTableHeader, isActions] : [...pagesTableHeader]}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && count > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    {/* Select dropdown for items per page */}
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default Roles;
