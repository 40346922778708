import { CircularProgress } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setCheckCountry } from "app/features/DashboardSlice";
import { setCountry } from "app/features/DashboardSlice";

const AutoCompleteSingleCountry = ({ isLoading, name, setValue, type }) => {
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState({
    country_name: "",
    country_code: "",
  });

  const { countryName } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  dispatch(setCheckCountry(selectedCountry.country_name !== countryName?.country_name));
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}customer/countries`)
      .then((response) => {
        if (
          Array.isArray(response.data.data) &&
          response.data.data.every((item) => item && item.country_name)
        ) {
          setCountries(response.data.data);
        } else {
          console.error("Invalid response format. Expected an array with country_name property.");
        }
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  useEffect(() => {
    const handlePopState = (event) => {
      const state = event.state;
      if (state && state.selectedCountry) {
        setSelectedCountry(state.selectedCountry);
      }
    };
    window.addEventListener("popstate", handlePopState);
    if (type === "editProfile") {
      setSelectedCountry({
        country_name: countryName?.country_name ? countryName?.country_name : "",
        country_code: countryName?.country_code ? countryName?.country_code : "",
      });
    } else {
      setSelectedCountry({
        country_name: "",
        country_code: "",
      });
    }
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [type, countryName]);
  return (
    <Autocomplete
      fullWidth
      popupIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: 12, fill: "#A0B5BE" }} />}
      sx={{
        "& .MuiOutlinedInput-root": {
          flexWrap: "nowrap",
          position: "relative",
          py: 0,
          borderRadius: "0.625rem",
          border: "none",
          bgcolor: isLoading ? "#E5EBED !important" : "#FFF",
          "&> .MuiOutlinedInput-input": {
            border: 0,
            "&+ .MuiCircularProgress-root": {
              position: "absolute",
              top: "11.5px",
              right: "35px",
            },
          },
        },
      }}
      name={name}
      value={selectedCountry}
      onChange={(event, newValue) => {
        if (newValue) {
          setSelectedCountry(newValue);
          setValue(newValue.country_code);
          dispatch(setCountry(newValue.country_code));
        } else {
          setSelectedCountry({
            country_name: "",
            country_code: "",
          });
          setValue("");
          dispatch(setCountry(""));
        }
      }}
      options={countries}
      getOptionLabel={(option) => option?.country_name}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Country"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="info" size={18} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteSingleCountry;
