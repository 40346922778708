import { useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Grid } from "@mui/material";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LinearScale,
  CategoryScale,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, CategoryScale);

import configs from "../Config";
import BoxLegends from "../ChartLegends/BoxLegends";

const DefaultDoughnutChart = ({ height, chart, id }) => {
  const { data, options } = configs(chart.labels, chart.datasets, chart.cutout);
  const updatedOptions = {
    ...options,
    scales: {
      y: {
        display: false,
      },
    },
  };
  const myChartRef = useRef(null);
  const centerText = {
    id: `centerText${id}`,
    beforeDatasetsDraw(chart) {
      const { ctx, data } = chart;
      const xCoor = chart.getDatasetMeta(0).data[0]?.x;
      const yCoor = chart.getDatasetMeta(0).data[0]?.y;
      const total = data.datasets[0].data.reduce((totalVal, num) => totalVal + num, 0);
      ctx.save();
      ctx.font = "800 32px Poppins";
      ctx.fillStyle = "#2B2B2B";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.fillText(total, xCoor, yCoor - 25);
      ctx.font = "400 12px Poppins";
      ctx.fillStyle = "#2B2B2B";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.fillText("Total 3CX", xCoor, yCoor + 10);
      ctx.font = "400 12px Poppins";
      ctx.fillStyle = "#2B2B2B";
      ctx.textAlign = "center";
      ctx.textBaseline = "top";
      ctx.fillText("requests", xCoor, yCoor + 25);
    },
  };
  const toggleLegend = (e, index) => {
    const myChart = myChartRef.current;
    myChart.legend.chart.toggleDataVisibility(index);
    myChart.update();
  };
  const renderChart = (
    <Grid container spacing={4} alignItems="center" py={1}>
      <Grid item xs={6} height={235}>
        <Box p={1} sx={{ height: "100%" }}>
          {useMemo(
            () => (
              <Doughnut
                id={id}
                ref={myChartRef}
                data={data}
                options={updatedOptions}
                plugins={[centerText]}
                style={{ boxSizing: "content-box" }}
              />
            ),
            [chart, height]
          )}
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={1.5}
          pt={2}
          alignItems="center"
          justifyContent="start"
          className="set-text-graph"
        >
          {chart.labels.map((label, index) => (
            <Grid item xs="auto" key={index}>
              <BoxLegends
                id={`${id}_${index}`}
                color={chart.datasets.backgroundColors[index]}
                label={label}
                onLegendFn={(e) => toggleLegend(e, index)}
                labelTextColor={`#2B2B2B`}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );

  return renderChart;
};

DefaultDoughnutChart.defaultProps = {
  height: "100%",
};

DefaultDoughnutChart.propTypes = {
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default DefaultDoughnutChart;
