import CollapseFormLayout from "Container/CollapseFormLayout";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { CustomerModuleRoutes } from "./data";

const CommonCustomerModules = () => {
  const { pathname } = useLocation();
  const { id, accnt_num } = useParams();
  const routes = CustomerModuleRoutes(id, accnt_num);
  let filteredData;
  const cus_id = localStorage.getItem("cus_id");
  if (cus_id != 0) {
    filteredData = routes.filter(
      (item) =>
        item.name !== "Order Details" &&
        item.name !== "Invoice Details" &&
        item.name !== "Spend Limit Details"
    );
  } else {
    filteredData = routes.filter((item) => item.name !== "Transaction Details");
  }
  return (
    <>
      {pathname === `/customer/edit-customer/account-details/${id}/${accnt_num}` ||
      pathname === `/customer/edit-customer/discount-details/${id}/${accnt_num}` ||
      pathname === `/customer/edit-customer/did-details/${id}/${accnt_num}` ||
      pathname === `/customer/edit-customer/sip-trunk-details/${id}/${accnt_num}` ||
      pathname === `/customer/edit-customer/number-port-in-details/${id}/${accnt_num}` ||
      pathname === `/customer/edit-customer/transaction-details/${id}/${accnt_num}` ||
      pathname === `/customer/edit-customer/3cx-details/${id}/${accnt_num}` ||
      pathname === `/customer/edit-customer/invoice-details/${id}/${accnt_num}` ||
      pathname === `/customer/edit-customer/spend-limit-details/${id}/${accnt_num}` ||
      pathname === `/customer/edit-customer/order-details/${id}/${accnt_num}` ||
      pathname === `/customer/edit-customer/cdr-details/${id}/${accnt_num}` ? (
        <CollapseFormLayout routes={filteredData}>
          <Outlet />
        </CollapseFormLayout>
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default CommonCustomerModules;
