import pxToRem from "assets/theme/functions/pxToRem";

const RequestIcon = ({ size }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={pxToRem(size)} viewBox="0 0 25 25">
      <path d="M9.37341 7.92993C8.90172 7.45825 8.13697 7.45825 7.66528 7.92993C7.1936 8.40162 7.1936 9.16637 7.66528 9.63806L10.4347 12.4075L7.66528 15.1769C7.1936 15.6485 7.1936 16.4133 7.66528 16.885C8.13697 17.3567 8.90172 17.3567 9.37341 16.885L12.1428 14.1156L14.9122 16.885C15.3839 17.3567 16.1487 17.3567 16.6203 16.885C17.092 16.4133 17.092 15.6485 16.6203 15.1769L13.8509 12.4075L16.6203 9.63806C17.092 9.16637 17.092 8.40162 16.6203 7.92994C16.1487 7.45825 15.3839 7.45825 14.9122 7.92994L12.1428 10.6994L9.37341 7.92993Z" />
      <path
        clipRule="evenodd"
        d="M12.1427 0.330078C5.47207 0.330078 0.0644531 5.73769 0.0644531 12.4083C0.0644531 19.0789 5.47207 24.4866 12.1427 24.4866C18.8133 24.4866 24.2209 19.0789 24.2209 12.4083C24.2209 5.73769 18.8133 0.330078 12.1427 0.330078ZM2.4801 12.4083C2.4801 7.07182 6.80619 2.74573 12.1427 2.74573C17.4792 2.74573 21.8053 7.07182 21.8053 12.4083C21.8053 17.7449 17.4792 22.0709 12.1427 22.0709C6.80619 22.0709 2.4801 17.7449 2.4801 12.4083Z"
      />
    </svg>
  );
};

export default RequestIcon;
