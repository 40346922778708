import { CircularProgress, Autocomplete, TextField } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

const AutocompleteSingleSelect = ({
  id,
  value,
  setValue,
  noOptionsText,
  placeholder,
  isLoading,
  onInputChange,
  disabled,
  options = [],
}) => {
  const values = options.map((o) => o);

  return (
    <Autocomplete
      id={id}
      fullWidth
      disabled={isLoading || disabled}
      popupIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: 12, fill: "#A0B5BE" }} />}
      sx={{
        "& .MuiOutlinedInput-root": {
          flexWrap: "nowrap",
          position: "relative",
          py: 0,
          borderRadius: "0.625rem",
          border: "none",
          bgcolor: isLoading ? "#E5EBED !important" : "#FFF",
          "&> .MuiOutlinedInput-input": {
            border: 0,
            "&+ .MuiCircularProgress-root": {
              position: "absolute",
              top: "11.5px",
              right: "35px",
            },
          },
        },
      }}
      noOptionsText={noOptionsText}
      value={value || null}
      // onChange={(event, newValue) => {
      //   if (newValue) {
      //     setValue(newValue);
      //   } else {
      //     setValue("");
      //   }
      // }}
      onChange={(_, newValue) => {
        setValue(newValue ? newValue : "");
      }}
      options={values}
      getOptionLabel={(option) => option?.name}
      onInputChange={onInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="info" size={18} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteSingleSelect;
