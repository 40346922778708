import { Box, IconButton, InputAdornment } from "@mui/material";
import React, { useRef } from "react";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import colors from "../../assets/theme/base/colors";
import UIInput from "./commonUIInput";

const ArrowButton = ({ children, ...rest }) => (
  <IconButton
    size="small"
    {...rest}
    sx={{
      bgcolor: "#fff !important",
      borderRadius: "5px",
      padding: "0 !important",
      color: colors.primary.main,
      maxWidth: 30,
      maxHeight: 16,
      fontSize: 24,
      boxShadow: "1px 2px 0 0 #D9DBE7",
    }}
  >
    {children}
  </IconButton>
);

const CommonNumberInput = ({ name, value, placeholder, setValue, onBlur, required, step = 1 }) => {
  const numberInputRef = useRef(null);

  const arrowButtonHandler = (type) => () => {
    let currentValue = parseFloat(numberInputRef.current.value) || 0;

    if (type === "add") {
      currentValue = value < 100 ? Math.min(currentValue + step, 100) : 100;
    } else {
      currentValue = Math.max(currentValue - step, 0);
    }

    setValue(name, currentValue);
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain");
    const regex = /^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)%?$/;

    if (!regex.test(pastedText)) {
      event.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    if (
      ["e", "+", "-"].includes(e.key) ||
      (e.key === "." && numberInputRef.current.value.includes("."))
    ) {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const newValue = e.target.value.replace("%", "");
    if (newValue === "" || /^(\d{1,2}(\.\d{0,2})?|100(\.0{0,2})?)$/.test(newValue)) {
      setValue(name, newValue === "" ? 0 : parseFloat(newValue));
    }
  };

  return (
    <Box position="relative">
      <UIInput
        type="text"
        size="medium"
        name={name}
        id={name}
        value={value === 0 ? "" : `${value}%`}
        placeholder={placeholder}
        inputSettings={{
          autoComplete: "off",
          required: Boolean(required),
        }}
        inputProps={{
          ref: numberInputRef,
          startAdornment: <InputAdornment position="start">kg</InputAdornment>,
          pattern: "^(\\d{1,2}(\\.\\d{1,2})?|100(\\.0{1,2})?)$",
          sx: {
            pr: "2.5rem",
          },
        }}
        handleChange={handleChange}
        onBlur={onBlur}
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
      />

      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        rowGap={0.5}
        top="49%"
        right={10}
        sx={{ transform: "translateY(-50%)" }}
      >
        <ArrowButton onClick={arrowButtonHandler("add")} disabled={value === 100}>
          <ArrowDropUpRoundedIcon />
        </ArrowButton>
        <ArrowButton onClick={arrowButtonHandler("subtract")} disabled={!value}>
          <ArrowDropDownRoundedIcon />
        </ArrowButton>
      </Box>
    </Box>
  );
};

export default CommonNumberInput;
