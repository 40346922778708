import { Box, IconButton, InputAdornment } from "@mui/material";
import React, { useRef } from "react";
import SuiInput from ".";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import colors from "../../assets/theme/base/colors";

const ArrowButton = ({ children, ...rest }) => (
  <IconButton
    size="small"
    {...rest}
    sx={{
      bgcolor: "#fff !important",
      borderRadius: "5px",
      padding: "0 !important",
      color: colors.primary.main,
      maxWidth: 30,
      maxHeight: 16,
      fontSize: 24,
      boxShadow: "1px 2px 0 0 #D9DBE7",
    }}
  >
    {children}
  </IconButton>
);

const DisNumberInput = ({
  name,
  value,
  placeholder,
  onChange,
  setValue,
  max,
  onBlur,
  required,
  type,
  step = 1,
}) => {
  const numberInputRef = useRef(null);

  const arrowButtonHandler = (type) => () => {
    let currentValue = parseFloat(numberInputRef.current.value) || 0;

    if (type === "add") {
      currentValue += step;
    } else {
      currentValue = currentValue - step < 0 ? 0 : currentValue - step;
    }

    numberInputRef.current.value = currentValue.toFixed(2);
    setValue(name, currentValue);
  };

  const handlePaste = (event) => {
    const pastedText = event.clipboardData.getData("text/plain");
    const regex = /^[0-9]*(\.[0-9]*)?$/;

    if (!regex.test(pastedText)) {
      event.preventDefault();
    }
  };

  const handleKeyDown = (e) => {
    const currentValue = numberInputRef.current.value;

    if (
      (!(e.key === "Backspace" || ((e.ctrlKey || e.metaKey) && e.key === "a")) &&
        !/^[0-9.]$/.test(e.key)) ||
      (e.key === "." && currentValue.includes("."))
    ) {
      e.preventDefault();
    }
  };

  return (
    <Box position="relative">
      <SuiInput
        type="text"
        size="medium"
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        inputSettings={{
          autoComplete: "off",
          required: Boolean(required),
        }}
        inputProps={{
          max,
          step,
          ref: numberInputRef,
          pattern: "[0-9.]*",
          inputMode: "numeric",
          min: 0,
          sx: {
            pr: "2.5rem",
            px: 3,
          },
        }}
        onChange={(e) => {
          const regex = /^[0-9.+-]*$/;
          const inputValue = e.target.value;

          if (regex.test(inputValue)) {
            onChange(e);
          }
        }}
        onBlur={onBlur}
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
      />
      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        rowGap={0.5}
        top="49%"
        right={10}
        sx={{ transform: "translateY(-50%)" }}
      >
        <ArrowButton onClick={arrowButtonHandler("add")}>
          <ArrowDropUpRoundedIcon />
        </ArrowButton>
        <ArrowButton onClick={arrowButtonHandler("subtract")} disabled={value === 0}>
          <ArrowDropDownRoundedIcon />
        </ArrowButton>
      </Box>
      {type === "spent" && (
        <Box
          position="absolute"
          display="flex"
          flexDirection="column"
          rowGap={0.5}
          top="49%"
          left={5}
          sx={{ transform: "translateY(-50%)" }}
        >
          <InputAdornment sx={{ fontSize: "1.75rem" }} position="start">
            £
          </InputAdornment>
        </Box>
      )}
    </Box>
  );
};

export default DisNumberInput;
