import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useGetCustomerAccountNoMutation } from "app/features/api/AuthApi";
import { useGetDidCountryMutation } from "app/features/api/AuthApi";

const AutoCompleteAccountNo = ({ formik, type, placeholder }) => {
  const [AccountNo, setCustomerAccountNo] = useState([]);
  const [getDidCountry] = useGetDidCountryMutation();
  const [Country, setCountry] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedAccountNo, setSelectedAccountNo] = useState(null);
  const [getCustomerAccountNo] = useGetCustomerAccountNoMutation();

  const fetchAccountNo = async () => {
    try {
      const response = await getCustomerAccountNo().unwrap();
      setCustomerAccountNo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getAllCountries = async () => {
    try {
      const response = await getDidCountry().unwrap();
      setCountry(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllCountries();
    fetchAccountNo();
  }, []);

  useEffect(() => {
    if (formik.values.country === "") {
      setSelectedCountry(null);
    }
    if (formik.values.cus_account_number === "") {
      setSelectedAccountNo(null);
    }
  }, [formik.values]);

  const handleAccountNoChange = (event, value) => {
    setSelectedAccountNo(value);
    if (value) {
      formik.setFieldValue("cus_account_number", value.cus_sre_id);
      formik.setFieldValue("account_no", value.cus_account_number);
    }
    if (value === null) {
      formik.setFieldValue("cus_account_number", "");
    }
  };
  const handleCountryChange = (event, value) => {
    setSelectedCountry(value);
    if (value) {
      formik.setFieldValue("country", value.attributes?.prefix);
    }
    if (value === null) {
      formik.setFieldValue("country", "");
    }
  };

  return (
    <Autocomplete
      fullWidth
      // disabled={isLoading}
      popupIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: 12, color: "#848484" }} />}
      sx={{
        "& .MuiOutlinedInput-root": {
          flexWrap: "nowrap",
          position: "relative",
          py: 0,
          borderRadius: "0.625rem",
          border: "0",
          "&> .MuiOutlinedInput-input": {
            border: 0,
            "&+ .MuiCircularProgress-root": {
              position: "absolute",
              top: "11.5px",
              right: "35px",
            },
          },
        },
        "& .MuiAutocomplete-popupIndicator": {
          fontSize: "1.4rem !important",
          marginRight: "-10px !important",
        },
      }}
      options={type === "accountNo" ? AccountNo : Country ? Country : []}
      getOptionLabel={(option) =>
        type === "accountNo"
          ? `(${option.cus_account_number}) ${
              option.cus_company_name.charAt(0).toUpperCase() + option.cus_company_name.slice(1)
            } `
          : option?.attributes?.name
      }
      value={type == "accountNo" ? selectedAccountNo : selectedCountry == "" ? "" : selectedCountry}
      onChange={type == "accountNo" ? handleAccountNoChange : handleCountryChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
    />
  );
};

export default AutoCompleteAccountNo;
