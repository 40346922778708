import PropTypes from "prop-types";
import SuiDropDownList from "components/SuiDropDownList";
import { licensePaymentStatus } from "./dropdownArrays";

const CxPaymentStatusDropdown = ({ name, value, setValue }) => {
  return (
    <SuiDropDownList
      data={licensePaymentStatus}
      select={{
        key: "id",
        valueKey: "id",
        labelName: "label",
        label: "Payment Status",
        name: name,
        val: value,
        fn: setValue,
      }}
    />
  );
};

CxPaymentStatusDropdown.defaultProps = {
  name: "payment_status",
};

CxPaymentStatusDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
};

export default CxPaymentStatusDropdown;
