import { Box, Pagination, useMediaQuery, useTheme } from "@mui/material";

const CommonPagination = ({ totalData, currentPage = 1, onPageChange, pageSize = 12, mt = 4 }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box display="flex" justifyContent="center" alignItems="center" mt={mt}>
      <Pagination
        sx={{
          ".MuiPaginationItem-page": { color: "#151515" },
          ".Mui-selected": { color: "#fff !important" },
        }}
        page={Number(currentPage)}
        count={Math.ceil(totalData / pageSize)}
        color="primary"
        siblingCount={mobileView ? 0 : 1}
        onChange={(e, value) => {
          onPageChange(e, value);
        }}
      />
    </Box>
  );
};

export default CommonPagination;
