export const pagesTableHeader = [
  {
    id: "1",
    align: "left",
    width: "auto",
    label: "Role name",
  },
  {
    id: "2",
    align: "left",
    width: "auto",
    label: "Status",
  },
  // {
  //   id: "3",
  //   align: "right",
  //   width: "auto",
  //   label: "Action",
  // },
];
