import { Box, Typography } from "@mui/material";
import colors from "assets/theme/base/colors";

const DashboardCard = ({ title, children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      px={{ xs: 1.5, lg: 3 }}
      py={2.5}
      bgcolor={colors.light.main}
      borderRadius={{ xs: "17px", lg: "20px" }}
      sx={{
        border: "1px solid #4FE2CF",
      }}
    >
      <Typography component="h6" color={colors.text.focus} fontSize={{ xs: 14, lg: 16 }}>
        {title}
      </Typography>
      <Box display="flex" justifyContent="space-between" alignItems="center" pt={{ xs: 1, lg: 2 }}>
        {children}
      </Box>
    </Box>
  );
};

export default DashboardCard;
