import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  IconButton,
  Box,
  Tooltip,
} from "@mui/material";
import { Link } from "react-router-dom";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import TableToolbar from "components/SuiTable/TableToolbar";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import useNavigateSearch from "hooks/useNavigateSearch";
import EditIcon from "Container/Icons/EditIcon";
import EyeIcon from "Container/Icons/EyeIcon";
import DatePickerUi from "components/SuiDateRangePicker/commonSingleDatePicker";
import SuiTypography from "components/SuiTypography";
import { useSubscriptionListMutation } from "app/features/api/AuthApi";
import { useFormik } from "formik";
import SubscriptionStatusDropdown from "components/SuiDropDownList/SubscriptionStatusDropdown";
import dayjs from "dayjs";
import { useSubscriptionExportMutation } from "app/features/api/AuthApi";
import { useSelector } from "react-redux";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import AlertIcon from "Container/Icons/AlertIcon";
import rgba from "assets/theme/functions/rgba";
import colors from "assets/theme/base/colors";
import { formattedDates } from "helper";

const Subscriptions = () => {
  const navigateSearch = useNavigateSearch();
  const [getSubscriptionList, { isLoading }] = useSubscriptionListMutation();
  const [listData, setListData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const formik = useFormik({
    initialValues: {
      cus_sub_stripe_id: "",
      cus_account_number: "",
      sub_purchase_on: "",
      cus_sub_status: "",
    },

    // enableReinitialize: true,
    onSubmit: () => {
      showData(perPage, 1);
    },
    onReset: () => {
      formik.values.cus_sub_stripe_id = "";
      formik.values.cus_account_number = "";
      formik.values.sub_purchase_on = "";
      formik.values.cus_sub_status = "";
      showData(perPage, 1); // Call showData with initial page
    },
  });

  let showData = async (perPage, currentPage) => {
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.cus_sub_stripe_id) {
      urlParamss.cus_sub_stripe_id = formik.values.cus_sub_stripe_id;
    }
    if (formik.values.cus_account_number) {
      urlParamss.cus_account_number = formik.values.cus_account_number;
    }
    if (formik.values.sub_purchase_on) {
      urlParamss.sub_purchase_on = formik.values.sub_purchase_on;
    }
    if (formik.values.cus_sub_status) {
      urlParamss.cus_sub_status =
        Number(formik.values.cus_sub_status) === 0
          ? "Active"
          : Number(formik.values.cus_sub_status) === 1
          ? "Cancelled"
          : "Cancels soon";
    }
    try {
      const response = await getSubscriptionList(urlParamss).unwrap();
      setListData(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      if (!error.response || !error.response.status) {
        // console.error("Network error:", error.message);
      } else {
        // console.error("Request failed with status:", error.response.status);
      }
      setListData(null);
      setCount(null);
    }
  };
  useEffect(() => {
    showData(perPage, currentPage);
    localStorage.removeItem("sub_id");
    // localStorage.removeItem("admin_role_id");
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, [perPage, currentPage]);

  const pagesTableHeader = [
    { id: "1", align: "left", width: "auto", label: "" },
    { id: "1", align: "left", width: "auto", label: "Subscription ID" },
    { id: "2", align: "left", width: "auto", label: "Account Number" },
    { id: "3", align: "left", width: "auto", label: "Customer Company Name" },
    { id: "4", align: "left", width: "auto", label: "Purchased on" },
    { id: "5", align: "left", width: "150px", label: "Next Payment date" },
    { id: "6", align: "left", width: "auto", label: "Status" },
    { id: "7", align: "left", width: "auto", label: "Cancelled on" },
    { id: "8", align: "left", width: "auto", label: "Cancelled by" },
    { id: "9", align: "right", width: "auto", label: "Action" },
  ];

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/subscriptions", { page: page });
  };

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    const { value } = event.target;
    navigateSearch("/subscriptions", { page: 1, perPage: value });
  };

  // Edit Subscription
  const editRedirect = (item) => {
    localStorage.setItem("sub_id", item);
    navigateSearch("edit-subscription");
  };

  //view Subscription
  const viewSubscriptionDetail = (id) => {
    navigateSearch("view-subscription");
    localStorage.setItem("sub_id", id);
  };

  //Export Subscription
  const [downloadAdminDetails] = useSubscriptionExportMutation();
  const downloadDetails = async () => {
    console.log(formik.values.cus_sub_status);
    let urlParamss = {};
    if (formik.values.cus_sub_stripe_id) {
      urlParamss.cus_sub_stripe_id = formik.values.cus_sub_stripe_id;
    }
    if (formik.values.cus_account_number) {
      urlParamss.admin_last_name = formik.values.cus_account_number;
    }
    if (formik.values.sub_purchase_on) {
      urlParamss.sub_purchase_on = formik.values.sub_purchase_on;
    }
    if (formik.values.cus_sub_status) {
      urlParamss.cus_sub_status =
        Number(formik.values.cus_sub_status) === 0
          ? "Active"
          : Number(formik.values.cus_sub_status) === 1
          ? "Cancelled"
          : "Cancels soon";
    }
    const response =
      Object.keys(urlParamss)?.length > 0
        ? await downloadAdminDetails(urlParamss).unwrap()
        : await downloadAdminDetails().unwrap();
    // console.log(response, "ceckking ");
    return response?.data;
  };

  const onDownloadFn = async () => {
    const response = await downloadDetails();
    console.log("response of export", response);
    const downloadData = response?.map((item) => {
      return {
        "SUBSCRIPTION ID": item?.cus_sub_stripe_id,
        "ACCOUNT NUMBER": item?.customers?.cus_account_number,
        "CUSTOMER COMPANY NAME": item?.customers?.cus_company_name,
        "PURCHASED ON": dayjs(item.sub_purchase_on).format("DD/MM/YYYY"),
        "NEXT PAYMENT DATE": item.next_payment_date
          ? dayjs(item.next_payment_date).format("DD/MM/YYYY")
          : " ",
        STATUS: item?.cus_sub_status,
        "CANCELLED ON":
          item.cus_sub_status === "Cancelled" || item.cus_sub_status === "Cancels soon"
            ? dayjs(item.cus_sub_cancelled_on).format("DD/MM/YYYY")
            : "-",
        "CANCELLED BY":
          item.cus_sub_cancelled_by === null && item.cus_sub_cancelled_reference_type === "Customer"
            ? "Self"
            : item.cus_sub_cancelled_by === null && item.cus_sub_cancelled_reference_type === null
            ? "-"
            : item.cus_sub_cancelled_by,
      };
    });
    const csvHeaders = downloadData?.[0] && Object.keys(downloadData?.[0]);
    const csvFormat = [
      csvHeaders && csvHeaders.join(","),
      ...(downloadData || []).map(
        (row) => csvHeaders && csvHeaders.map((header) => row[header])?.join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvFormat], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `Subscription List (${dayjs().format("DD/MM/YYYY | hh:mm A")}).csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];

  const rows =
    listData &&
    listData?.map((item) => {
      return (
        <>
          <TableRow
            key={item.cus_sub_id}
            sx={
              item.cus_sub_overdue === null
                ? {}
                : { background: `${rgba(colors.secondary.main, 0.6)}` }
            }
          >
            <TableCell>
              {item.cus_sub_overdue === null ? (
                ""
              ) : (
                <Tooltip
                  title={
                    <>
                      <SuiTypography
                        textColor="light"
                        fontWeight="regular"
                        variant="body2"
                        sx={{
                          fontSize: "12px",
                        }}
                      >
                        {item.cus_sub_overdue.text}
                      </SuiTypography>
                    </>
                  }
                  arrow
                  placement="bottom"
                  backgroundColor="#000000"
                >
                  <IconButton sx={{ padding: "0" }}>
                    <AlertIcon fontSize={12} color="error" />
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
            <TableCell>{`${item.cus_sub_stripe_id}`}</TableCell>
            <TableCell>{`${item.customers.cus_account_number}`}</TableCell>
            <TableCell>{`${item.customers.cus_company_name}`}</TableCell>
            <TableCell>{dayjs(item.sub_purchase_on).format("DD/MM/YYYY")}</TableCell>
            <TableCell>
              {item.next_payment_date === null
                ? "-"
                : dayjs(item.next_payment_date).format("DD/MM/YYYY")}
            </TableCell>
            <TableCell>
              <SuiTypography
                fontSize="inherit"
                textColor={
                  item.cus_sub_status === "Cancelled"
                    ? "error"
                    : item.cus_sub_status === "Active"
                    ? "success"
                    : "text2"
                }
              >
                {item.cus_sub_status === "Cancelled"
                  ? "Cancelled"
                  : item.cus_sub_status === "Active"
                  ? "Active"
                  : "Cancels Soon"}
              </SuiTypography>
            </TableCell>
            <TableCell>
              {item.cus_sub_status === "Cancelled" || item.cus_sub_status === "Cancels soon"
                ? item.cus_sub_cancelled_on == null
                  ? "-"
                  : formattedDates(item.cus_sub_cancelled_on)
                : "-"}
            </TableCell>
            <TableCell>
              {item.cus_sub_cancelled_reference_type === "System"
                ? "System"
                : item.cus_sub_cancelled_reference_type === "Customer"
                ? "Self"
                : item.admins === null && item.cus_sub_cancelled_reference_type === null
                ? "-"
                : `${item.admins?.admin_name || ""} ${item.admins?.admin_last_name || ""}`?.trim()}
            </TableCell>
            <TableCell align="right">
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "right",
                }}
              >
                {item.cus_sub_status === "Active" && permissionInfo?.subscription?.UPDATE ? (
                  <IconButton onClick={() => editRedirect(item.cus_sub_id)}>
                    {<EditIcon />}
                  </IconButton>
                ) : null}
                <IconButton
                  className="viewIcon"
                  onClick={() => {
                    viewSubscriptionDetail(item.cus_sub_id);
                  }}
                >
                  <EyeIcon />
                </IconButton>
              </Box>
            </TableCell>
          </TableRow>
        </>
      );
    });

  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Subscription ID"
                  id="cus_sub_stripe_id"
                  name="cus_sub_stripe_id"
                  value={formik.values.cus_sub_stripe_id}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="search"
                  placeholder="Account Number"
                  id="cus_account_number"
                  name="cus_account_number"
                  value={formik.values.cus_account_number}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <DatePickerUi
                  placeholder="Date"
                  value={formik.values.sub_purchase_on}
                  color="primary"
                  formik={formik}
                  getValue={(value) => formik.setFieldValue("sub_purchase_on", value)}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SubscriptionStatusDropdown
                  name="cus_sub_status"
                  value={formik.values.cus_sub_status}
                  setValue={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.resetForm();
                    navigateSearch("/subscriptions");
                    showData(perPage, 1);
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>

      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title="List of Subscriptions">
            <SuiButton
              component={Link}
              size="small"
              buttonColor="info"
              sx={{ fontWeight: "500", px: "12px", ml: 2 }}
              onClick={onDownloadFn}
            >
              Export
            </SuiButton>
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={540}
            tablehead={pagesTableHeader}
            rowsData={rows}
            isLoading={isLoading}
          />
          {count > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};
export default Subscriptions;
