import React, { useState } from "react";
import { Popover, Typography } from "@mui/material";
import ActiveIcon from "Container/Icons/ActiveIcon";
import InactiveIcon from "Container/Icons/InactiveIcon";
import SuiBox from "components/SuiBox";
import IdealICon from "Container/Icons/IdealICon";

const CronStatus = ({ status }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <SuiBox
        fontSize="14px"
        display="flex"
        borderRadius="16px"
        fontWeight="600"
        alignItems="center"
        columnGap="8px"
        px={2}
        py={1.5}
        mx={1.5}
        backgroundColor="#F2F4FF"
        sx={{
          cursor: status ? "auto" : "pointer",
        }}
        onClick={status?.cron_status == "Inactive" ? handleClick : () => null}
      >
        {(() => {
          switch (status?.cron_status) {
            case "Ideal":
              return <ActiveIcon size={24} />;

            case "Active":
              return <IdealICon size={24} />;
            case "Inactive":
              return <InactiveIcon size={24} />;
            default:
              return null;
          }
        })()}

        <span style={{ marginTop: "-2px" }}>Cron Status</span>
      </SuiBox>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Typography px={1.2} py={1} maxWidth={250} fontSize={14}>
          {status?.cron_description}
        </Typography>
      </Popover>
    </>
  );
};

export default CronStatus;
