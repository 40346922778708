import colors from "../../base/colors";
import borders from "../../base/borders";
import pxToRem from "../../functions/pxToRem";

const { white, primary } = colors;
const { borderWidth, borderColor } = borders;

export default {
  styleOverrides: {
    root: {
      background: white.main,
      fill: white.main,
      stroke: white.main,
      strokeWidth: pxToRem(10),
      width: pxToRem(13),
      height: pxToRem(13),
      border: `${borderWidth[2]} solid ${borderColor}`,
      borderRadius: "50%",
      zIndex: 99,
      transition: "all 200ms linear",
      display: "none",
      position: "absolute",
      top: "14px",
      left: "20px",
      "&.MuiStepLabel-iconContainer": {
        position: "absolute",
        left: "10px",
        "&.Mui-completed": {
          display: "block",
          "&.svg": {
            fill: primary.main,
          },
        },
      },
    },
  },
};
