import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { FormLabel, Grid, CircularProgress } from "@mui/material";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiTypography from "components/SuiTypography";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import {
  useGet2faMutation,
  usePost2faMutation,
  useVerify2faMutation,
  useReset2faMutation,
} from "app/features/api/AuthApi";
import { useFormik } from "formik";
import { addotpSchema } from "Validation";
import useSuiSnackbar from "hooks/useSuiSnackbar";

const TwoFactorAuthntication = () => {
  const [apiGet2fa, setapiGet2fa] = useState("");
  const [apiPost2fa, setapiPost2fa] = useState("");
  const [screateCode, setscreateCode] = useState("");
  const [VerifiedCode, setsVerifiedCode] = useState("");
  const [QrImg, setQrImg] = useState("");
  const [Get2fa, { isLoading }] = useGet2faMutation();
  const [Post2fa] = usePost2faMutation();
  const [verifyOTP] = useVerify2faMutation();
  const [Reset2fa] = useReset2faMutation();
  const suiSnackbar = useSuiSnackbar();
  const [loading, setLoading] = useState(false);
  const show2fa = async () => {
    try {
      const response = await Get2fa().unwrap();
      if (response.status === 200) {
        if (response.data.admin_2fa_id && response.data.admin_2fa_is_verified === "0") {
          setapiGet2fa(response.data);
          setscreateCode(response.data.admin_2fa_secret);
          setQrImg(response.data.admin_2fa_qr_json.qrBase64);
        } else {
          setsVerifiedCode(response.data.admin_2fa_is_verified);
        }
      }
    } catch (error) {
      if (error?.data?.errorMessages?.fieldErrors) {
        const fieldErrors = error.data.errorMessages.fieldErrors;
        for (const field in fieldErrors) {
          if (Object.hasOwn(fieldErrors, field)) {
            fieldErrors[field].forEach((errorMessage) => {
              console.error(`Error in ${field}: ${errorMessage}`);
              suiSnackbar(errorMessage || "An error occurred", "error");
            });
          }
        }
      } else {
        suiSnackbar(error?.data?.message || "An error occurred", "error");
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: addotpSchema,
    onSubmit: async (values) => {
      setLoading(true);
      const body = {
        otp: values.otp,
      };
      try {
        const updateOtp = await verifyOTP(body).unwrap();
        if (updateOtp.status === 200) {
          setapiGet2fa();
          setsVerifiedCode(updateOtp.status);
          suiSnackbar("The authenticator app has been verified", "success");
        } else {
          console.error("Error verifying OTP:", updateOtp.message);
        }
      } catch (error) {
        if (error?.data?.errorMessages?.fieldErrors) {
          const fieldErrors = error.data.errorMessages.fieldErrors;
          for (const field in fieldErrors) {
            if (Object.hasOwn(fieldErrors, field)) {
              fieldErrors[field].forEach((errorMessage) => {
                console.error(`Error in ${field}: ${errorMessage}`);
                suiSnackbar(errorMessage || "An error occurred", "error");
              });
            }
          }
        } else {
          suiSnackbar(error?.data?.message || "An error occurred", "error");
        }
      } finally {
        setLoading(false);
      }
    },
  });
  const authRetry = () => {
    show2fa();
  };
  const authRedirect = async () => {
    setLoading(true);
    try {
      const response = await Post2fa().unwrap();
      setapiPost2fa(response.data);
      console.log(apiPost2fa);
      if (response.data.admin_2fa_id && response.data.admin_2fa_is_verified === "0") {
        setapiGet2fa(response.data);
        setscreateCode(response.data.admin_2fa_secret);
        setQrImg(response.data.admin_2fa_qr_json.qrBase64);
      }
    } catch (error) {
      if (error?.data?.errorMessages?.fieldErrors) {
        const fieldErrors = error.data.errorMessages.fieldErrors;
        for (const field in fieldErrors) {
          if (Object.hasOwn(fieldErrors, field)) {
            fieldErrors[field].forEach((errorMessage) => {
              console.error(`Error in ${field}: ${errorMessage}`);
              suiSnackbar(errorMessage || "An error occurred", "error");
            });
          }
        }
      } else {
        suiSnackbar(error?.data?.message || "An error occurred", "error");
      }
    } finally {
      setLoading(false);
    }
  };
  const authReset = async () => {
    setLoading(true);
    try {
      const response = await Reset2fa().unwrap();
      setsVerifiedCode("");
      formik.setFieldValue("otp", "");
      setapiGet2fa("");
      suiSnackbar(response.message, "success");
    } catch (error) {
      if (error?.data?.errorMessages?.fieldErrors) {
        const fieldErrors = error.data.errorMessages.fieldErrors;
        for (const field in fieldErrors) {
          if (Object.hasOwn(fieldErrors, field)) {
            fieldErrors[field].forEach((errorMessage) => {
              console.error(`Error in ${field}: ${errorMessage}`);
              suiSnackbar(errorMessage || "An error occurred", "error");
            });
          }
        }
      } else {
        suiSnackbar(error?.data?.message || "An error occurred", "error");
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    show2fa();
  }, []);

  return (
    <Grid container>
      {!isLoading &&
        (apiGet2fa?.admin_2fa_id ? (
          <>
            <Grid item xs={12}>
              <SuiTypography sx={{ color: "#2B2B2B", fontSize: "18px", fontWeight: "600" }}>
                Configure Google Authenticator App
              </SuiTypography>
              <SuiTypography sx={{ fontSize: "18px", color: "#848484", mt: 1, fontWeight: "400" }}>
                To use the Google authenticator app go through the following Steps:
              </SuiTypography>
              <SuiTypography
                sx={{
                  fontSize: "18px",
                  color: "#2B2B2B",
                  mt: 2,
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "baseline",
                  gap: "10px",
                }}
              >
                <span>1.</span>
                <span>Download the google authenticator app for Android or iOS</span>
              </SuiTypography>
              <SuiTypography
                sx={{
                  fontSize: "18px",
                  color: "#2B2B2B",
                  mt: 2,
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "baseline",
                  gap: "10px",
                }}
              >
                <span>2.</span>
                <span>
                  Scan QR Code or enter this key{" "}
                  <SuiTypography
                    sx={{
                      fontSize: "18px",
                      background: "#2B2B2B",
                      color: "#ffffff !important",
                      borderRadius: "30px",
                      fontWeight: "400",
                      display: "inline-block",
                      px: 2,
                      py: 0,
                    }}
                  >
                    {" "}
                    {screateCode}{" "}
                  </SuiTypography>{" "}
                  into your google authenticator. Spaces and Casing do not matter
                </span>
              </SuiTypography>
              <SuiTypography sx={{ fontSize: "16px", color: "#848484", mt: 2, px: 2 }}>
                QR Code
              </SuiTypography>
              {QrImg ? (
                <img src={QrImg} alt="QRCODE" width="150px" />
              ) : (
                <>
                  <SuiTypography sx={{ fontSize: "16px", color: "#848484", mt: 2, px: 2 }}>
                    QR code could not be loaded
                  </SuiTypography>
                  <SuiButton
                    type="submit"
                    size="small"
                    buttonColor="primary"
                    sx={{ px: 2, mt: 2, ml: 2 }}
                    onClick={() => authRetry()}
                  >
                    Retry
                  </SuiButton>
                </>
              )}{" "}
              {}
              <SuiTypography
                sx={{
                  fontSize: "18px",
                  color: "#2B2B2B",
                  mt: 2,
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "baseline",
                  gap: "10px",
                }}
              >
                <span>3.</span>
                <span>
                  Once you have scanned the QR Code or input the key above, your two factor
                  authentication app will provide you with a unique code. Enter the code in the
                  confirmation box below.
                </span>
              </SuiTypography>
            </Grid>
            <Grid item xs={12} md={6} component="form">
              <FormLabel htmlFor="otp" required sx={{ mt: 2 }}>
                Verification Code
              </FormLabel>
              <SuiInput
                inputSettungs={{ autoComplete: "off", required: true }}
                type="text"
                placeholder="Verification Code"
                id="otp"
                name="otp"
                value={formik.values.otp}
                onChange={formik.handleChange}
              />
              <ErrorMessageComponent touched={formik.touched.otp} error={formik.errors.otp} />
              <SuiButton
                type="submit"
                size="small"
                buttonColor="primary"
                sx={{ px: "1rem", mt: 2 }}
                onClick={formik.handleSubmit}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : "Verify"}
              </SuiButton>
            </Grid>
          </>
        ) : (
          <>
            {VerifiedCode ? (
              <>
                <SuiButton type="button" size="small" buttonColor="primary" sx={{ px: "1rem" }}>
                  Your Google authentication has been verified.
                </SuiButton>
                <FormLabel
                  htmlFor="verifiaction_code"
                  sx={{ mt: 2, display: "block", width: "100%;" }}
                >
                  Google Authenticator
                </FormLabel>
                <SuiButton type="submit" buttonColor="info" onClick={() => authReset()}>
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Reset Two-Factor Authentication"
                  )}
                </SuiButton>
              </>
            ) : (
              <>
                <SuiTypography>Two-Factor Authentication (2FA)</SuiTypography>
                <FormLabel
                  htmlFor="verifiaction_code"
                  sx={{ mt: 2, display: "block", width: "100%;" }}
                >
                  Google Authenticator
                </FormLabel>
                <SuiButton type="submit" buttonColor="info" onClick={() => authRedirect()}>
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Setup Two-Factor Authentication"
                  )}
                </SuiButton>
              </>
            )}
          </>
        ))}
    </Grid>
  );
};

export default TwoFactorAuthntication;
