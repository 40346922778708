const InfoIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="15"
      width="15"
      viewBox="0 0 21 21"
      fill="#848484"
    >
      <path d="M10.3472 0.847656C8.36936 0.847656 6.43596 1.43415 4.79147 2.53296C3.14698 3.63177 1.86525 5.19356 1.10838 7.02082C0.351501 8.84808 0.153467 10.8587 0.53932 12.7986C0.925172 14.7384 1.87758 16.5202 3.2761 17.9187C4.67463 19.3172 6.45646 20.2697 8.39627 20.6555C10.3361 21.0414 12.3467 20.8433 14.174 20.0865C16.0013 19.3296 17.5631 18.0478 18.6619 16.4034C19.7607 14.7589 20.3472 12.8255 20.3472 10.8477C20.3472 9.53444 20.0885 8.23408 19.586 7.02082C19.0834 5.80757 18.3468 4.70517 17.4182 3.77659C16.4897 2.848 15.3873 2.11141 14.174 1.60886C12.9608 1.10631 11.6604 0.847656 10.3472 0.847656ZM10.3472 18.8477C8.76492 18.8477 7.2182 18.3785 5.90261 17.4994C4.58702 16.6204 3.56164 15.3709 2.95614 13.9091C2.35064 12.4473 2.19221 10.8388 2.50089 9.28693C2.80957 7.73509 3.5715 6.30962 4.69032 5.1908C5.80914 4.07198 7.2346 3.31006 8.78645 3.00137C10.3383 2.69269 11.9468 2.85112 13.4086 3.45662C14.8704 4.06212 16.1199 5.0875 16.9989 6.40309C17.878 7.71869 18.3472 9.26541 18.3472 10.8477C18.3472 12.9694 17.5043 15.0042 16.004 16.5045C14.5037 18.0048 12.4689 18.8477 10.3472 18.8477Z" />
      <path d="M10.3472 7.84766C10.8995 7.84766 11.3472 7.39994 11.3472 6.84766C11.3472 6.29537 10.8995 5.84766 10.3472 5.84766C9.79488 5.84766 9.34717 6.29537 9.34717 6.84766C9.34717 7.39994 9.79488 7.84766 10.3472 7.84766Z" />
      <path d="M10.3472 8.84766C10.082 8.84766 9.8276 8.95301 9.64006 9.14055C9.45253 9.32809 9.34717 9.58244 9.34717 9.84766V14.8477C9.34717 15.1129 9.45253 15.3672 9.64006 15.5548C9.8276 15.7423 10.082 15.8477 10.3472 15.8477C10.6124 15.8477 10.8667 15.7423 11.0543 15.5548C11.2418 15.3672 11.3472 15.1129 11.3472 14.8477V9.84766C11.3472 9.58244 11.2418 9.32809 11.0543 9.14055C10.8667 8.95301 10.6124 8.84766 10.3472 8.84766Z" />
    </svg>
  );
};

export default InfoIcon;
