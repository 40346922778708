import { CircularProgress, FormLabel } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiInput from "components/SuiInput";
import SuiButton from "components/SuiButton";
import { useFormik } from "formik";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useDispatch } from "react-redux";
import { setCredentials } from "app/features/AuthSlice";
import { useLoginWithOtpMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { authverifySchema } from "Validation";

const OtpVerify = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginWithOtp] = useLoginWithOtpMutation();
  const suiSnackbar = useSuiSnackbar();
  const { id } = useParams();

  const formik = useFormik({
    initialValues: { otp: "" },
    validationSchema: authverifySchema,
    onSubmit: async (values) => {
      const body = {
        admin_id: id,
        otp: values.otp,
      };
      try {
        const response = await loginWithOtp(body).unwrap();
        if (response.status === 200) {
          localStorage.setItem("auth_token_admin", response.data.token);
          localStorage.setItem("tokenExpiryTime_admin", response.data.token_expire_at);
          localStorage.setItem("loggedInTime_admin", new Date());
          localStorage.setItem("refresh_token_admin", response.data.refresh_token);
          dispatch(setCredentials({ token_admin: response.data.token, isAuthenticated: true }));
          navigate("/dashboard");
        }
      } catch (error) {
        if (error?.data?.errorMessages?.fieldErrors) {
          const fieldErrors = error.data.errorMessages.fieldErrors;
          for (const field in fieldErrors) {
            if (Object.hasOwn(fieldErrors, field)) {
              fieldErrors[field].forEach((errorMessage) => {
                console.error(`Error in ${field}: ${errorMessage}`);
                suiSnackbar(errorMessage || "An error occurred", "error");
              });
            }
          }
        } else {
          suiSnackbar(error?.data?.message || "An error occurred", "error");
        }
      }
    },
  });
  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Two Factor Authentication
      </SuiTypography>
      <SuiTypography component="p" textColor="text2" variant="formSubHeading">
        Your sign in is protected with an Google Authenticator app, Enter your authenticator code
        below.
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={3}>
          <FormLabel htmlFor="otp" required>
            Authentication Code
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            type="text"
            placeholder="Authentication Code"
            id="otp"
            name="otp"
            value={formik.values.otp}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.otp} error={formik.errors.otp} />
        </SuiBox>
      </SuiBox>

      <SuiBox mt={2} mb={0.5}>
        <SuiButton
          tabIndex={4}
          type="submit"
          disabled={formik.isSubmitting}
          variant="contained"
          buttonColor="primary"
          fullWidth
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? (
            <CircularProgress size="25px" sx={{ color: "blue" }} />
          ) : (
            "Sign in"
          )}
        </SuiButton>
      </SuiBox>
    </>
  );
};
export default OtpVerify;
