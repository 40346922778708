import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import React, { useCallback, useEffect, useState } from "react";
import boxShadow from "assets/theme/functions/boxShadow";
import { Link, useLocation, useNavigate } from "react-router-dom";
import colors from "assets/theme/base/colors";
import rgba from "assets/theme/functions/rgba";
import SuiButton from "components/SuiButton";
import Nodata from "layouts/customer/components/Nodata";
import CommonSpinner from "components/CommonSpinner";
import BaseTableUi from "components/SuiTable/BaseTableUI";
import AlertIcon from "Container/Icons/AlertIcon";
import { formattedDates } from "helper";
import { useInvoiceDetailsMutation } from "app/features/api/InvoiceApi";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import { useSubGetByIdMutation } from "app/features/api/AuthApi";
import { useGetAlldidByIdMutation } from "app/features/api/AuthApi";
import { useDownloadInvoiceMutation } from "app/features/api/AuthApi";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useFormik } from "formik";
import { useSupplierDropListMutation } from "app/features/api/AuthApi";
import { debounce } from "lodash";
import { useRangeHolderDropListMutation } from "app/features/api/AuthApi";
import SuiInput from "components/SuiInput";
import { useGetAssignSipTrunkMutation } from "app/features/api/AuthApi";
import { AddDidsSchema } from "Validation";
import { usePurchasedUpdateMutation } from "app/features/api/AuthApi";
import IntVIew from "Container/Icons/InternationalDIDIcon";

const ViewPurchase = () => {
  const [invInfo, setInvInfo] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [subInfo, setSubInfo] = useState("");
  const id = localStorage.getItem("did_id");

  const [perPageData, setCount] = useState();
  const [purchaseDid, setPurchaseDid] = useState();
  const [perPage, setPerPage] = useState(10);
  const location = useLocation();
  const [supplierListVal, setSupplierListVal] = useState();
  const navigate = useNavigate();
  const { pathname } = location;
  const [rangeListVal, setRangeListVal] = useState();

  const [rangeHolderDropList] = useRangeHolderDropListMutation();

  const checkPath = pathname.includes("edit-purchased-did");

  const handleBackClick = () => {
    navigate("/purchased-dids");
  };
  const [InvoiceDetails, { isLoading: isLoadingInv }] = useInvoiceDetailsMutation();
  const [getAlldidById, { isLoading: isLoading }] = useGetAlldidByIdMutation();
  const [SubGetById] = useSubGetByIdMutation();
  const [searchTextRange, setSearchTextRange] = useState("");

  const [searchText, setSearchText] = useState("");

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
  };
  const getSubData = async (id) => {
    try {
      const response = await SubGetById(id).unwrap();
      setSubInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  // const [flag, setFlag] = useState();
  const [PurchasedUpdate] = usePurchasedUpdateMutation();
  const formik = useFormik({
    initialValues: {
      did_supplier_status: "",
      did_supplier_status1: "",
      range_holder_name: "",
      range_holder_name1: "",
      did_num: "",
      sip_name: "",
      is_golden: false,
      is_fuse2: false,
      is_diverted: false,
      is_allocate_sip: false,
      number: "",
      cus_account_number: "",
      sip_id: "",
      cus_id: "",
    },
    validationSchema: purchaseDid?.did_purchased_from == "DID Inventory" ? AddDidsSchema : "",
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        ...(values.did_supplier_status !==
          purchaseDid?.did_inventorys?.did_suppliers?.did_supplier_id && {
          did_supplier_id: values.did_supplier_status,
        }),
        ...(values.range_holder_name !==
          purchaseDid?.did_inventorys?.did_range_holders?.did_range_holder_id && {
          did_range_holder_id: values.range_holder_name,
        }),
        ...((values.is_golden == true
          ? "1"
          : "0" !== purchaseDid?.did_inventorys?.did_inventory_is_golden_number) && {
          did_inventory_is_golden_number: values.is_golden == true ? "1" : "0",
        }),
        ...((values.is_fuse2 == true
          ? "1"
          : "0" !== purchaseDid?.did_inventorys?.did_inventory_is_fuse2_number) && {
          did_inventory_is_fuse2_number: values.is_fuse2 == true ? "1" : "0",
        }),
        ...((values.is_diverted ? 1 : 0) != purchaseDid?.main_service.diverted && {
          is_diverted: values.is_diverted,
        }),
        ...(values.is_diverted &&
          values.did_num !== purchaseDid?.main_service.backup_number && {
            divert_backup_number: values.did_num,
          }),

        did_number: values.number,
        ...(values.sip_name !== purchaseDid?.main_service.username &&
          values.is_allocate_sip != false && {
            allocated_sip_trunk: values.is_allocate_sip,
          }),
        ...(values.is_allocate_sip == false &&
          values.sip_id != "-" && {
            allocated_sip_trunk: values.is_allocate_sip,
          }),

        sip_trunk_obj: {
          sip_id: String(values.sip_id),
          sip_username: values.is_allocate_sip === false ? "-" : values.sip_name,
          number: values.number,
          cus_account_number: values.cus_account_number,
          cus_id: values.cus_id,
        },

        id,
      };
      const isSipServerBlank =
        (formik.touched.sip_name &&
          formik.values.is_allocate_sip &&
          formik.values.sip_name === "" &&
          formik.touched.did_num &&
          formik.values.is_diverted &&
          formik.values.did_num === "") ||
        formik.values.sip_name === null;

      const isDidNumInvalid =
        formik.values.is_diverted == true &&
        formik.values.did_num !== "" &&
        !/^\+?\d+$/.test(formik.values.did_num);

      const shouldValidateDidNum = formik.values.is_diverted;
      if (
        (formik.values.is_allocate_sip && formik.values.sip_name === "") ||
        formik.values.sip_name == null
      ) {
        return;
      }
      if (!isSipServerBlank && (!shouldValidateDidNum || !isDidNumInvalid)) {
        if (shouldValidateDidNum && formik.values.did_num === "") {
          return;
        }

        try {
          const response = await PurchasedUpdate(body).unwrap();
          navigate("/purchased-dids");
          suiSnackbar(response.message, "success");
        } catch (error) {
          const issues = error.data.errorMessages.issues;
          if (issues) {
            issues.map((item) => {
              console.log("error", item);
              suiSnackbar(item.message, "error");
            });
          }
        }
      }
    },
  });
  const RangeList = useCallback(
    debounce(async (value) => {
      setSearchTextRange(value?.target?.value || "");

      try {
        const response = await rangeHolderDropList().unwrap();
        setRangeListVal(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );
  const GetAllPurchaseInfo = async (id) => {
    try {
      const response = await getAlldidById(id).unwrap();
      setPurchaseDid(response.data);
      if (
        response.data.main_service.diverted == "0" ||
        response.data.main_service.diverted == "-"
      ) {
        setShowDropdown(false);
      } else {
        setShowDropdown(true);
      }
      if (response.data.main_service.username == "-") {
        setShowDropdownSip(false);
      } else {
        setShowDropdownSip(true);
      }

      formik.setFieldValue(
        "did_supplier_status",
        response.data?.did_inventorys?.did_suppliers?.did_supplier_id
      );
      const transformedRoles = {
        id: response.data?.did_inventorys?.did_suppliers?.did_supplier_id,
        name: response.data?.did_inventorys?.did_suppliers?.did_supplier_name,
      };
      const transformedHolder = {
        id: response.data?.did_inventorys?.did_range_holders?.did_range_holder_id,
        name: response.data?.did_inventorys?.did_range_holders?.did_range_holder_name,
      };
      if (response.data?.did_inventorys?.did_range_holders?.did_range_holder_id) {
        formik.setFieldValue("did_supplier_status1", transformedRoles);
      }
      formik.setFieldValue(
        "range_holder_name",
        response.data?.did_inventorys?.did_range_holders?.did_range_holder_id
      );
      if (response.data?.did_inventorys?.did_range_holders?.did_range_holder_id) {
        formik.setFieldValue("range_holder_name1", transformedHolder);
      }
      formik.setFieldValue(
        "is_fuse2",
        response.data?.did_inventorys?.did_inventory_is_fuse2_number == "1" ? true : false
      );
      formik.setFieldValue(
        "is_golden",
        response.data?.did_inventorys?.did_inventory_is_golden_number == "1" ? true : false
      );
      formik.setFieldValue(
        "is_diverted",
        response.data.main_service.diverted == "0" || response.data.main_service.diverted == "-"
          ? false
          : true
      );
      formik.setFieldValue(
        "did_num",
        response.data.main_service.backup_number == "-" ||
          response.data.main_service.backup_number == "None"
          ? ""
          : response.data.main_service.backup_number
      );
      formik.setFieldValue(
        "is_allocate_sip",
        response.data.main_service.sip_id == "-" ? false : true
      );
      formik.setFieldValue(
        "sip_name",
        response.data.main_service.username == "-" ? "" : response.data.main_service.username
      );
      formik.setFieldValue("sip_id", response.data.main_service.sip_id);
      formik.setFieldValue("number", response?.data?.did_number);
      formik.setFieldValue("cus_account_number", response.data.customers.cus_account_number);
      formik.setFieldValue("cus_id", response.data.customers.cus_id);
    } catch (error) {
      console.log(error);
    }
  };
  const invoiceDetailss = async (id, pageNo, perPage) => {
    const params = {
      results_per_page: perPage,
      page: pageNo,
    };
    try {
      const response = await InvoiceDetails({ params, id }).unwrap();
      setInvInfo(response.data);
      setCount(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!checkPath) {
      invoiceDetailss(purchaseDid?.cus_sub_id, currentPage, perPage);
      getSubData(purchaseDid?.cus_sub_id);
    }
    GetAllPurchaseInfo(id);
  }, [perPage, currentPage, purchaseDid?.cus_sub_id]);

  const [loadingId, setLoadingId] = useState(null);
  const suiSnackbar = useSuiSnackbar();
  const invoiceHeader = [
    { id: "1", width: "auto", align: "left", title: "" },
    { id: "1", width: "auto", align: "left", title: "Payment ID" },
    { id: "2", width: "auto", align: "left", title: "Payment date" },
    { id: "3", width: "auto", align: "left", title: "Payment Method" },
    { id: "4", width: "auto", align: "left", title: "Paid Amount" },
    { id: "5", width: "auto", align: "left", title: "Payment Status" },
    { id: "6", width: "auto", align: "left", title: "Action" },
  ];
  const [DownloadInvoices] = useDownloadInvoiceMutation();

  const [supplierDropList] = useSupplierDropListMutation();

  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  const DownloadInvoice = async (id) => {
    setLoadingId(id);
    try {
      const response = await DownloadInvoices(id).unwrap();
      window.open(response.data.invoice_pdf, "_blank");
    } catch (error) {
      suiSnackbar(error.message, "error");
    } finally {
      setLoadingId(null);
    }
  };
  const supplierData = supplierListVal?.map((dropval) => ({
    id: dropval.did_supplier_id,
    label: dropval.did_supplier_name,
  }));
  const rangeData = rangeListVal?.map((dropval) => ({
    id: dropval.did_range_holder_id,
    label: dropval.did_range_holder_name,
  }));
  const featuresFilters = {
    voice_in: "Voice IN",
    voice_out: "Voice OUT",
    t38: "T.38 Fax",
    sms_in: "SMS IN",
    sms_out: "SMS OUT",
  };

  const featuresData = purchaseDid?.did_number_features?.map((item) => {
    return featuresFilters[item];
  });

  const SupplierLists = useCallback(
    debounce(async (value) => {
      const params = value ? { keyword: value?.target?.value } : {};
      setSearchText(value?.target?.value || "");

      try {
        const response = await supplierDropList(params).unwrap();
        setSupplierListVal(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );
  useEffect(() => {
    if (searchTextRange.length > 0) {
      RangeList();
    } else {
      setRangeListVal([]);
    }
  }, [searchTextRange, RangeList]);
  useEffect(() => {
    if (checkPath) {
      RangeList();
      SupplierLists();
    }
  }, []);
  useEffect(() => {
    if (searchText.length > 0) {
      SupplierLists(searchText);
    } else {
      setSupplierListVal([]);
    }
  }, [searchText, SupplierLists]);
  console.log(formik.values, "FOrmik");
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownSip, setShowDropdownSip] = useState(false);

  const handleCheckboxChange = (event) => {
    formik.setFieldValue("is_golden", event.target.checked);
  };
  const handleCheckboxChangeFuse = (event) => {
    formik.setFieldValue("is_fuse2", event.target.checked);
  };

  const handleCheckboxChangeDivert = (event) => {
    const isChecked = event.target.checked;

    setShowDropdown(isChecked);
    formik.setFieldValue("is_diverted", isChecked);
  };
  const handleCheckboxChangeSip = (event) => {
    const isChecked = event.target.checked;
    setShowDropdownSip(isChecked);
    formik.setFieldValue("is_allocate_sip", event.target.checked);
  };
  const [SipDetails, setSipDetails] = useState([]);
  const [getAssignSipTrunk] = useGetAssignSipTrunkMutation();

  const getCustomerNo = async () => {
    try {
      const response = await getAssignSipTrunk(purchaseDid?.customers?.cus_id).unwrap();
      setSipDetails(response?.data);
    } catch (error) {
      setSipDetails([]);
      console.log(error);
    }
  };
  useEffect(() => {
    if (checkPath) {
      getCustomerNo();
    }
  }, [purchaseDid?.customers?.cus_id]);

  return (
    <>
      <SuiBox>
        <Grid container pt={{ xs: 2, md: 3, xl: 4 }} rowSpacing={{ xs: 3, lg: 5 }}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {/* Detail Section */}
              <Grid item xs={12} mb={2}>
                <Box
                  mx="auto"
                  borderRadius={5}
                  bgcolor="#fff"
                  boxShadow={boxShadow("#EDEDED", 1, [0, 6], [14, 0])}
                  py={{ xs: 3, lg: 3 }}
                  px={{ xs: 4, lg: 4 }}
                >
                  <Box
                    mb={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "20px",
                    }}
                  >
                    <SuiTypography sx={{ fontSize: "18px", color: "#2B2B2B", fontWeight: "600" }}>
                      {!checkPath && (
                        <ArrowBackIcon
                          onClick={handleBackClick}
                          sx={{
                            color: "#55DED0",
                            fontSize: "20px",
                            verticalAlign: "middle",
                            cursor: "pointer",
                          }}
                        />
                      )}
                      {checkPath ? "Edit" : "View"} {purchaseDid?.did_number}
                      {purchaseDid?.did_number_type != "National" &&
                      purchaseDid?.did_number_type != undefined ? (
                        <IntVIew />
                      ) : (
                        ""
                      )}
                    </SuiTypography>
                  </Box>
                  {!checkPath && purchaseDid?.did_purchased_from == "DID Inventory" && (
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        xl={5}
                        display="flex"
                        alignItems="start"
                        gap="10px"
                        mb={1}
                      >
                        <SuiTypography
                          variant="subtitle2"
                          textColor="text2"
                          fontWeight="regular"
                          sx={{ flex: "0 0 168px" }}
                        >
                          Supplier Name:
                        </SuiTypography>
                        <SuiTypography variant="body2" textColor="dark">
                          {purchaseDid?.did_inventorys?.did_suppliers?.did_supplier_name}
                        </SuiTypography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        xl={5}
                        display="flex"
                        alignItems="start"
                        gap="10px"
                        mb={1}
                      >
                        <SuiTypography
                          variant="subtitle2"
                          textColor="text2"
                          fontWeight="regular"
                          sx={{ flex: "0 0 168px" }}
                        >
                          Is Golden Number:
                        </SuiTypography>
                        <SuiTypography variant="body2" textColor="dark">
                          {purchaseDid?.did_inventorys?.did_inventory_is_golden_number == "1"
                            ? "Yes"
                            : "No"}
                        </SuiTypography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        xl={5}
                        display="flex"
                        alignItems="start"
                        gap="10px"
                        mb={1}
                      >
                        <SuiTypography
                          variant="subtitle2"
                          textColor="text2"
                          fontWeight="regular"
                          sx={{ flex: "0 0 168px" }}
                        >
                          Range Holder Name:
                        </SuiTypography>
                        <SuiTypography variant="body2" textColor="dark">
                          {purchaseDid?.did_inventorys?.did_range_holders?.did_range_holder_name}
                        </SuiTypography>
                      </Grid>
                      <Grid
                        item
                        display="flex"
                        xs={12}
                        md={6}
                        xl={5}
                        alignItems="start"
                        gap="10px"
                        mb={1}
                      >
                        <SuiTypography
                          variant="subtitle2"
                          textColor="text2"
                          fontWeight="regular"
                          sx={{ flex: "0 0 168px" }}
                        >
                          Is Fuse2 Allocation:
                        </SuiTypography>
                        <SuiTypography variant="body2" textColor="dark">
                          {purchaseDid?.did_inventorys?.did_inventory_is_fuse2_number == "1"
                            ? "Yes"
                            : "No"}
                        </SuiTypography>
                      </Grid>
                    </Grid>
                  )}

                  {!checkPath && (
                    <>
                      <Grid item xs={12} mt={2} mb={2}>
                        <SuiTypography variant="body2" textColor="dark" fontWeight="semiBold">
                          Other Details
                        </SuiTypography>
                      </Grid>
                      <Grid container>
                        <Grid item sx={12} md={6} xl={5}>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Account Number:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.customers?.cus_account_number}{" "}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Number Type:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.did_number_type}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Main Service:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.main_service?.description}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Company Name:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.customers?.cus_company_name}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Account Type:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.customers?.cus_account_type == "0"
                                ? "Postpaid"
                                : "Prepaid"}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Provision to SRE:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.did_inventorys?.did_inventory_provision_to_sre == "1"
                                ? "Yes"
                                : "No"}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Is Diverted:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.main_service?.diverted == "0" ? "No" : "Yes"}
                            </SuiTypography>
                          </Box>
                        </Grid>
                        <Grid item sx={12} md={6} xl={4}>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              SIP Server Name:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.main_service?.username}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Rate Override:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {" "}
                              {purchaseDid?.did_inventorys?.did_inventory_rate_override == null
                                ? "-"
                                : `£${purchaseDid?.did_inventorys?.did_inventory_rate_override}`}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Port In Fees:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.did_inventorys?.did_inventory_porting_fee_amount == null
                                ? "-"
                                : `£${purchaseDid?.did_inventorys?.did_inventory_porting_fee_amount}`}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Is Ported:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.did_inventorys?.did_inventory_is_ported_in == "1"
                                ? "Yes"
                                : "No"}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Port Date:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.did_inventorys?.did_inventory_effective_date == null
                                ? "-"
                                : formattedDates(
                                    purchaseDid?.did_inventorys?.did_inventory_effective_date
                                  )}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Purchased From:
                            </SuiTypography>

                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.did_purchased_from}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Purchased On:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.createdAt == null
                                ? "-"
                                : formattedDates(purchaseDid?.createdAt)}
                            </SuiTypography>
                          </Box>
                          <Box display="flex" alignItems="start" gap="10px" mb={1}>
                            <SuiTypography
                              variant="subtitle2"
                              textColor="text2"
                              fontWeight="regular"
                              sx={{ flex: "0 0 168px" }}
                            >
                              Diverted To:
                            </SuiTypography>
                            <SuiTypography variant="body2" textColor="dark">
                              {purchaseDid?.main_service?.backup_number == ""
                                ? "-"
                                : purchaseDid?.main_service?.backup_number}
                            </SuiTypography>
                          </Box>
                          {purchaseDid?.did_purchased_from == "DIDWW" && (
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Features:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {featuresData?.length > 0 ? featuresData?.join(",") : "-"}
                              </SuiTypography>
                            </Box>
                          )}
                          {purchaseDid?.did_purchased_from == "DIDWW" && (
                            <Box display="flex" alignItems="start" gap="10px" mb={1}>
                              <SuiTypography
                                variant="subtitle2"
                                textColor="text2"
                                fontWeight="regular"
                                sx={{ flex: "0 0 168px" }}
                              >
                                Number Type:
                              </SuiTypography>
                              <SuiTypography variant="body2" textColor="dark">
                                {purchaseDid?.did_number_group_types?.join(",")}
                              </SuiTypography>
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}

                  {subInfo?.subscription_data?.cus_sub_status == "Active" && !checkPath && (
                    <>
                      <Grid item xs={12} mt={2} mb={2}>
                        <SuiTypography variant="body2" textColor="dark" fontWeight="semiBold">
                          Subscription Details
                        </SuiTypography>
                      </Grid>
                      <Box
                        mx="auto"
                        borderRadius={2}
                        p={{ xs: 3, lg: 2 }}
                        borderColor={rgba(colors.primary.main, 1)}
                        sx={{
                          borderWidth: "2px",
                          borderStyle: "solid",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <SuiButton
                              type="submit"
                              variant="contained"
                              buttonColor="primary"
                              size="md"
                            >
                              {subInfo?.subscription_data?.cus_sub_status}
                            </SuiButton>
                          </Grid>
                          <Grid item xs={12} md={6} xl={6}>
                            <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.focus} !important`,
                                  width: "145px",
                                }}
                              >
                                Subscription ID:
                              </SuiTypography>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.main} !important`,
                                }}
                              >
                                {subInfo?.subscription_data?.cus_sub_stripe_id}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.focus} !important`,
                                  width: "145px",
                                }}
                              >
                                Account Number:
                              </SuiTypography>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.main} !important`,
                                }}
                              >
                                {subInfo?.subscription_data?.customers?.cus_account_number}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.focus} !important`,
                                  width: "145px",
                                }}
                              >
                                Customer Company Name:
                              </SuiTypography>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.main} !important`,
                                }}
                              >
                                {subInfo?.subscription_data?.customers?.cus_company_name}
                              </SuiTypography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} md={6} xl={6}>
                            <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.focus} !important`,
                                  width: "160px",
                                }}
                              >
                                Purchased on:
                              </SuiTypography>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.main} !important`,
                                }}
                              >
                                {formattedDates(subInfo.subscription_data?.sub_purchase_on)}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.focus} !important`,
                                  width: "160px",
                                }}
                              >
                                Current Period:
                              </SuiTypography>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.main} !important`,
                                }}
                              >
                                {formattedDates(subInfo.subscription_data?.current_period_start)} to{" "}
                                {formattedDates(subInfo.subscription_data?.current_period_end)}{" "}
                              </SuiTypography>
                            </Box>
                            <Box display="flex" alignItems="flex-start" sx={{ gap: "10px", mb: 2 }}>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.focus} !important`,
                                  width: "160px",
                                }}
                              >
                                Next Payment Date:
                              </SuiTypography>
                              <SuiTypography
                                component="span"
                                variant="body2"
                                fontSize={{ xs: 14, md: 15, xl: 16 }}
                                sx={{
                                  color: `${colors.text.main} !important`,
                                }}
                              >
                                {formattedDates(subInfo.subscription_data?.next_payment_date)}{" "}
                              </SuiTypography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                  {subInfo?.subscription_data?.cus_sub_status == "Cancelled" && !checkPath && (
                    <>
                      {" "}
                      <Grid item xs={12} mt={2} mb={2}>
                        <SuiTypography variant="body2" textColor="dark" fontWeight="semiBold">
                          Subscription Details
                        </SuiTypography>
                      </Grid>
                      <Box
                        mx="auto"
                        borderRadius={2}
                        p={{ xs: 3, lg: 2 }}
                        borderColor={rgba(colors.error.main, 1)}
                        sx={{
                          borderWidth: "2px",
                          borderStyle: "solid",
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <SuiButton
                              type="submit"
                              variant="contained"
                              buttonColor="error"
                              size="md"
                            >
                              Cancelled
                            </SuiButton>
                          </Grid>
                          <Grid item xs={6} md={2} alignItems="flex-start">
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                              }}
                            >
                              Subscription ID:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                            >
                              {subInfo?.subscription_data?.cus_sub_stripe_id}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                              }}
                            >
                              Purchased on:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                            >
                              {formattedDates(subInfo?.subscription_data?.sub_purchase_on)}{" "}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                                width: "145px",
                              }}
                            >
                              Account Number:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.main} !important`,
                              }}
                            >
                              {subInfo?.subscription_data?.customers?.cus_account_number}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                              }}
                            >
                              Cancelled on:
                            </SuiTypography>
                          </Grid>

                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                            >
                              {subInfo?.subscription_data?.cus_sub_cancelled_on == null
                                ? "-"
                                : formattedDates(subInfo?.subscription_data?.cus_sub_cancelled_on)}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                                width: "145px",
                              }}
                            >
                              Customer Company Name:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.main} !important`,
                              }}
                            >
                              {subInfo?.subscription_data?.customers?.cus_company_name}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                              }}
                            >
                              Cancelled by:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                            >
                              {subInfo?.subscription_data?.admins?.admin_name}{" "}
                              {subInfo?.subscription_data?.admins?.admin_last_name
                                ? subInfo?.subscription_data?.admins?.admin_last_name
                                : ""}
                              {subInfo?.subscription_data?.cus_sub_cancelled_reference_type ===
                              "System"
                                ? "System"
                                : subInfo?.subscription_data?.cus_sub_cancelled_reference_type ===
                                  "Customer"
                                ? "Self"
                                : subInfo?.subscription_data?.cus_sub_cancelled_reference_type ===
                                  null
                                ? "-"
                                : ""}
                            </SuiTypography>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                  {subInfo?.subscription_data?.cus_sub_status == "Cancels soon" && !checkPath && (
                    <>
                      <Grid item xs={12} mt={2} mb={2}>
                        <SuiTypography variant="body2" textColor="dark" fontWeight="semiBold">
                          Subscription Details
                        </SuiTypography>
                      </Grid>

                      <Box
                        mx="auto"
                        borderRadius={2}
                        p={{ xs: 3, lg: 2 }}
                        borderColor={rgba(colors.text.focus, 1)}
                        sx={{
                          borderWidth: "2px",
                          borderStyle: "solid",
                        }}
                      >
                        <Grid container spacing={2} alignItems="flex-start">
                          <Grid item xs={12}>
                            <SuiButton
                              type="submit"
                              variant="contained"
                              size="md"
                              sx={{
                                background: `${colors.text.focus} !important`,
                                color: `${colors.white.main} !important`,
                              }}
                            >
                              {subInfo?.subscription_data?.cus_sub_status}
                            </SuiButton>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              fontWeight="semiBold"
                              ml={1}
                              sx={{
                                color: `${colors.info.main} !important`,
                              }}
                            >
                              Effective from{" "}
                              {formattedDates(subInfo?.subscription_data?.current_period_end)}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={2} alignItems="flex-start">
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                              }}
                            >
                              Subscription ID:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                            >
                              {subInfo?.subscription_data?.cus_sub_stripe_id}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                              }}
                            >
                              Purchased on:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                            >
                              {formattedDates(subInfo?.subscription_data?.sub_purchase_on)}{" "}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                                width: "145px",
                              }}
                            >
                              Account Number:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.main} !important`,
                              }}
                            >
                              {subInfo?.subscription_data?.customers?.cus_account_number}
                            </SuiTypography>
                          </Grid>

                          <Grid item xs={6} md={2}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                              }}
                            >
                              Cancelled on:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                            >
                              {subInfo?.subscription_data?.cus_sub_cancelled_on == null
                                ? "-"
                                : formattedDates(subInfo?.subscription_data?.cus_sub_cancelled_on)}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                                width: "145px",
                              }}
                            >
                              Customer Company Name:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={4}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.main} !important`,
                              }}
                            >
                              {subInfo?.subscription_data?.customers?.cus_company_name}
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={2}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                              sx={{
                                color: `${colors.text.focus} !important`,
                              }}
                            >
                              Cancelled by:
                            </SuiTypography>
                          </Grid>
                          <Grid item xs={6} md={3}>
                            <SuiTypography
                              component="span"
                              variant="body2"
                              fontSize={{ xs: 14, md: 15, xl: 16 }}
                            >
                              {subInfo?.subscription_data?.cus_sub_cancelled_by?.admin_name}
                              {""}{" "}
                              {subInfo?.subscription_data?.cus_sub_cancelled_by?.admin_last_name
                                ? subInfo?.subscription_data?.cus_sub_cancelled_by?.admin_last_name
                                : subInfo?.subscription_data?.cus_sub_cancelled_by === null &&
                                  subInfo?.subscription_data?.cus_sub_cancelled_reference_type ===
                                    "Customer"
                                ? "Self"
                                : "-"}
                            </SuiTypography>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  )}
                  {!checkPath && purchaseDid?.customers?.cus_account_type == "1" && (
                    <Grid item xs={12}>
                      <Box
                        borderRadius={2}
                        bgcolor="#fff"
                        boxShadow={boxShadow("#EDEDED", 1, [0, 6], [14, 0])}
                        mt={3}
                        borderColor={rgba(colors.text.focus, 0.3)}
                        sx={{
                          borderWidth: "1px",
                          borderStyle: "solid",
                        }}
                      >
                        <Grid
                          item
                          px={2}
                          py={2}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <SuiTypography
                            component="span"
                            variant="body2"
                            fontWeight="semiBold"
                            fontSize={{ xs: 14, md: 16, xl: 18 }}
                            sx={{
                              color: `${colors.text.main} !important`,
                            }}
                          >
                            Invoices
                          </SuiTypography>
                        </Grid>
                        <BaseTableUi tableHeadData={invoiceHeader}>
                          {isLoadingInv ? (
                            <TableRow>
                              <TableCell colSpan={7}>
                                <CommonSpinner size="100px" color={colors.primary.main} />
                              </TableCell>
                            </TableRow>
                          ) : invInfo?.rows?.length == undefined ? (
                            <TableRow>
                              <TableCell colSpan={7}>
                                <Nodata />
                              </TableCell>
                            </TableRow>
                          ) : (
                            invInfo?.rows?.map((val, i) => (
                              <TableRow
                                key={i}
                                sx={
                                  val.cus_invoice_payment_status === "Due"
                                    ? { background: `${rgba(colors.secondary.main, 0.6)}` }
                                    : {}
                                }
                              >
                                <TableCell>
                                  {val.cus_invoice_payment_status === "Due" ? (
                                    <Tooltip
                                      title={
                                        <>
                                          <SuiTypography
                                            textColor="light"
                                            fontWeight="regular"
                                            variant="body2"
                                            sx={{
                                              fontSize: "12px",
                                            }}
                                          >
                                            {subInfo?.subscription_data?.cus_sub_overdue?.text}
                                          </SuiTypography>
                                        </>
                                      }
                                      arrow
                                      placement="bottom"
                                      backgroundColor="#000000"
                                    >
                                      <IconButton sx={{ padding: "0" }}>
                                        <AlertIcon fontSize={12} color="error" />
                                      </IconButton>
                                    </Tooltip>
                                  ) : (
                                    ""
                                  )}
                                </TableCell>
                                <TableCell>{val.cus_invoice_payment_intent_id}</TableCell>
                                <TableCell>
                                  {formattedDates(val.cus_invoice_stripe_created_at)}
                                </TableCell>
                                <TableCell>{val.cus_invoice_payment_method}</TableCell>
                                <TableCell>£{val.cus_invoice_amount}</TableCell>
                                <TableCell>
                                  {val.cus_invoice_payment_status == "Paid" && (
                                    <SuiTypography
                                      textColor="info"
                                      fontSize="inherit"
                                      fontWeight="semiBold"
                                    >
                                      {val.cus_invoice_payment_status}
                                    </SuiTypography>
                                  )}
                                  {val.cus_invoice_payment_status == "Due" && (
                                    <SuiTypography
                                      textColor="error"
                                      fontSize="inherit"
                                      fontWeight="semiBold"
                                    >
                                      {val.cus_invoice_payment_status}
                                    </SuiTypography>
                                  )}
                                  {val.cus_invoice_payment_status == "Failed" && (
                                    <SuiTypography
                                      textColor="error"
                                      fontSize="inherit"
                                      fontWeight="semiBold"
                                    >
                                      {val.cus_invoice_payment_status}
                                    </SuiTypography>
                                  )}
                                </TableCell>
                                <TableCell>
                                  <SuiButton
                                    type="submit"
                                    variant="contained"
                                    disabled={loadingId == val.cus_invoice_stripe_id}
                                    buttonColor="info"
                                    onClick={() => DownloadInvoice(val.cus_invoice_stripe_id)}
                                    size="small"
                                  >
                                    {loadingId == val.cus_invoice_stripe_id ? (
                                      <CircularProgress size={24} />
                                    ) : (
                                      "Download"
                                    )}{" "}
                                  </SuiButton>
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </BaseTableUi>
                        {/* Pagination section :: BEGIN */}
                        {!isLoadingInv && perPageData > 0 && (
                          <Grid
                            container
                            py={3}
                            spacing={1.5}
                            flexDirection={{ xs: "column", md: "row" }}
                            justifyContent={{ xs: "center", md: "flex-end" }}
                            alignItems="center"
                          >
                            <Grid item>
                              <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                  <span>Show</span>
                                </Grid>
                                <Grid item>
                                  {/* Select dropdown for items per page */}
                                  <Select
                                    value={perPage}
                                    onChange={handlePerPageChange}
                                    variant="standard"
                                    // onClick={() => getCustomerMultipleRecords(perPage, currentPage)}
                                    sx={{ minWidth: 100 }}
                                  >
                                    {options.map((option) => (
                                      <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Grid>
                                <Grid item>
                                  <span>out of {perPageData} Results</span>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item>
                              <CommonPagination
                                mt={0}
                                totalData={perPageData}
                                pageSize={perPage}
                                currentPage={Number(currentPage)}
                                onPageChange={handlePageChange}
                              />
                            </Grid>
                          </Grid>
                        )}
                        {/* Pagination section :: END */}
                      </Box>
                    </Grid>
                  )}
                  {checkPath && (
                    <form onSubmit={formik.handleSubmit}>
                      <Grid container spacing={2} mt={2} alignItems="end">
                        {purchaseDid?.did_purchased_from == "DID Inventory" && (
                          <Grid item xs={12} md={6}>
                            <FormLabel htmlFor="did_supplier_status" required>
                              Supplier Name
                            </FormLabel>
                            <Autocomplete
                              sx={{
                                "& .MuiAutocomplete-input:focus": {
                                  border: "none !important",
                                },
                              }}
                              options={supplierData}
                              getOptionLabel={(option) => option.label}
                              // onInputChange={SupplierLists}
                              value={
                                supplierData?.find(
                                  (option) => option.id === formik.values.did_supplier_status
                                ) || null
                              }
                              onChange={(event, newValue) => {
                                formik.setFieldValue(
                                  "did_supplier_status",
                                  newValue ? newValue.id : ""
                                );
                                formik.setFieldValue(
                                  "did_supplier_status1",
                                  newValue ? newValue : ""
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select Supplier Name" />
                              )}
                            />
                            {formik.errors.did_supplier_status &&
                              formik.touched.did_supplier_status && (
                                <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                                  {formik.errors.did_supplier_status}
                                </div>
                              )}
                          </Grid>
                        )}
                        {purchaseDid?.did_purchased_from == "DID Inventory" && (
                          <Grid item xs={12} md={6}>
                            <FormLabel htmlFor="range_holder_name" required>
                              Range Holder Name
                            </FormLabel>
                            <Autocomplete
                              options={rangeData}
                              onInputChange={RangeList}
                              getOptionLabel={(option) => option.label}
                              sx={{
                                "& .MuiAutocomplete-input:focus": {
                                  border: "none !important",
                                },
                              }}
                              value={
                                rangeData?.find(
                                  (option) => option.id === formik.values.range_holder_name
                                ) || null
                              }
                              onChange={(event, newValue) => {
                                formik.setFieldValue(
                                  "range_holder_name",
                                  newValue ? newValue.id : ""
                                );
                                formik.setFieldValue(
                                  "range_holder_name1",
                                  newValue ? newValue : ""
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select Range Holder Name" />
                              )}
                            />
                            {formik.errors.range_holder_name &&
                              formik.touched.range_holder_name && (
                                <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                                  {formik.errors.range_holder_name}
                                </div>
                              )}
                          </Grid>
                        )}
                        {purchaseDid?.did_purchased_from == "DID Inventory" && (
                          <Grid item xs={12} mt={1}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.is_golden}
                                  onChange={handleCheckboxChange}
                                  name="is_golden"
                                  color="secondary"
                                />
                              }
                              label="Is Golden Number"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={formik.values.is_fuse2}
                                  onChange={handleCheckboxChangeFuse}
                                  name="is_fuse2"
                                  color="secondary"
                                />
                              }
                              label="Is Fuse2 Number"
                            />
                          </Grid>
                        )}

                        {purchaseDid?.did_purchased_from == "DID Inventory" && (
                          <Grid item xs={12} mt={2} mb={2}>
                            <SuiTypography variant="body2" textColor="dark" fontWeight="semiBold">
                              Other Details
                            </SuiTypography>
                          </Grid>
                        )}
                        {isLoading ? (
                          <Grid sx={{ py: 5 }}>
                            <CommonSpinner size="100px" color={colors.primary.main} />
                          </Grid>
                        ) : (
                          purchaseDid?.did_purchased_from == "DID Inventory" && (
                            <Grid container>
                              <Grid item sx={12} md={6} xl={5} ml={2}>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Account Number:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.customers?.cus_account_number}{" "}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Number Type:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.did_number_type}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Country Name:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.did_inventorys?.did_area_codes?.countries
                                      ?.country_name == null
                                      ? "-"
                                      : purchaseDid?.did_inventorys?.did_area_codes?.countries
                                          ?.country_name}
                                    {purchaseDid?.did_country != "-"
                                      ? purchaseDid?.did_country
                                      : ""}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Main Service:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.main_service?.description}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Company Name:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.customers?.cus_company_name}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Account Type:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.customers?.cus_account_type == "0"
                                      ? "Postpaid"
                                      : "Prepaid"}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Provision to SRE:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.did_inventorys?.did_inventory_provision_to_sre ==
                                    "1"
                                      ? "Yes"
                                      : "No"}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Is Diverted:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.main_service?.diverted == "0" ? "No" : "Yes"}
                                  </SuiTypography>
                                </Box>
                              </Grid>
                              <Grid item sx={12} md={6} xl={4} ml={2}>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    SIP Server Name:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.main_service?.username}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Rate Override:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.did_inventorys?.did_inventory_rate_override ==
                                    null
                                      ? "-"
                                      : `£${purchaseDid?.did_inventorys?.did_inventory_rate_override}`}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Port In Fees:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.did_inventorys
                                      ?.did_inventory_porting_fee_amount == null
                                      ? "-"
                                      : `£${purchaseDid?.did_inventorys?.did_inventory_porting_fee_amount}`}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Is Ported:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.did_inventorys?.did_inventory_is_ported_in == "1"
                                      ? "Yes"
                                      : "No"}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Port Date:
                                  </SuiTypography>

                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.did_inventorys?.did_inventory_effective_date ==
                                    null
                                      ? "-"
                                      : formattedDates(
                                          purchaseDid?.did_inventorys?.did_inventory_effective_date
                                        )}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Purchased From:
                                  </SuiTypography>

                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.did_purchased_from}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Purchased On:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {formattedDates(purchaseDid?.createdAt)}
                                  </SuiTypography>
                                </Box>
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Diverted To:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.main_service?.backup_number == ""
                                      ? "-"
                                      : purchaseDid?.main_service?.backup_number}
                                  </SuiTypography>
                                </Box>
                              </Grid>
                            </Grid>
                          )
                        )}
                        {(purchaseDid?.did_purchased_from === "DIDWW" ||
                          purchaseDid?.did_purchased_from === "Edge") && (
                          <Grid item xs={12} mt={2} mb={2}>
                            <SuiTypography variant="body2" textColor="dark" fontWeight="semiBold">
                              Other Details
                            </SuiTypography>
                          </Grid>
                        )}

                        {(purchaseDid?.did_purchased_from === "DIDWW" ||
                          purchaseDid?.did_purchased_from === "Edge") && (
                          <Grid container>
                            <Grid item sx={12} md={6} xl={5} ml={2}>
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Account Number:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {purchaseDid?.customers?.cus_account_number}{" "}
                                </SuiTypography>
                              </Box>
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Country Name:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {purchaseDid?.did_inventorys?.did_area_codes?.countries
                                    ?.country_name == null
                                    ? ""
                                    : purchaseDid?.did_inventorys?.did_area_codes?.countries
                                        ?.country_name}
                                  {purchaseDid?.did_country != "-" ? purchaseDid?.did_country : ""}
                                </SuiTypography>
                              </Box>

                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Main Service:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {purchaseDid?.main_service?.description}
                                </SuiTypography>
                              </Box>
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Company Name:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {purchaseDid?.customers?.cus_company_name}
                                </SuiTypography>
                              </Box>
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Account Type:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {purchaseDid?.customers?.cus_account_type == "0"
                                    ? "Postpaid"
                                    : "Prepaid"}
                                </SuiTypography>
                              </Box>
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Provision to SRE:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {purchaseDid?.did_inventorys?.did_inventory_provision_to_sre ==
                                  "1"
                                    ? "Yes"
                                    : "No"}
                                </SuiTypography>
                              </Box>

                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  SIP Server Name:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {purchaseDid?.main_service?.username}
                                </SuiTypography>
                              </Box>
                            </Grid>
                            <Grid item sx={12} md={6} xl={4} ml={2}>
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Is Diverted:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {purchaseDid?.main_service?.diverted == "0" ? "No" : "Yes"}
                                </SuiTypography>
                              </Box>
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Purchased On:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {formattedDates(purchaseDid?.createdAt)}
                                </SuiTypography>
                              </Box>
                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Purchased From:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {purchaseDid?.did_purchased_from}
                                </SuiTypography>
                              </Box>

                              <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                <SuiTypography
                                  variant="subtitle2"
                                  textColor="text2"
                                  fontWeight="regular"
                                  sx={{ flex: "0 0 168px" }}
                                >
                                  Diverted To:
                                </SuiTypography>
                                <SuiTypography variant="body2" textColor="dark">
                                  {purchaseDid?.main_service?.backup_number == ""
                                    ? "-"
                                    : purchaseDid?.main_service?.backup_number}
                                </SuiTypography>
                              </Box>
                              {purchaseDid?.did_purchased_from == "DIDWW" && (
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Features:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {featuresData?.length > 0 ? featuresData?.join(",") : "-"}
                                  </SuiTypography>
                                </Box>
                              )}
                              {purchaseDid?.did_purchased_from == "DIDWW" && (
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Number Type:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.did_number_group_types?.join(",")}
                                  </SuiTypography>
                                </Box>
                              )}
                              {purchaseDid?.did_purchased_from == "Edge" && (
                                <Box display="flex" alignItems="start" gap="10px" mb={1}>
                                  <SuiTypography
                                    variant="subtitle2"
                                    textColor="text2"
                                    fontWeight="regular"
                                    sx={{ flex: "0 0 168px" }}
                                  >
                                    Number Type:
                                  </SuiTypography>
                                  <SuiTypography variant="body2" textColor="dark">
                                    {purchaseDid?.did_number_type}
                                  </SuiTypography>
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        )}
                        <Grid item xs={12} mt={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik.values.is_diverted}
                                onChange={handleCheckboxChangeDivert}
                                name="is_diverted"
                                color="secondary"
                              />
                            }
                            label="Is Diverted"
                          />
                        </Grid>
                        {showDropdown && (
                          <Grid item xs={12} md={6} lg={3} xl={5} xxl={5}>
                            <FormLabel htmlFor="did_supplier_status">Diverted to</FormLabel>
                            <SuiInput
                              inputSettungs={{ autoComplete: "off" }}
                              type="tel"
                              placeholder="Number"
                              id="did_num"
                              name="did_num"
                              value={formik.values.did_num}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.did_num && formik.values.is_diverted === true && (
                              <>
                                {formik.values.did_num == "" && (
                                  <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                                    Phone Number cannot be blank.
                                  </div>
                                )}

                                {formik.values.did_num !== "" &&
                                  !/^\+?\d+$/.test(formik.values.did_num) && (
                                    <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                                      Enter a valid phone number.
                                    </div>
                                  )}
                              </>
                            )}
                          </Grid>
                        )}
                        <Grid item xs={12} mt={1}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={formik.values.is_allocate_sip}
                                onChange={handleCheckboxChangeSip}
                                name="is_allocate_sip"
                                color="secondary"
                              />
                            }
                            label="Allocate SIP Trunk"
                          />
                        </Grid>
                        {showDropdownSip && (
                          <Grid item xs={12} md={6} lg={3} xl={5} xxl={5}>
                            <FormLabel htmlFor="did_supplier_status">SIP Server Name</FormLabel>
                            <Autocomplete
                              freeSolo={false}
                              options={
                                Array.isArray(SipDetails)
                                  ? SipDetails.map((option) => option.sip_username)
                                  : []
                              }
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select SIP Server Name" />
                              )}
                              onChange={(event, newValue) => {
                                formik.setFieldValue("sip_name", newValue);
                              }}
                              sx={{
                                "& .MuiAutocomplete-input:focus": {
                                  border: "none !important",
                                },
                              }}
                              value={formik.values.sip_name}
                            />
                            {(formik.touched.sip_name &&
                              formik.values.is_allocate_sip == true &&
                              formik.values.sip_name == "") ||
                            formik.values.sip_name == null ? (
                              <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                                SIP Server Name cannot be blank.
                              </div>
                            ) : (
                              ""
                            )}
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container pt={4} columnSpacing={2} rowSpacing={1}>
                          <Grid item>
                            <SuiButton
                              size="small"
                              component={Link}
                              to="/purchased-dids"
                              variant="outlined"
                              buttonColor="dark"
                              sx={{ px: "1rem", py: "0.4rem" }}
                            >
                              Cancel
                            </SuiButton>
                          </Grid>
                          <Grid item>
                            <SuiButton
                              type="submit"
                              size="small"
                              buttonColor="primary"
                              sx={{ px: "1rem" }}
                              onClick={formik.handleSubmit}
                              // disabled={!formik.dirty}
                            >
                              Update
                            </SuiButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </SuiBox>
    </>
  );
};

export default ViewPurchase;
