export const didTableHeader = [
  {
    id: "1",
    align: "left",
    width: "auto",
    label: "Number",
  },
  {
    id: "2",
    align: "left",
    width: "500",
    label: "Range info",
  },
  {
    id: "3",
    align: "left",
    width: "auto",
    label: "Account Number",
  },
  {
    id: "4",
    align: "left",
    width: "auto",
    label: "Account type",
  },
  {
    id: "5",
    align: "left",
    width: "500",
    label: "Supplier",
  },
  {
    id: "6",
    align: "left",
    width: "auto",
    label: "IS Golden Number",
  },
  {
    id: "7",
    align: "left",
    width: "auto",
    label: "Fuse2 Allocation",
  },
  {
    id: "8",
    align: "left",
    width: "auto",
    label: "Is ported",
  },
  {
    id: "9",
    align: "left",
    width: "auto",
    label: "Number Status",
  },
  {
    id: "10",
    align: "left",
    width: "auto",
    label: "Status",
  },
  {
    id: "11",
    align: "left",
    width: "auto",
    label: "Created on",
  },
  {
    id: "12",
    align: "center",
    width: "180",
    label: "ACTION",
  },
];
export const PurchasedDidHeader = [
  {
    id: "1",
    align: "left",
    width: "auto",
    label: "Number",
  },
  {
    id: "2",
    align: "left",
    width: "500",
    label: "Type",
  },
  {
    id: "3",
    align: "left",
    width: "auto",
    label: "Account Number",
  },
  {
    id: "13",
    align: "left",
    width: "180",
    label: "purchased from",
  },
  {
    id: "4",
    align: "left",
    width: "auto",
    label: "Country Name",
  },
  {
    id: "5",
    align: "left",
    width: "500",
    label: "Rate",
  },
  {
    id: "6",
    align: "left",
    width: "auto",
    label: "IS Golden Number",
  },
  {
    id: "7",
    align: "left",
    width: "auto",
    label: "Fuse2 Allocation",
  },
  {
    id: "8",
    align: "left",
    width: "auto",
    label: "Is ported",
  },
  {
    id: "9",
    align: "left",
    width: "auto",
    label: "Port Date",
  },
  {
    id: "10",
    align: "left",
    width: "auto",
    label: "Supplier",
  },
  {
    id: "11",
    align: "left",
    width: "auto",
    label: "Range holder",
  },
  {
    id: "12",
    align: "right",
    width: "180",
    label: "Main Service",
  },
  {
    id: "12",
    align: "left",
    width: "180",
    label: "Purchased on",
  },

  {
    id: "12",
    align: "right",
    width: "180",
    label: "Action",
  },
];
export const pagesTableHeader = [
  {
    id: "1",
    align: "left",
    width: "100%",
    label: "Page Name",
  },
  // {
  //   id: "12",
  //   align: "left",
  //   width: "auto",
  //   label: "Action",
  // },
];
export const requestApprovalTableHeader = [
  {
    id: "1",
    align: "left",
    width: "auto",
    label: "Account Number",
  },
  {
    id: "2",
    align: "left",
    width: "auto",
    label: "Company Name",
  },
  {
    id: "3",
    align: "left",
    width: "auto",
    label: "Product Type",
  },
  {
    id: "4",
    align: "left",
    width: "auto",
    label: "Requested On",
  },
  {
    id: "5",
    align: "left",
    width: "auto",
    label: "Status",
  },
  {
    id: "6",
    align: "left",
    width: "auto",
    label: "Updated On",
  },
  {
    id: "7",
    align: "left",
    width: "auto",
    label: "Updated By",
  },
  {
    id: "8",
    align: "right",
    width: "auto",
    label: "Action",
  },
];
export const doughnutCharDataFn = (label, labels, data, backgroundColors) => ({
  labels: [...labels],
  datasets: {
    label,
    data: [...data],
    backgroundColors,
    borderWidth: 0,
  },
});
export const doughnutCharDataLc = (label, labels, data, backgroundColors) => ({
  labels: [...labels],
  datasets: {
    label,
    data: [...data],
    backgroundColors,
    borderWidth: 0,
  },
});
