import pxToRem from "assets/theme/functions/pxToRem";

const InactiveIcon = ({ size = 19 }) => {
  return (
    <svg height={pxToRem(size)} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.15"
        d="M13.9055 27.3193C21.2619 27.3193 27.2253 21.3558 27.2253 13.9995C27.2253 6.64318 21.2619 0.679688 13.9055 0.679688C6.54918 0.679688 0.585693 6.64318 0.585693 13.9995C0.585693 21.3558 6.54918 27.3193 13.9055 27.3193Z"
        fill="#C02B0A"
      />
      <path
        d="M13.9053 22.4273C18.5595 22.4273 22.3324 18.6544 22.3324 14.0003C22.3324 9.34616 18.5595 5.57324 13.9053 5.57324C9.25119 5.57324 5.47827 9.34616 5.47827 14.0003C5.47827 18.6544 9.25119 22.4273 13.9053 22.4273Z"
        fill="#C02B0A"
      />
    </svg>
  );
};

export default InactiveIcon;
