import { useEffect, useState } from "react";
import { Box, ListItemIcon, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import NotificationIcon from "../../Container/Icons/NotificationIcon";
import NotificationImage from "../../assets/images/notificationImage.png";
import noNotifictionIcon from "../../assets/images/noNotification.gif";
import { useGetUnreadCountMutation } from "app/features/api/AuthApi";
import { useNotificationCheckMutation } from "app/features/api/AuthApi";
import { useNotificationUnreadMutation } from "app/features/api/AuthApi";
import { formatDateNotification } from "helper";
import SuiTypography from "components/SuiTypography";

const NotificationContainer = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    NotificationCounts();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [NotificationUnread] = useNotificationUnreadMutation();
  const [getUnreadCount] = useGetUnreadCountMutation();
  const [NotificationVal, setNotificationVal] = useState("");
  const [notificationCounts, setNotificationCounts] = useState("");
  const NotificationCounts = async () => {
    try {
      const response = await NotificationUnread().unwrap();
      setNotificationVal(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const NotificationUnreadCount = async () => {
    try {
      const response = await getUnreadCount().unwrap();
      setNotificationCounts(response.data.count);
    } catch (error) {
      console.log(error);
    }
  };
  const [NotificationCheck] = useNotificationCheckMutation();
  const CheckNotification = async (item) => {
    const body = { notification_id: [item] };
    try {
      const response = await NotificationCheck(body).unwrap();
      console.log(response);
      NotificationUnreadCount();
      NotificationCounts();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    NotificationUnreadCount();
  }, []);
  useEffect(() => {
    const fetchNotificationCount = () => {
      NotificationUnreadCount();
    };
    fetchNotificationCount();
    const intervalId = setInterval(fetchNotificationCount, 10000);
    return () => clearInterval(intervalId);
  }, []);
  const style =
    notificationCounts > 0
      ? {
          width: "8px",
          height: "8px",
          background: "#C02B0A",
          borderRadius: "50%",
          position: "absolute",
          top: "9px",
          right: "12px",
        }
      : {};
  return (
    <>
      <Box
        display="inline-flex"
        alignItems="center"
        onClick={handleClick}
        className="notificationIconset"
        aria-controls={open ? "notification-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          cursor: "pointer",
          position: "relative",
          p: 1.5,
          borderRadius: "50px",
          "&[aria-expanded='true']": {
            background: "#55DED0",
          },
        }}
      >
        <NotificationIcon />
        <Typography sx={style} />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="notification-menu"
        open={open}
        onClose={handleClose}
        classes={{
          paper: classes.notificationMenu,
        }}
        MenuListProps={{
          component: "div",
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box>
          <Box
            display="flex"
            gap={1}
            alignItems="center"
            justifyContent="space-between"
            sx={{ padding: "10px" }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "600" }}>
              {notificationCounts} New Notifications
            </Typography>
            <Typography
              variant="a"
              component={Link}
              onClick={() => setAnchorEl(null)}
              to="/notification"
              sx={{ color: "#364FB3" }}
            >
              View All
            </Typography>
          </Box>
        </Box>
        <Box sx={{ maxHeight: "427px", overflow: "auto", width: "450px", minHeight: "200px" }}>
          {NotificationVal?.notificationData?.map((item, i) => (
            <MenuItem
              key={i}
              display="flex"
              onClick={() => CheckNotification(item.notification_id)}
              justifyContent="space-between"
              sx={{
                padding: "10px 0",
                borderBottom: "1px solid #E9E9E9",
                gap: "5px",
                alignItems: "center",
              }}
            >
              <ListItemIcon>
                <img src={NotificationImage} alt="Icon" width="30" />
              </ListItemIcon>
              <Stack
                display="flex"
                justifyContent="space-between"
                sx={{ alignItems: "flex-start", flexDirection: "row", width: "100%" }}
              >
                <Typography
                  className="unread-list"
                  sx={{
                    width: "230px",
                    color: "#2B2B2B",
                    whiteSpace: "normal",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "20px",
                  }}
                >
                  {item.notification_text}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#848484",
                    fontWeight: "400",
                    textAlign: "end",
                    marginLeft: "auto",
                  }}
                >
                  {formatDateNotification(item.createdAt, "menu")}
                </Typography>
              </Stack>
            </MenuItem>
          ))}
          {notificationCounts == 0 ? (
            <Typography
              sx={{
                fontSize: "14px",
                m: "10px auto",
                textAlign: "center",
                height: "300px",
                width: "100%",
                lineHeight: "12",
                overflow: "hidden",
              }}
            >
              <img src={noNotifictionIcon} width="150px" />
              <SuiTypography
                varient="h6"
                fontWeight="semiBold"
                textColor="dark"
                sx={{ fontSize: "16px" }}
              >
                No Notifications yet
              </SuiTypography>
              <SuiTypography
                varient="p"
                fontWeight="light"
                textColor="text2"
                sx={{ fontSize: "14px" }}
              >
                Stay tuned! Notifications about your activity <br /> will show up here.
              </SuiTypography>
            </Typography>
          ) : (
            ""
          )}
        </Box>
      </Menu>
    </>
  );
};

export default NotificationContainer;
