export default {
  styleOverrides: {
    root: {
      backgroundColor: "rgba(0, 0, 0, 0.35)",
    },
    invisible: {
      backgroundColor: "transparent",
      backdropFilter: "none",
    },
  },
};
