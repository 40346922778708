import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiTypography from "components/SuiTypography";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import TableToolbar from "components/SuiTable/TableToolbar";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { TableSortLabel } from "@mui/material";
import { useNotificationCheckMutation } from "app/features/api/AuthApi";
import { useAllNotificationMutation } from "app/features/api/AuthApi";
import { useNavigate } from "react-router-dom";
import Nodata from "layouts/customer/components/Nodata";
// import { formatDateStringLocal } from "helper";
import CommonSpinner from "components/CommonSpinner";
import colors from "assets/theme/base/colors";
import { makeStyles } from "@mui/styles";
// import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import vector1 from "../../assets/images/Vector.png";
import vector2 from "../../assets/images/Vector2.png";
import { formatDateStringLocal } from "helper";

const Notification = () => {
  const [AllNotification, { isLoading: isLoading }] = useAllNotificationMutation();
  const [perPage, setPerPage] = useState(10);
  const [checkedItems, setCheckedItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [NotificationVal, setNotificationVal] = useState("");
  const [NotificationVal1, setNotificationVal1] = useState("");
  const [notificationIsRead, setNotificationIsRead] = useState(null);
  const useStyles = makeStyles({
    arrowIcon: {
      fontSize: 18,
      verticalAlign: "middle",
      marginLeft: 4,
    },
    activeIcon: {
      color: "black",
      opacity: 1,
    },
    disabledIcon: {
      color: "#ccc",
      opacity: 0.5,
    },
  });
  const classes = useStyles();

  const navigateSearch = useNavigate();
  const NotificationCounts = async (page, notificationIsRead) => {
    navigateSearch("/notification", { page: page });
    let urlParams = {
      results_per_page: perPage,
      page: currentPage,
      ...(notificationIsRead !== null && {
        notification_is_read: notificationIsRead,
      }),
    };
    try {
      const response = await AllNotification(urlParams).unwrap();
      setNotificationVal(response.data);
      setNotificationVal1(response.data.rows);
      setCheckedItems([]);
      navigateSearch("/notification", { page: currentPage });
    } catch (error) {
      suiSnackbar(error.data.message, "error");
    }
  };

  const handleCheckSingle = (item) => {
    const newCheckedItems = [...checkedItems];
    const currentIndex = newCheckedItems.indexOf(item);

    if (currentIndex === -1) {
      newCheckedItems.push(item);
    } else {
      newCheckedItems.splice(currentIndex, 1);
    }

    setCheckedItems(newCheckedItems);
  };
  const allItemsChecked =
    NotificationVal?.rows?.length > 0 && checkedItems?.length === NotificationVal?.rows?.length;
  const handleCheckAll = () => {
    if (allItemsChecked) {
      setCheckedItems([]);
    } else {
      const allNotificationIds = NotificationVal?.rows.map((item) => item.notification_id);
      setCheckedItems(allNotificationIds);
    }
  };
  const suiSnackbar = useSuiSnackbar();
  const [NotificationCheck] = useNotificationCheckMutation();
  const CheckNotification = async () => {
    const body = { notification_id: checkedItems };
    try {
      const response = await NotificationCheck(body).unwrap();
      NotificationCounts();
      setCheckedItems([]);
      suiSnackbar(response.message, "success");
    } catch (error) {
      suiSnackbar(error.data.message, "error");
    }
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/notification", { page: page });
    // NotificationCounts(page, notificationIsRead);
  };
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/notification", { page: 1 });
  };
  useEffect(() => {
    NotificationCounts(currentPage, notificationIsRead);
  }, [currentPage, perPage, notificationIsRead]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("status");

  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  const allNotificationsRead =
    NotificationVal1 && NotificationVal1.every((notification) => notification.notification_is_read);
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const newNotificationIsRead = notificationIsRead === "true" ? "false" : "true";
    setNotificationIsRead(newNotificationIsRead);
    NotificationCounts(currentPage, newNotificationIsRead);
  };
  return (
    <SuiBox my={3}>
      <Card sx={{ width: "100%" }}>
        <TableToolbar title="List of Notifications">
          <SuiButton
            size="small"
            buttonColor="info"
            disabled={allNotificationsRead || checkedItems?.length == 0}
            onClick={CheckNotification}
            sx={{ fontWeight: "500", px: "12px", ml: 2 }}
          >
            Read All
          </SuiButton>
        </TableToolbar>
        <TableContainer sx={{ "tr:last-of-type td": { border: "none" } }}>
          <Table sx={{ minWidth: "540px" }} size="medium">
            <TableRow>
              <TableCell
                sx={{
                  color: `${colors.text.focus} !important`,
                  fontSize: "14px",
                  fontWeight: "400",
                  textTransform: "uppercase",
                }}
              >
                {!allNotificationsRead && (
                  <Checkbox
                    size="small"
                    name="checkbox-notification"
                    color="secondary"
                    checked={allItemsChecked}
                    onChange={handleCheckAll}
                  />
                )}
              </TableCell>
              <TableCell
                maxWidth="200px"
                sx={{
                  color: `${colors.text.focus} !important`,
                  fontSize: "14px",
                  fontWeight: "400",
                  textTransform: "uppercase",
                }}
              >
                Message
              </TableCell>
              <TableCell
                maxWidth="200px"
                sx={{
                  color: `${colors.text.focus} !important`,
                  fontSize: "14px",
                  fontWeight: "400",
                  textTransform: "uppercase",
                }}
              >
                Date & Time
              </TableCell>

              <TableCell sortDirection={orderBy === "status" ? order : false}>
                <TableSortLabel
                  active={orderBy === "status"}
                  direction={orderBy === "status" ? order : "asc"}
                  onClick={() => handleRequestSort("status")}
                  IconComponent={null}
                  icon
                  sx={{
                    color: `${colors.text.focus} !important`,
                    fontSize: "14px",
                    fontWeight: "400",
                    textTransform: "uppercase",
                  }}
                >
                  Status
                  <Box
                    className={`${classes.arrowIcon} ${
                      orderBy === "status" && order === "asc"
                        ? classes.activeIcon
                        : classes.disabledIcon
                    }`}
                  >
                    <img src={vector1} width="7" />
                  </Box>
                  <Box
                    className={`${classes.arrowIcon} ${
                      orderBy === "status" && order === "desc"
                        ? classes.activeIcon
                        : classes.disabledIcon
                    }`}
                  >
                    <img src={vector2} width="7" />
                  </Box>
                </TableSortLabel>
              </TableCell>
            </TableRow>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <CommonSpinner size="100px" color={colors.primary.main} />
                </TableCell>
              </TableRow>
            ) : NotificationVal1?.length == 0 ? (
              <TableRow>
                <TableCell colSpan={13}>
                  <Nodata />
                </TableCell>
              </TableRow>
            ) : (
              NotificationVal1?.map((item, i) => (
                <TableRow key={i}>
                  <TableCell
                    sx={{
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    {!item.notification_is_read ? (
                      <Checkbox
                        size="small"
                        name="checkbox-notification"
                        color="secondary"
                        checked={checkedItems.includes(item.notification_id)}
                        onChange={() => handleCheckSingle(item.notification_id)}
                      />
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      color: `#303841 !important`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {item.notification_text}
                  </TableCell>

                  <TableCell
                    sx={{
                      color: `#303841 !important`,
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {formatDateStringLocal(item.createdAt, "tab")}
                  </TableCell>
                  <TableCell>
                    <SuiTypography
                      fontSize="14px"
                      fontWeight="semiBold"
                      textColor={item.notification_is_read ? "success" : "error"}
                    >
                      {item.notification_is_read ? "Read" : "Unread"}
                    </SuiTypography>
                  </TableCell>
                </TableRow>
              ))
            )}
          </Table>
        </TableContainer>
        <Grid
          container
          py={3}
          spacing={1.5}
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent={{ xs: "center", md: "flex-end" }}
          alignItems="center"
        >
          {!isLoading && NotificationVal?.count > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {NotificationVal?.count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={NotificationVal?.count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Card>
    </SuiBox>
  );
};

export default Notification;
