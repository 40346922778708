import colors from "../../base/colors";
import borders from "../../base/borders";
import boxShadows from "../../base/boxShadows";
import pxToRem from "../../functions/pxToRem";
import linearGradient from "../../functions/linearGradient";

const { text, light, gradients } = colors;
const { borderWidth } = borders;
const { regular } = boxShadows;

export default {
  defaultProps: {
    disableRipple: true,
  },

  styleOverrides: {
    root: {
      width: pxToRem(40),
      height: pxToRem(20),
      margin: `${pxToRem(4)} 0`,
      padding: 0,
      borderRadius: pxToRem(160),
      transition: "transform 250ms ease-in",
    },

    switchBase: {
      padding: 0,
      top: "50%",
      transform: `translate(${pxToRem(2)}, -50%)`,
      transition: `transform 250ms ease-in-out`,

      "&.Mui-checked": {
        transform: `translate(${pxToRem(22)}, -50%)`,

        "& + .MuiSwitch-track": {
          backgroundColor: `#364FB3 !important`,
          borderColor: `#364FB3 !important`,
          opacity: 1,
        },
        "& .MuiSwitch-thumb": {
          backgroundColor: light.main,
        },
      },

      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: "0.3 !important",
      },

      "&.Mui-focusVisible .MuiSwitch-thumb": {
        backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
      },
    },
    colorSuccess: {
      "&.Mui-checked": {
        "& + .MuiSwitch-track": {
          backgroundColor: `#9EE85D !important`,
          borderColor: `#9EE85D !important`,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: pxToRem(16),
      height: pxToRem(16),
      backgroundColor: text.focus,
      boxShadow: regular,
      top: "50%",
    },

    track: {
      backgroundColor: "#E9E9E9",
      border: `${borderWidth[1]} solid ${light.main}`,
      borderRadius: pxToRem(160),
      opacity: 1,
      transition: `background-color 250ms ease, border-color 200ms ease`,
    },

    checked: {},
  },
};
