import PropTypes from "prop-types";
import SuiDropDownList from "components/SuiDropDownList";
import { ApprovalStatuses } from "./dropdownArrays";

const ApprovalStatus = ({ name, value, setValue }) => {
  return (
    <SuiDropDownList
      data={ApprovalStatuses}
      select={{
        key: "id",
        valueKey: "id",
        labelName: "label",
        label: "Request Status",
        name: name,
        val: value,
        fn: setValue,
      }}
    />
  );
};

ApprovalStatus.defaultProps = {
  name: "",
};

ApprovalStatus.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
};

export default ApprovalStatus;
