import PropTypes from "prop-types";
import SuiDropDownList from "components/SuiDropDownList";
import { accountType } from "./dropdownArrays";

const AccountTypeDropdown = ({ name, value, setValue, handlePostPaidSelection }) => {
  const handleChange = (selectedValue) => {
    setValue(selectedValue);
    handlePostPaidSelection();
  };
  return (
    <SuiDropDownList
      data={accountType}
      select={{
        key: "id",
        valueKey: "id",
        labelName: "label",
        label: "Select Account Type",
        name: name,
        val: value,
        fn: handleChange,
      }}
    />
  );
};

AccountTypeDropdown.defaultProps = {
  name: "account_type",
};

AccountTypeDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  handlePostPaidSelection: PropTypes.func,
};

export default AccountTypeDropdown;
