export const CustomerTableHeader = [
  {
    id: "1",
    align: "left",
    width: "100px",
    label: "ACCOUNT NUMBER",
  },
  {
    id: "2",
    align: "left",
    width: "120px",
    label: "FIRST NAME",
  },
  {
    id: "13",
    align: "left",
    width: "120px",
    label: "LAST NAME",
  },
  {
    id: "12",
    align: "left",
    width: "auto",
    label: "ACCOUNT TYPE",
  },
  {
    id: "14",
    align: "left",
    width: "auto",
    label: "Company name",
  },
  {
    id: "3",
    align: "left",
    width: "auto",
    label: "MOBILE",
  },
  {
    id: "4",
    align: "left",
    width: "auto",
    label: "EMAIL",
  },
  {
    id: "5",
    align: "left",
    width: "auto",
    label: "ACCOUNT STATUS",
  },
  {
    id: "6",
    align: "left",
    width: "auto",
    label: "NETWORK BLOCKED",
  },

  {
    id: "8",
    align: "left",
    width: "auto",
    label: "DIDS",
  },
  {
    id: "9",
    align: "left",
    width: "auto",
    label: "SIP TRUNKS",
  },
  {
    id: "10",
    align: "left",
    width: "auto",
    label: "ACCOUNT BALANCE",
  },
  // {
  //   id: "11",
  //   align: "right",
  //   width: "auto",
  //   label: "ACTION",
  // },
];
export const cronStatusHeader = [
  {
    id: "1",
    align: "center",
    width: "auto",
    label: "Cron run date",
  },
  {
    id: "2",
    align: "center",
    width: "auto",
    label: "cron run time",
  },
  {
    id: "3",
    align: "center",
    width: "auto",
    label: " cron execution time",
  },
  {
    id: "4",
    align: "center",
    width: "auto",
    label: "Cron status",
  },
  {
    id: "5",
    align: "center",
    width: "auto",
    label: "description",
  },
];

export const CustomerModuleRoutes = (id, accnt_num) => {
  return [
    {
      to: `/customer/edit-customer/account-details/${id}/${accnt_num}`,
      name: "Account Details",
    },
    {
      to: `/customer/edit-customer/spend-limit-details/${id}/${accnt_num}`,
      name: "Spend Limit Details",
    },
    {
      to: `/customer/edit-customer/discount-details/${id}/${accnt_num}`,
      name: "Discount Details",
    },
    {
      to: `/customer/edit-customer/did-details/${id}/${accnt_num}`,
      name: "DID Details",
    },
    {
      to: `/customer/edit-customer/sip-trunk-details/${id}/${accnt_num}`,
      name: "SIP Trunk Details",
    },
    {
      to: `/customer/edit-customer/number-port-in-details/${id}/${accnt_num}`,
      name: "Number Port-in Details",
    },
    {
      to: `/customer/edit-customer/3cx-details/${id}/${accnt_num}`,
      name: "3CX Details",
    },
    {
      to: `/customer/edit-customer/transaction-details/${id}/${accnt_num}`,
      name: "Transaction Details",
    },
    {
      to: `/customer/edit-customer/order-details/${id}/${accnt_num}`,
      name: "Order Details",
    },
    {
      to: `/customer/edit-customer/cdr-details/${id}/${accnt_num}`,
      name: "CDR Details",
    },
    {
      to: `/customer/edit-customer/invoice-details/${id}/${accnt_num}`,
      name: "Invoice Details",
    },
  ];
};

export const didDetailsTableHeader = [
  {
    id: "1",
    align: "left",
    width: "250",
    label: "DID Number",
  },
  {
    id: "2",
    align: "left",
    width: "200",
    label: "Number Type",
  },
  {
    id: "3",
    align: "left",
    width: "100",
    label: "Diverted",
  },
  {
    id: "4",
    align: "left",
    width: "150",
    label: "Purchased On",
  },
  {
    id: "5",
    align: "right",
    width: "100",
    label: "Action",
  },
];
export const didDetailsTableHeader1 = [
  {
    id: "1",
    align: "left",
    width: "250",
    label: "DID Number",
  },
  {
    id: "2",
    align: "left",
    width: "200",
    label: "Number Type",
  },
  {
    id: "3",
    align: "left",
    width: "100",
    label: "Diverted",
  },
  {
    id: "4",
    align: "left",
    width: "150",
    label: "Purchased On",
  },
];

export const sipDetailsTableHeader = [
  {
    id: "1",
    align: "left",
    width: "250",
    label: "Trunk Name",
  },
  {
    id: "2",
    align: "left",
    width: "250",
    label: "No. of channel",
  },
  {
    id: "5",
    align: "left",
    width: "250",
    label: "Password",
  },
  {
    id: "6",
    align: "left",
    width: "250",
    label: "Call Bundle Name",
  },
  {
    id: "3",
    align: "left",
    width: "150",
    label: "Purchased On",
  },
  {
    id: "4",
    align: "right",
    width: "100",
    label: "Action",
  },
];
export const sipDetailsTableHeader1 = [
  {
    id: "1",
    align: "left",
    width: "250",
    label: "Trunk Name",
  },
  {
    id: "2",
    align: "left",
    width: "250",
    label: "No. of channel",
  },
  {
    id: "5",
    align: "left",
    width: "250",
    label: "Password",
  },
  {
    id: "6",
    align: "left",
    width: "250",
    label: "Call Bundle Name",
  },
  {
    id: "3",
    align: "left",
    width: "150",
    label: "Purchased On",
  },
];

export const numberPortinTableHeader = [
  {
    id: "1",
    align: "left",
    width: "250",
    label: "Port Number",
  },
  {
    id: "2",
    align: "left",
    width: "250",
    label: "losing communication provider",
  },
  {
    id: "3",
    align: "left",
    width: "150",
    label: "Applied On",
  },
  {
    id: "4",
    align: "right",
    width: "100",
    label: "Action",
  },
];
export const orderTableHeader = [
  {
    id: "1",
    align: "left",
    width: "100",
    label: "",
  },
  {
    id: "2",
    align: "left",
    width: "100",
    label: "Order ID",
  },
  {
    id: "3",
    align: "left",
    width: "150",
    label: "Order Type",
  },
  {
    id: "4",
    align: "left",
    width: "150",
    label: "Order Date",
  },
  {
    id: "5",
    align: "left",
    width: "100",
    label: "Amount",
  },
  {
    id: "6",
    align: "left",
    width: "100",
    label: "Status",
  },
];

export const transactionTableHeader = [
  {
    id: "1",
    align: "left",
    width: "100",
    label: "",
  },
  {
    id: "2",
    align: "left",
    width: "100",
    label: "Transaction ID",
  },
  {
    id: "9",
    align: "left",
    width: "100",
    label: "Order ID",
  },
  {
    id: "3",
    align: "left",
    width: "150",
    label: "Order Date",
  },
  {
    id: "4",
    align: "left",
    width: "150",
    label: "Payment Date",
  },
  {
    id: "5",
    align: "left",
    width: "100",
    label: "Product Detail",
  },
  {
    id: "6",
    align: "left",
    width: "100",
    label: "Amount",
  },
  {
    id: "7",
    align: "left",
    width: "100",
    label: "Payment mode",
  },
  {
    id: "8",
    align: "left",
    width: "100",
    label: "Payment Status",
  },
];

export const cdrTableHeader = [
  {
    id: "1",
    align: "left",
    width: "150",
    title: "From Number",
  },
  {
    id: "2",
    align: "left",
    width: "150",
    title: "To Number",
  },
  {
    id: "3",
    align: "left",
    width: "150",
    title: "Start Date",
  },
  {
    id: "4",
    align: "left",
    width: "100",
    title: "Start Time",
  },
  {
    id: "5",
    align: "left",
    width: "150",
    title: "Duration (S)",
  },
  {
    id: "6",
    align: "left",
    width: "150",
    title: "Amount",
  },
  {
    id: "7",
    align: "left",
    width: "100",
    title: "Call type",
  },
];

export const paymentTableHeader = [
  {
    id: "1",
    align: "left",
    width: "150",
    label: "Invoice ID",
  },
  {
    id: "2",
    align: "left",
    width: "150",
    label: "Date",
  },
  {
    id: "3",
    align: "left",
    width: "150",
    label: "Period Start",
  },
  {
    id: "4",
    align: "left",
    width: "100",
    label: "Due Date",
  },
  {
    id: "5",
    align: "left",
    width: "100",
    label: "AmountExclVAT",
  },
  {
    id: "6",
    align: "left",
    width: "100",
    label: "AmountInclVAT",
  },
  {
    id: "7",
    align: "left",
    width: "100",
    label: "ACTION",
  },
];
export const adminTable = [
  {
    id: "1",
    align: "left",
    width: "150",
    label: "First Name",
  },

  {
    id: "3",
    align: "left",
    width: "150",
    label: "Last Name",
  },
  {
    id: "2",
    align: "left",
    width: "150",
    label: "Role",
  },
  {
    id: "4",
    align: "left",
    width: "150",
    label: "Email id",
  },
  {
    id: "5",
    align: "left",
    width: "150",
    label: "status",
  },

  {
    id: "7",
    align: "left",
    width: "150",
    label: "created on",
  },
];
