import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import typography from "assets/theme/base/typography";
import styles from "Container/Footer/styles";

const Footer = ({ company, links }) => {
  const { size } = typography;
  const classes = styles();
  const currentYear = new Date().getFullYear();

  const renderLinks = () =>
    links.map((link) => (
      <SuiBox key={link.name} component="li" px={2}>
        <Link href={link.href} target="_blank">
          <SuiTypography variant="button" fontWeight="regular" textColor="text">
            {link.name}
          </SuiTypography>
        </Link>
      </SuiBox>
    ));

  return (
    <SuiBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
    >
      <SuiBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
      >
        &copy;
        {currentYear === 2023 ? "2023" : `2023-${new Date().getFullYear()}`}
        <SuiTypography variant="button" fontWeight="medium">
          &nbsp;{company}
        </SuiTypography>
        .&nbsp;All Rights Reserved.
      </SuiBox>
      {links ? (
        <SuiBox component="ul" customClass={classes.footer_list}>
          {renderLinks()}
        </SuiBox>
      ) : null}
    </SuiBox>
  );
};

Footer.defaultProps = {
  company: "Fuse2",
  // links: [{ href: "#", name: "Technical Support" }],
};

Footer.propTypes = {
  company: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
