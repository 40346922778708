import {
  Autocomplete,
  //   Autocomplete,
  Box,
  Card,
  FormControlLabel,
  FormLabel,
  Grid,
  Switch,
  TableCell,
  TableRow,
  TextField,
  //   TextField,
} from "@mui/material";
import { setAssignNumber } from "app/features/AssignSlice";
import { useGetAssignSipTrunkMutation } from "app/features/api/AuthApi";
import { useAssignDIDsMutation } from "app/features/api/AuthApi";
import { useGetCustomerAccountNoMutation } from "app/features/api/AuthApi";
import colors from "assets/theme/base/colors";
import SuiButton from "components/SuiButton";
// import FormLabel from "assets/theme/components/form/formLabel";
import DatePickerUi from "components/SuiDateRangePicker/CommonFeatureDate";
import SuiDropDownList from "components/SuiDropDownList";
import NumberInput from "components/SuiInput/InventoryNumber";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import SuiTypography from "components/SuiTypography";
import dayjs from "dayjs";
// import dayjs from "dayjs";
import { useFormik } from "formik";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import Nodata from "layouts/customer/components/Nodata";
import { debounce } from "lodash";
// import dayjs from "dayjs";

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const AssignDID = () => {
  const { AssignNum } = useSelector((state) => state.assign);
  const [customerListVal, setCustomerListVal] = useState();
  const [flag, setFlag] = useState();
  const [getCustomerAccountNo] = useGetCustomerAccountNoMutation();
  const suiSnackbar = useSuiSnackbar();
  const navigate = useNavigate();
  const [searchTextCust, setSearchTextCust] = useState("");
  const customerTableHeader = [
    { id: "1", align: "left", width: "auto", label: "DID Numbers" },
    { id: "2", align: "left", width: "auto", label: "Area Code" },
    { id: "3", align: "left", width: "auto", label: "Provision to SRE" },
    { id: "4", align: "left", width: "auto", label: "Is Ported" },
    { id: "5", align: "left", width: "auto", label: "Effective Date" },
    { id: "6", align: "left", width: "auto", label: "Rate Override" },
    { id: "7", align: "left", width: "auto", label: "Provision to SIP Server" },
    { id: "8", align: "left", width: "auto", label: "Porting Fees" },
  ];

  const [didNumbers, setDidNumbers] = useState(AssignNum);
  const [status, setStatus] = useState(false);
  const CustomersLists = useCallback(
    debounce(async (value) => {
      const params = value ? { keyword: value?.target?.value } : {};
      setSearchTextCust(value?.target?.value || "");

      try {
        const response = await getCustomerAccountNo(params).unwrap();
        setCustomerListVal(response.data);
      } catch (error) {
        console.error("Error fetching customer details:", error);
      }
    }, 500),
    []
  );
  const [AssignDIDs] = useAssignDIDsMutation();
  const modifiedInventory = didNumbers.map((item) => {
    const newItem = { ...item };
    delete newItem.did_area_code_code;
    newItem.did_inventory_is_ported_in = String(newItem.did_inventory_is_ported_in);
    return newItem;
  });
  console.log(modifiedInventory, "modifiedInventory");
  const formik = useFormik({
    initialValues: {
      company_name: "",
    },
    // validationSchema: AddDidsSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      console.log(values, "values");
      const body = {
        cus_id: values.company_name,
        numbers: modifiedInventory,
      };
      const isSipServerBlank =
        formik.touched.company_name &&
        formik.values.company_name == "" &&
        setFlag(isSipServerBlank);
      if (!isSipServerBlank && !flag) {
        try {
          const resp = await AssignDIDs(body).unwrap();
          suiSnackbar(resp.message, "success");
          navigate("/did-inventory");
        } catch (error) {
          suiSnackbar(error.data.message, "error");
          console.log(error);
        }
      }
    },
  });
  const customerId = formik.values.company_name;
  const matchedCustomer = customerListVal?.filter((customer) => customer.cus_id == customerId);
  const accountNumber = matchedCustomer ? matchedCustomer[0]?.cus_id : null;
  const accountNumberType = matchedCustomer ? matchedCustomer[0]?.cus_account_type : null;
  const handlePorted = (event, index) => {
    const newValue = Number(event.target.value);
    const updatedTable = didNumbers.map((row, idx) => {
      if (idx === index) {
        return {
          ...row,
          did_inventory_is_ported_in: newValue,
          did_inventory_porting_fee_amount:
            newValue == 0 ? 0 : row.did_inventory_porting_fee_amount,
        };
      }
      return row;
    });
    setDidNumbers(updatedTable);
  };
  const [getAssignSipTrunk] = useGetAssignSipTrunkMutation();
  const [SipDetails, setSipDetails] = useState([]);
  console.log(SipDetails, "SipDetails");
  const getCustomerNo = async (id) => {
    try {
      const response = await getAssignSipTrunk(id).unwrap();
      setSipDetails(response?.data);
    } catch (error) {
      setSipDetails([]);
      console.log(error);
    }
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (accountNumber != null || accountNumber != undefined) {
      getCustomerNo(accountNumber);
    } else {
      setSipDetails([]);
    }
    if (accountNumberType !== 0) {
      setDidNumbers((prevNumbers) => {
        const numbersArray = Array.isArray(prevNumbers) ? prevNumbers : [];
        return numbersArray.map((row) => ({
          ...row,
          did_inventory_effective_date: null,
        }));
      });
    }
    if (accountNumberType == 0) {
      setDidNumbers((prevNumbers) => {
        const numbersArray = Array.isArray(prevNumbers) ? prevNumbers : [];
        return numbersArray.map((row) => ({
          ...row,
          did_inventory_effective_date: dayjs().format("YYYY-MM-DD"),
        }));
      });
    }
  }, [accountNumber]);
  const handleOverride = (event, index) => {
    const newValue = Number(event.target.value);
    const updatedTable = didNumbers.map((row, idx) => {
      if (idx === index) {
        return {
          ...row,
          did_inventory_rate_override: newValue,
        };
      }
      return row;
    });
    setDidNumbers(updatedTable);
  };
  const CheckChange = (newValue) => {
    const updatedTable = didNumbers.map((row) => ({
      ...row,
      did_inventory_sip_server_name: newValue,
    }));

    setDidNumbers(updatedTable);
  };

  const CheckSingleChange = (newValue, index) => {
    const updatedTable = didNumbers.map((row, idx) => ({
      ...row,
      did_inventory_sip_server_name: idx === index ? newValue : row.did_inventory_sip_server_name,
    }));

    setDidNumbers(updatedTable);
  };
  const handleDate = (event, index) => {
    const newValue = event;
    const updatedTable = didNumbers.map((row, idx) => {
      if (idx === index) {
        return {
          ...row,
          did_inventory_effective_date: newValue,
        };
      }
      return row;
    });
    setDidNumbers(updatedTable);
  };
  const handlePriceChange = (event, index) => {
    const newValue = Number(event.target.value);
    const updatedTable = didNumbers.map((row, idx) => {
      if (idx === index) {
        return {
          ...row,
          did_inventory_porting_fee_amount: newValue,
        };
      }
      return row;
    });
    setDidNumbers(updatedTable);
  };
  const CustomerData = customerListVal?.map((dropval) => ({
    id: dropval.cus_id,
    label: `(${dropval.cus_account_number}) ${dropval.cus_company_name}`,
  }));
  const customerRows = Array.isArray(didNumbers)
    ? [
        <TableRow key="header-row">
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell>
            <SuiDropDownList
              data={[
                { id: "1", label: "Yes" },
                { id: "0", label: "No" },
              ]}
              select={{
                key: "id",
                label: "Select for all",

                valueKey: "id",
                labelName: "label",
                fn: (event) => {
                  const newValue = Number(event.target.value);
                  setDidNumbers((prevNumbers) =>
                    prevNumbers.map((row) => ({
                      ...row,
                      did_inventory_is_ported_in: newValue,
                      did_inventory_porting_fee_amount: 0,
                    }))
                  );
                },
              }}
            />
          </TableCell>
          <TableCell>
            <DatePickerUi
              placeholder="Pick date for all"
              //   value={formik.values.payment_date}
              color="primary"
              disabled={accountNumberType == 1 || accountNumberType == undefined}
              formik={formik}
              getValue={(newValue) => {
                setDidNumbers((prevNumbers) =>
                  prevNumbers.map((row) => ({
                    ...row,
                    did_inventory_effective_date: newValue,
                  }))
                );
                setStatus(true);
              }}
            />
          </TableCell>
          <TableCell>
            <NumberInput
              placeholder="Enter Rate for all"
              step={1}
              setValue={formik.setFieldValue}
              max={100}
              required
              onChange={(event) => {
                const newValue = Number(event.target.value);
                setDidNumbers((prevNumbers) =>
                  prevNumbers.map((row) => ({
                    ...row,
                    did_inventory_rate_override: newValue,
                  }))
                );
              }}
            />
          </TableCell>
          <TableCell>
            <Autocomplete
              freeSolo={false}
              options={
                Array.isArray(SipDetails) ? SipDetails.map((option) => option.sip_username) : []
              }
              renderInput={(params) => (
                <TextField {...params} placeholder="Select SIP Server Name for all" />
              )}
              onChange={(event, newValue) => {
                CheckChange(newValue);
              }}
              noOptionsText="No options"
              sx={{
                "& .MuiAutocomplete-input:focus": {
                  border: "none !important",
                },
              }}
            />
          </TableCell>
          <TableCell>
            <NumberInput
              placeholder="Enter Fees for all"
              step={1}
              max={100}
              required
              setValue={formik.setFieldValue}
              onChange={(event) => {
                const newValue = Number(event.target.value);

                setDidNumbers((prevNumbers) =>
                  prevNumbers.map((row) => ({
                    ...row,
                    did_inventory_porting_fee_amount:
                      row.did_inventory_is_ported_in == 0 ? 0 : newValue,
                  }))
                );
              }}
            />
          </TableCell>
        </TableRow>,
        ...didNumbers.map((row, index) => (
          <TableRow key={row.did_inventory_number}>
            <TableCell>{row.did_inventory_number}</TableCell>
            <TableCell>{row?.did_area_code_code || row.did_area_code}</TableCell>
            <TableCell>
              <FormControlLabel
                sx={{ ml: "20px" }}
                id={`cus_2fa${row.did_inventory_number}`}
                name="cus_2fa"
                control={
                  <Switch
                    checked={row.did_inventory_provision_to_sre === "1"}
                    color="primary"
                    sx={{ ml: 2 }}
                    disabled
                  />
                }
              />
            </TableCell>
            <TableCell>
              <SuiDropDownList
                data={[
                  { id: "1", label: "Yes" },
                  { id: "0", label: "No" },
                ]}
                select={{
                  key: "id",
                  valueKey: "id",
                  labelName: "label",
                  name: "did_range_holder_status",
                  val: row.did_inventory_is_ported_in || "0", // Default to "0" if undefined

                  //   value={row.did_inventory_porting_fee_amount || 0}
                  fn: (event) => handlePorted(event, index),
                }}
              />
            </TableCell>
            <TableCell>
              <DatePickerUi
                placeholder="Pick Date"
                value={
                  accountNumberType == 0 && status == false
                    ? dayjs()
                    : row.did_inventory_effective_date
                    ? dayjs(row.did_inventory_effective_date)
                    : null
                }
                color="primary"
                disabled={accountNumberType == 1 || accountNumberType == undefined}
                formik={formik}
                getValue={(value) => {
                  handleDate(value, index);
                  setStatus(true);
                }}
              />
            </TableCell>
            <TableCell>
              <NumberInput
                placeholder="Enter Rate"
                setValue={formik.setFieldValue}
                required
                value={row.did_inventory_rate_override || 0}
                onChange={(event) => handleOverride(event, index)}
              />
            </TableCell>
            <TableCell>
              <Autocomplete
                freeSolo={false}
                options={
                  Array.isArray(SipDetails) ? SipDetails.map((option) => option.sip_username) : []
                }
                renderInput={(params) => (
                  <TextField {...params} placeholder="Select SIP Server Name" />
                )}
                onChange={(event, newValue) => {
                  const valueToSet =
                    typeof newValue === "string" ? newValue : newValue?.username || "";
                  CheckSingleChange(valueToSet, index);
                }}
                sx={{
                  "& .MuiAutocomplete-input:focus": {
                    border: "none !important",
                  },
                }}
                value={row.did_inventory_sip_server_name || ""}
              />
            </TableCell>
            <TableCell>
              <NumberInput
                placeholder="Enter Fees"
                step={1}
                setValue={formik.setFieldValue}
                max={100}
                disabled={row.did_inventory_is_ported_in == 0}
                required
                value={row.did_inventory_porting_fee_amount || 0}
                onChange={(event) => handlePriceChange(event, index)}
              />
            </TableCell>
          </TableRow>
        )),
      ]
    : null;
  useEffect(() => {
    if (searchTextCust.length > 0) {
      CustomersLists(searchTextCust);
    } else {
      setCustomerListVal([]);
    }
  }, [searchTextCust, CustomersLists]);

  useEffect(() => {
    CustomersLists();
  }, []);
  return (
    <>
      <Card sx={{ my: 3, p: 2 }}>
        {AssignNum?.length > 0 && (
          <Grid>
            <Grid item xs={12} md={6} pb={1}>
              <Grid item xs={12} md={6} pb={4} pt={4}>
                <SuiTypography vhariant="h6" fontWeight="bold">
                  Configurations
                </SuiTypography>
              </Grid>
              <Grid item xs={12} mb={2}>
                <FormLabel htmlFor="customer_name" required>
                  Company Name
                </FormLabel>

                <div style={{ width: "500px" }}>
                  <Autocomplete
                    options={CustomerData}
                    sx={{
                      "& .MuiAutocomplete-input:focus": {
                        border: "none !important",
                      },
                    }}
                    getOptionLabel={(option) => option.label || ""} // Ensure option has a label
                    onChange={(event, newValue) => {
                      formik.setFieldValue("company_name", newValue ? newValue.id : "");
                      const updatedTable = didNumbers.map((row) => ({
                        ...row,
                        did_inventory_sip_server_name: null,
                      }));
                      setDidNumbers(updatedTable);
                      setStatus(false);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Company Name" />
                    )}
                  />
                </div>
              </Grid>

              {formik.touched.company_name && !formik.values.company_name && (
                <div style={{ color: "#fc3c3c", fontSize: "0.9em" }}>
                  Company Name cannot be blank.
                </div>
              )}
            </Grid>

            <Box
              className="didTableSet"
              sx={{
                border: `1px solid ${colors.accous.main}`,
                borderRadius: "20px",
              }}
            >
              <EnhancedTable
                tableMinWidth={1300}
                tablehead={customerTableHeader}
                rowsData={customerRows}
              />
            </Box>

            <div style={{ marginTop: 20 }}>
              <SuiButton
                size="small"
                variant="outlined"
                buttonColor="dark"
                onClick={() => {
                  dispatch(setAssignNumber([]));
                }}
                // onClick={handleBack}
                component={Link}
                to={"/did-inventory"}
              >
                Cancel
              </SuiButton>
              <SuiButton
                buttonColor="primary"
                type="button"
                size="small"
                // disabled={didNumbers?.length == 0}
                onClick={() => {
                  formik.handleSubmit();
                }}
                style={{ marginLeft: 10 }}
              >
                {"Assign"}
              </SuiButton>
            </div>
          </Grid>
        )}
        {AssignNum?.length == 0 && <Nodata />}
      </Card>
    </>
  );
};

export default AssignDID;
