import colors from "../../base/colors";
import typography from "../../base/typography";
import pxToRem from "../../functions/pxToRem";

const { white, text, primary, secondary } = colors;
const { size } = typography;

export default {
  base: {
    backgroundColor: white.main,
    minHeight: pxToRem(40),
    color: text.main,
    boxShadow: "none",
    padding: `${pxToRem(12)} ${pxToRem(24)}`,

    "&:hover, &:focus, &:active, &:active:focus, &:active:hover, &:disabled": {
      boxShadow: "none !important",
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(32),
    padding: `${pxToRem(8)} ${pxToRem(32)}`,
    fontSize: size.sm,
    borderRadius: "5px",

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(18)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(45),
    padding: `${pxToRem(8)} ${pxToRem(40)}`,
    fontSize: size.md,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(18)} !important`,
    },
  },

  primary: {
    backgroundColor: primary.main,

    "&:hover": {
      backgroundColor: primary.focus,
    },

    "&:focus:not(:hover)": {
      backgroundColor: primary.focus,
    },
  },

  secondary: {
    backgroundColor: secondary.main,

    "&:hover": {
      backgroundColor: secondary.focus,
    },

    "&:focus:not(:hover)": {
      backgroundColor: secondary.focus,
    },
  },
};
