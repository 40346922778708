import {
  Grid,
  TableCell,
  TableRow,
  Card,
  IconButton,
  DialogTitle,
  DialogContent,
  MenuItem,
  Select,
  fabClasses,
} from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useFormik } from "formik";
import useNavigateSearch from "hooks/useNavigateSearch";
import SuiTypography from "components/SuiTypography";
import DeleteIcon from "Container/Icons/DeleteIcon";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
// import dayjs from "dayjs";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import SuiDialog from "components/SuiDialog";
import ErrorMessageComponent from "components/FormikErrorMessage";
import FileUploadUI from "components/SuiFileUploadInput/FileUploadUI";
// import RequestStatusSelector from "components/StatusSelector/RequestStatusSelector";
import { useStatusUpdateMutation } from "app/features/api/AuthApi";
import { importCSV } from "Validation";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { Permission } from "Permissions/Permission";
import { useSelector } from "react-redux";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import { GeoCodeHeader } from "layouts/assign-dids/data";
import { useGetAllGeoAreaMutation } from "app/features/api/AuthApi";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import { useGetAllCountryMutation } from "app/features/api/AuthApi";
import EditIcon from "Container/Icons/EditIcon";
import { useDeleteGeoAreaMutation } from "app/features/api/AuthApi";
import { useImportGeoAreaMutation } from "app/features/api/AuthApi";
import { useSampleGeoAreaMutation } from "app/features/api/AuthApi";

const GeoCodeList = () => {
  let page = new URLSearchParams(window.location.search).get("page");
  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [getDIDs, setGetDids] = useState("");
  const [getAllGeoArea, { isLoading: isLoading }] = useGetAllGeoAreaMutation();
  const [statusUpdate] = useStatusUpdateMutation();
  const [formatCSVUrl, setFormatCSVUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [DeleteGeoArea] = useDeleteGeoAreaMutation();
  const [perPage, setPerPage] = useState(10);
  const [sampleGeoArea] = useSampleGeoAreaMutation();
  const suiSnackbar = useSuiSnackbar();
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);
  if (page === null) {
    page = 1;
  }
  const navigateSearch = useNavigateSearch();
  const formik = useFormik({
    initialValues: {
      code: "",
      Country: "",
      status: "",
      // page: 1,
    },
    enableReinitialize: true,
    onSubmit: () => {
      handleSubmitForm(perPage, 1);
      setCurrentPage(1);
    },
  });
  const formatCSV = async () => {
    try {
      const response = await sampleGeoArea().unwrap();
      setFormatCSVUrl(response.data.url);
      // navigateSearch("/geo-area-code", { page: page });
      // setGetDids(response.data?.rows);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmitForm = async (perPage, currentPage) => {
    navigateSearch("/geo-area-code", { page: currentPage });
    let urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.Country) {
      urlParamss.country_id = formik.values.Country.id;
    }

    if (formik.values.code) {
      urlParamss.did_area_code_code = formik.values.code;
    }

    try {
      const response = await getAllGeoArea(urlParamss).unwrap();
      navigateSearch("/geo-area-code", { page: currentPage });
      setGetDids(response.data?.rows);
      setCount(response?.data?.count);
    } catch (error) {
      setGetDids("");
      setCount("");
    }
  };
  const handleRestForm = async () => {
    navigateSearch("/geo-area-code", { page: 1 });
    let urlParamss = {
      results_per_page: 10,
      page: 1,
    };
    formik.setFieldValue("customer_name", "");
    try {
      setCurrentPage(1);
      const response = await getAllGeoArea(urlParamss).unwrap();
      navigateSearch("/geo-area-code", { page: 1 });
      setGetDids(response.data?.rows);
      setCount(response?.data?.count);
      formik.setFieldValue("customer_name", "");
    } catch (error) {
      setGetDids("");
      setCount("");
    }
  };
  const handleDeleteIconClick = async (id) => {
    try {
      const response = await DeleteGeoArea(id).unwrap();
      if (response.status === 200) {
        suiSnackbar(response.message, "success");
        setOpenModal(false);
      }
    } catch (error) {
      suiSnackbar(error.data.message, "error");
      setOpenModal(false);
    }
    handleSubmitForm(perPage, currentPage);
  };
  const handleButtonClick = () => {
    window.location.href = formatCSVUrl;
  };
  const updateStatusFunc = async (id, status) => {
    const body = {
      id: id,
      assign_did_status: status,
    };
    try {
      const response = await statusUpdate(body).unwrap();
      suiSnackbar(response.message, "success");
      handleSubmitForm(perPage, currentPage);
    } catch (error) {
      console.log(error);
    }
  };
  const [ImportGeoArea] = useImportGeoAreaMutation();
  const checkval = fabClasses;
  useEffect(() => {
    if (checkval) {
      updateStatusFunc();
    }
  }, []);
  useEffect(() => {
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
    handleSubmitForm(perPage, currentPage);
  }, [perPage, currentPage]);

  const [openModal, setOpenModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");
  const [GetAllCountry] = useGetAllCountryMutation();
  const [countryList, setCountryList] = useState();

  const deleteModal = (id) => {
    setDeleteID(id);
    setOpenModal(true);
  };
  const isActions =
    permissionInfo?.geo_area?.UPDATE || permissionInfo?.geo_area?.DELETE
      ? {
          id: "4",
          align: "right",
          width: "50px",
          label: "Action",
        }
      : null;

  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/geo-area-code", { page: 1 });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/geo-area-code", { page: page });
  };
  const editRedirect = (item) => {
    localStorage.setItem("geo_id", item);
    navigateSearch(`edit-geo-area-code`);
  };
  const rows =
    getDIDs && Array.isArray(getDIDs)
      ? getDIDs.map((item, i) => {
          const labelId = `item.cus_id_${i}`;
          return (
            <>
              <TableRow key={labelId}>
                <TableCell>{item.did_area_code_caption}</TableCell>
                <TableCell>{item.did_area_code_code}</TableCell>
                <TableCell>{item.countries.country_name}</TableCell>

                {permissionInfo.geo_area.UPDATE && !permissionInfo.geo_area.DELETE && (
                  <TableCell align="right">
                    <IconButton onClick={() => editRedirect(item.did_area_code_id)}>
                      {<EditIcon />}
                    </IconButton>
                  </TableCell>
                )}
                {!permissionInfo.geo_area.UPDATE && permissionInfo.geo_area.DELETE && (
                  <TableCell align="right">
                    <IconButton onClick={() => deleteModal(item.did_area_code_id)}>
                      {<DeleteIcon />}
                    </IconButton>
                  </TableCell>
                )}
                {permissionInfo.geo_area.UPDATE && permissionInfo.geo_area.DELETE && (
                  <TableCell align="right">
                    {item.admin_role_name !== "Super Admin" && (
                      <>
                        <IconButton onClick={() => editRedirect(item.did_area_code_id)}>
                          {<EditIcon />}
                        </IconButton>
                        <IconButton onClick={() => deleteModal(item.did_area_code_id)}>
                          {<DeleteIcon />}
                        </IconButton>
                      </>
                    )}
                  </TableCell>
                )}
              </TableRow>
            </>
          );
        })
      : [];

  const importCsvFormik = useFormik({
    initialValues: {
      file: "",
    },
    validationSchema: importCSV,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const formData = new FormData();
      const files = values.file;
      formData.append("file", files);
      try {
        const response = await ImportGeoArea(formData).unwrap();
        if (response.status === 200) {
          suiSnackbar(response.message, "success");
          importCsvFormik.resetForm();
          setOpen(false);
          handleSubmitForm(perPage, currentPage);
        }
      } catch (error) {
        console.log(error);
        const url = window.URL.createObjectURL(new Blob([error.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Unexpected_data.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        suiSnackbar("Unexpected data found in csv.", "error");
        setOpen(false);
      }
    },
  });

  const handleFileChange = (file) => {
    importCsvFormik.setFieldValue("file", file);
  };
  const removeFileName = () => {
    return;
  };
  const getCountry = async () => {
    try {
      const response = await GetAllCountry().unwrap();
      setCountryList(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCountry();
    localStorage.removeItem("geo_id");
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, []);
  const CountryOption = countryList?.map((item) => ({
    id: item.country_id,
    name: item.country_name,
  }));
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={6}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="tel"
                  placeholder="Enter Code"
                  id="code"
                  name="code"
                  value={formik.values.code}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <AutocompleteSingleSelect
                  id="Country"
                  value={formik.values.Country}
                  setValue={(value) => {
                    formik.setFieldValue("Country", value);
                    formik.setFieldValue("Country1", value.name);
                  }}
                  noOptionsText="No options"
                  placeholder="Country"
                  isLoading={false}
                  disabled={false}
                  options={CountryOption}
                  formik={formik}
                />
              </Grid>

              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.handleReset();
                    navigateSearch("/geo-area-code", { page: 1 });
                    handleRestForm();
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title={"List of Geo Area Codes"}>
            {permissionInfo?.geo_area?.CREATE ? (
              <>
                <SuiButton
                  size="small"
                  sx={{
                    fontWeight: "500",
                    px: "12px",
                    backgroundColor: "#E9E9E9 !important",
                    color: "#848484 !important",
                  }}
                  // sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                  onClick={() => {
                    setOpen(true);
                    formatCSV();
                  }}
                >
                  Import
                </SuiButton>
                <SuiButton
                  component={Link}
                  size="small"
                  buttonColor="info"
                  sx={{ fontWeight: "500", px: "12px", ml: 2 }}
                  to={"add-geo-area-code"}
                >
                  Add Geo Area Code
                </SuiButton>
              </>
            ) : null}
          </TableToolbar>
          <EnhancedTable
            tableMinWidth={1000}
            tablehead={isActions ? [...GeoCodeHeader, isActions] : [...GeoCodeHeader]}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && getDIDs.length > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
      <SuiDialog open={openModal} onClose={() => setOpenModal(false)} haveCloseIcon>
        <DialogContent
          sx={{
            fontWeight: "semiBold",
          }}
        >
          Are you sure you want to delete this Geo area code ?
          <Grid container rowSpacing={2}>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"end"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => setOpenModal(false)}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                    disabled={importCsvFormik.isSubmitting}
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="info"
                    fullWidth
                    onClick={() => handleDeleteIconClick(deleteID)}
                  >
                    Delete
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
      <SuiDialog open={open} onClose={() => setOpen(false)} haveCloseIcon>
        <DialogTitle sx={{ fontWeight: 600 }}>Import the file</DialogTitle>
        <DialogContent dividers>
          <Grid container rowSpacing={2}>
            <Grid item xs={12}>
              <SuiButton
                size="small"
                sx={{ fontSize: "1rem", fontWeight: "500" }}
                buttonColor="info"
                component="button"
                onClick={handleButtonClick}
              >
                Download Sample File
              </SuiButton>
              <SuiTypography textColor="text2" fontSize={14} pt={1}>
                Download the sample file and update Geo Area Code information according to the
                example given.
              </SuiTypography>
            </Grid>
            <Grid item xs={12}>
              <FileUploadUI
                name="file"
                id="file"
                label="Upload File"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                getFile={handleFileChange}
                removeFileName={removeFileName}
              />
              {!importCsvFormik.values.file && (
                <SuiTypography textColor="text2" fontSize={14} pt={1}>
                  Select a .csv file for importing data
                </SuiTypography>
              )}
              <ErrorMessageComponent
                touched={importCsvFormik.touched.file}
                error={importCsvFormik.errors.file}
              />
            </Grid>
            <Grid item xs={12} sx={{ marginTop: "20px" }}>
              <Grid container justifyContent={"end"} spacing={2}>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    onClick={() => setOpen(false)}
                    buttonColor="dark"
                    variant="outlined"
                    fullWidth
                    disabled={importCsvFormik.isSubmitting}
                  >
                    Cancel
                  </SuiButton>
                </Grid>
                <Grid item xs={12} sm="auto">
                  <SuiButton
                    size="small"
                    sx={{ px: "1rem" }}
                    buttonColor="primary"
                    fullWidth
                    onClick={importCsvFormik.handleSubmit}
                    type="submit"
                    disabled={importCsvFormik.isSubmitting || !importCsvFormik.dirty}
                  >
                    Submit
                  </SuiButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiDialog>
    </>
  );
};

export default GeoCodeList;
