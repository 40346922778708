import { Card, FormLabel, Grid } from "@mui/material";
import { NonGeoCodeSechma } from "Validation";
import { useUpdateNonGeoAreaMutation } from "app/features/api/AuthApi";
import { useAddNonGeoAreaMutation } from "app/features/api/AuthApi";
import { useGetByNonGeoAreaMutation } from "app/features/api/AuthApi";
import ErrorMessageComponent from "components/FormikErrorMessage";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { useFormik } from "formik";
import useNavigateSearch from "hooks/useNavigateSearch";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const NonGeoCodeAction = () => {
  const navigateSearch = useNavigateSearch();
  const suiSnackbar = useSuiSnackbar();
  const currentUrl = window.location.href.includes("edit-non-geo-area-code");
  const [AddGeoArea] = useAddNonGeoAreaMutation();
  const [UpdateGeoArea] = useUpdateNonGeoAreaMutation();
  const [errorVal, setErrorVal] = useState("");
  const id = localStorage.getItem("non_geo_id");
  const formik = useFormik({
    initialValues: {
      did_area_code_caption: "",
      did_area_code_code: "",
    },
    enableReinitialize: true,
    validationSchema: NonGeoCodeSechma,
    onSubmit: async (values) => {
      const body = {
        did_area_code_caption: values.did_area_code_caption.trim(),
        did_area_code_code: values.did_area_code_code.trim(),
      };
      if (currentUrl) {
        body.id = id;
      }
      try {
        const response = await (currentUrl
          ? UpdateGeoArea(body).unwrap()
          : AddGeoArea(body).unwrap());
        navigateSearch("/non-geo-area-code", { page: 1 });
        setErrorVal("");
        suiSnackbar(response.message, "success");
      } catch (error) {
        if (error.data.message) {
          suiSnackbar(error.data.message, "error");
        }
        setErrorVal(error);
      }
    },
  });
  const [getByGeoArea] = useGetByNonGeoAreaMutation();

  const getGeoData = async () => {
    try {
      const response = await getByGeoArea(id).unwrap();
      formik.setFieldValue("did_area_code_caption", response.data.did_area_code_caption);
      formik.setFieldValue("did_area_code_code", response.data.did_area_code_code);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (id) {
      getGeoData();
    }
  }, []);

  useEffect(() => {
    if (errorVal?.data?.errorMessages?.fieldErrors) {
      const fieldErrors = {};
      const errors = errorVal.data.errorMessages.fieldErrors;
      for (const field in errors) {
        if (Object.hasOwn(errors, field)) {
          fieldErrors[field] = errors[field].join(" ");
        }
      }
      formik.setErrors(fieldErrors);
    } else {
      setErrorVal("");
    }
  }, [errorVal, formik]);
  return (
    <Card sx={{ my: 3, p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SuiTypography fontWeight="semiBold" fontSize={16}>
            {currentUrl ? "Edit Non Geo Area Code" : "Add Non Geo Area Code"}
          </SuiTypography>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormLabel htmlFor="did_num" required>
            Caption
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            placeholder="Enter Caption"
            id="did_area_code_caption"
            name="did_area_code_caption"
            value={formik.values.did_area_code_caption}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.did_area_code_caption}
            error={formik.errors.did_area_code_caption}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <FormLabel htmlFor="First_Name" required>
            Code
          </FormLabel>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            placeholder="Enter  Code"
            id="did_area_code_code"
            name="did_area_code_code"
            value={formik.values.did_area_code_code}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent
            touched={formik.touched.did_area_code_code}
            error={formik.errors.did_area_code_code}
          />
        </Grid>
        <Grid item xs={12} mt={4}>
          <SuiButton
            size="small"
            component={Link}
            to="/non-geo-area-code"
            variant="outlined"
            buttonColor="dark"
            sx={{ px: "1rem", py: "0.4rem", marginRight: "14px" }}
            disabled={formik.isSubmitting}
          >
            Cancel
          </SuiButton>
          <SuiButton
            type="submit"
            size="small"
            buttonColor="primary"
            sx={{ px: "1rem" }}
            onClick={formik.handleSubmit}
            disabled={!formik.dirty || formik.isSubmitting}
          >
            {currentUrl ? "Update" : "Add"}
          </SuiButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default NonGeoCodeAction;
