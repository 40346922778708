import React from "react";
import { Box, Checkbox } from "@mui/material";
import SuiTypography from "components/SuiTypography";

const BoxLegends = ({ id, color, label, onLegendFn, labelTextColor }) => {
  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      alignItems="center"
      columnGap={1}
      sx={{
        userSelect: "none",
        "& > .MuiTypography-root": {
          textDecoration: "line-through",
          opacity: 0.7,
        },
        "& > .MuiCheckbox-root.Mui-checked + .MuiTypography-root": {
          textDecoration: "none",
          opacity: 1,
        },
      }}
    >
      <Box backgroundColor={color} width={16} height={16} borderRadius="50%" flexShrink={0} />
      <Checkbox
        size="small"
        id={`legend_${id}`}
        name={`legend_${id}`}
        defaultChecked
        sx={{ display: "none" }}
        onChange={onLegendFn}
      />
      <SuiTypography
        sx={{ cursor: "pointer" }}
        component="label"
        htmlFor={`legend_${id}`}
        fontSize={12}
        fontWeight="medium"
        textColor="light"
        lineHeight={1}
      >
        <span style={{ color: labelTextColor }}>{label}</span>
      </SuiTypography>
    </Box>
  );
};

export default BoxLegends;
