import { Dialog, IconButton } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import colors from "../../assets/theme/base/colors";

const SuiDialog = ({ open, onClose, closeByBackdrop, haveCloseIcon, children, ...rest }) => {
  return (
    <Dialog open={open} onClose={closeByBackdrop ? onClose : () => null} {...rest}>
      {haveCloseIcon && (
        <IconButton
          className="close-btn"
          onClick={onClose}
          color="light"
          sx={{ bgcolor: `${colors.text.main} !important` }}
        >
          <CloseRoundedIcon />
        </IconButton>
      )}
      {children}
    </Dialog>
  );
};

export default SuiDialog;
