import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

const DownloadIcon = ({ size = 19, color = "dark", type = "main", typeColor }) => {
  return (
    <svg
      height={pxToRem(size)}
      viewBox="0 0 19 19"
      fill="none"
      stroke={typeColor == "paymentInvoice" ? colors["info"][type] : colors[color][type]}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.6229 12.6025V16.1952C17.6229 16.6716 17.4336 17.1285 17.0967 17.4654C16.7598 17.8023 16.3029 17.9916 15.8265 17.9916H3.25193C2.77551 17.9916 2.31859 17.8023 1.98171 17.4654C1.64483 17.1285 1.45557 16.6716 1.45557 16.1952V12.6025"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.04883 8.11154L9.53974 12.6025L14.0307 8.11154"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.53955 12.6024V1.82425"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
