import { useState, useEffect, useRef } from "react";
import { Box, IconButton } from "@mui/material";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import SuiInput from ".";

const ArrowButton = ({ children, disabled, ...rest }) => (
  <IconButton
    size="small"
    disabled={disabled}
    {...rest}
    sx={{
      bgcolor: "#fff !important",
      borderRadius: "5px",
      padding: "0 !important",
      color: "#1976d2",
      maxWidth: 30,
      maxHeight: 16,
      fontSize: 24,
      boxShadow: "1px 2px 0 0 #D9DBE7",
    }}
  >
    {children}
  </IconButton>
);

const NumberInput = ({
  name,
  value,
  placeholder,
  onChange,
  setValue,
  max,
  disabled,
  onBlur,
  required,
  step = 1,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const numberInputRef = useRef(null);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue === "" || /^[0-9]*\.?[0-9]*$/.test(inputValue)) {
      setInputValue(inputValue);
      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue)) {
        setValue(name, numericValue);
        onChange(e);
      } else {
        setValue(name, 0);
        onChange({ target: { value: 0 } });
      }
    }
  };

  const arrowButtonHandler = (type) => () => {
    let currentValue = parseFloat(inputValue) || 0;

    if (type === "add") {
      currentValue += step;
    } else {
      currentValue = Math.max(currentValue - step, 0);
    }

    const formattedValue = currentValue.toFixed(2);
    setInputValue(formattedValue);
    setValue(name, parseFloat(formattedValue));
    onChange({ target: { value: currentValue } });
  };

  return (
    <Box position="relative">
      <SuiInput
        type="text"
        size="medium"
        name={name}
        id={name}
        value={inputValue}
        disabled={disabled}
        placeholder={placeholder}
        inputSettings={{
          autoComplete: "off",
          required: Boolean(required),
        }}
        inputProps={{
          max,
          step,
          pattern: "[0-9.]*",
          inputMode: "numeric",
          min: 0,
          ref: numberInputRef,
          sx: {
            pr: "2.5rem",
            px: 3,
          },
        }}
        onChange={handleInputChange}
        onBlur={onBlur}
      />
      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        rowGap={0.5}
        top="50%"
        right={10}
        sx={{ transform: "translateY(-50%)" }}
      >
        <ArrowButton disabled={disabled} onClick={arrowButtonHandler("add")}>
          <ArrowDropUpRoundedIcon />
        </ArrowButton>
        <ArrowButton onClick={arrowButtonHandler("subtract")} disabled={disabled}>
          <ArrowDropDownRoundedIcon />
        </ArrowButton>
      </Box>
    </Box>
  );
};

export default NumberInput;
