import pxToRem from "assets/theme/functions/pxToRem";

const DidIcon = ({ size }) => {
  return (
    <svg height={pxToRem(size)} viewBox="0 0 29 28" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.7076 12.2467C12.1619 11.7925 12.3981 11.211 12.3981 10.5751V7.3772C12.3981 6.06897 11.3442 5.01512 10.036 5.01512H6.87444C6.20216 5.01512 5.56621 5.30584 5.11197 5.79642C4.65772 6.30518 4.43968 6.97746 4.53053 7.63158C5.00295 11.8288 6.89261 15.7898 9.89064 18.7879C12.8887 21.7859 16.8497 23.6937 21.0469 24.148C21.1378 24.1661 21.2286 24.1661 21.3013 24.1661C21.8827 24.1661 22.446 23.9481 22.8821 23.5665C23.3908 23.1123 23.6634 22.4763 23.6634 21.8041V18.6425C23.6634 17.3343 22.6095 16.2804 21.3013 16.2804H17.3766C16.7407 16.2804 16.1592 16.5348 15.705 16.9709L14.9055 17.7704C14.4513 18.2246 13.7063 18.2791 13.2157 17.8612C12.18 17.0254 11.2534 16.0806 10.4721 15.0267C10.0905 14.518 10.145 13.8093 10.5993 13.3551L11.7076 12.2467ZM15.7958 7.06831C18.8302 7.44988 21.2286 9.86647 21.6284 12.9008C21.6647 13.1734 21.9009 13.3914 22.1735 13.3914C22.5005 13.3914 22.7549 13.1007 22.7185 12.7736C22.2643 9.2487 19.4661 6.45054 15.9594 6.01446C15.6323 5.97812 15.3416 6.21433 15.3416 6.55956C15.3053 6.79577 15.5051 7.03197 15.7958 7.06831Z" />
      <path d="M16.035 9.95312C17.3475 10.2812 18.3756 11.3312 18.7037 12.6219C18.7694 12.9062 19.0319 13.1031 19.3381 13.1031C19.7756 13.1031 20.0819 12.6875 19.9725 12.2719C19.5131 10.5219 18.135 9.12187 16.3631 8.6625C15.9475 8.55312 15.5319 8.85937 15.5319 9.29687C15.5537 9.625 15.7506 9.8875 16.035 9.95312Z" />
    </svg>
  );
};

export default DidIcon;
