import colors from "../../base/colors";
import pxToRem from "../../functions/pxToRem";

const { error } = colors;

export default {
  styleOverrides: {
    root: {
      fontSize: pxToRem(13),
      fontWeight: "500",
      display: "inline-block",
      paddingBottom: pxToRem(4),
    },
    asterisk: {
      color: error.focus,
    },
  },
};
