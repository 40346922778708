import React, { useEffect, useState } from "react";
import { Grid, Card, TableRow, TableCell, Select, MenuItem } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import TableToolbar from "components/SuiTable/TableToolbar";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import DatePickerUi from "components/SuiDateRangePicker/commonSingleDatePicker";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useGetThreeCXMutation } from "app/features/api/AuthApi";
import ViewIcon from "Container/Icons/EyeIcon";
import IconButton from "@mui/material/IconButton";
import useNavigateSearch from "hooks/useNavigateSearch";
import CommonPagination from "components/SuiTablePagination/CommonPagination";
import SuiTypography from "components/SuiTypography";
// import LicenseEditionDropdown from "components/SuiDropDownList/LicenseEditionDropdown";
import CxPaymentStatusDropdown from "components/SuiDropDownList/CxPaymentStatusDropdown";
import AutocompleteSingleSelect from "components/SuiAutocomplete/AutoComplete";
import { useGetLienceValueMutation } from "app/features/api/AuthApi";

const ThreeCXDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [threeCXInfo, setThreeCXInfo] = useState("");
  const [getThreeCX, { isLoading }] = useGetThreeCXMutation();
  const navigateSearch = useNavigateSearch();
  const [GetLienceValue] = useGetLienceValueMutation();
  const [dropvals, setDropVal] = useState([]); // Initialize as an empty array
  const formik = useFormik({
    initialValues: {
      cus_firstname: "",
      cus_lastname: "",
      cus_company_name: "",
      cus_phone_number: "",
      license_3cx_id: "",
      license_3cx_name: "",
      license_code: "",
      payment_status: "",
      payment_transaction_id: "",
      payment_date: null,
      ordered_on: null,
    },
    onSubmit: () => {
      showData(perPage, 1);
    },
    onReset: () => {
      formik.values.cus_firstname = "";
      formik.values.cus_lastname = "";
      formik.values.cus_company_name = "";
      formik.values.cus_phone_number = "";
      formik.values.license_3cx_id = "";
      formik.values.license_code = "";
      formik.values.payment_status = "";
      formik.values.payment_transaction_id = "";
      formik.values.payment_date = null;
      formik.values.ordered_on = null;
      setThreeCXInfo("");
      showData(perPage, 1); // Call showData with initial page
    },
  });

  const viewData = (item) => {
    localStorage.setItem("three_id", item);
    navigateSearch("view-3cx-details");
  };
  const maskString = (str) => {
    if (str?.length <= 4) return str;
    const last4 = str?.slice(-4);
    const masked = "*"?.repeat(str?.length - 4) + last4;
    return masked;
  };
  const dropdownValues = async () => {
    try {
      const response = await GetLienceValue().unwrap();
      setDropVal(response.data.licences);
    } catch (error) {
      console.error("Error fetching license values:", error);
    }
  };

  const licenseEdition = dropvals.map((dropval) => ({
    id: dropval.license_id,
    name: dropval.license_name,
  }));
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/3cx-details", { page: 1 });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/3cx-details", { page: page });
  };

  const showData = async (perPage, currentPage) => {
    const urlParamss = {
      results_per_page: perPage,
      page: currentPage,
    };
    if (formik.values.cus_firstname) {
      urlParamss.cus_firstname = formik.values.cus_firstname.trim();
    }
    if (formik.values.cus_lastname) {
      urlParamss.cus_lastname = formik.values.cus_lastname.trim();
    }
    if (formik.values.cus_company_name) {
      urlParamss.cus_company_name = formik.values.cus_company_name.trim();
    }
    if (formik.values.cus_phone_number) {
      urlParamss.cus_phone_number = formik.values.cus_phone_number.trim();
    }
    if (formik.values.license_3cx_id) {
      urlParamss.license_id = formik.values.license_3cx_id.id.trim();
    }
    if (formik.values.license_code) {
      urlParamss.customer_license_code = formik.values.license_code.trim();
    }
    if (formik.values.payment_status) {
      urlParamss.payment_status =
        formik.values.payment_status === "0"
          ? "Pending"
          : formik.values.payment_status === "1"
          ? "Success"
          : "Failed";
    }
    if (formik.values.payment_transaction_id) {
      urlParamss.payment_transaction_id = formik.values.payment_transaction_id.trim();
    }
    if (formik.values.payment_date) {
      urlParamss.payment_date = formik.values.payment_date;
    }
    if (formik.values.payment_date) {
      urlParamss.ordered_on = formik.values.ordered_on;
    }
    try {
      setThreeCXInfo("");
      const response = await getThreeCX(urlParamss).unwrap();
      setThreeCXInfo(response.data.rows);
      setCount(response?.data?.count);
    } catch (error) {
      if (!error.response || !error.response.status) {
        console.error("Network error:", error.message);
      } else {
        console.error("Request failed with status:", error.response.status);
      }
    }
  };

  useEffect(() => {
    showData(perPage, currentPage);
    dropdownValues();
  }, [perPage, currentPage]);

  const pagesTableHeader = [
    { id: "1", align: "left", width: "auto", label: "First Name" },
    { id: "2", align: "left", width: "auto", label: "Last Name" },
    { id: "3", align: "left", width: "auto", label: "Company Name" },
    { id: "4", align: "left", width: "auto", label: "Mobile Number" },
    { id: "5", align: "left", width: "auto", label: "Licence Edition" },
    { id: "6", align: "left", width: "auto", label: "Licence Key" },
    { id: "7", align: "left", width: "auto", label: "Simultaneous calls" },
    { id: "8", align: "left", width: "auto", label: "Purchased On" },
    { id: "9", align: "left", width: "auto", label: "Price" },
    { id: "10", align: "left", width: "auto", label: "Payment Status" },
    { id: "11", align: "left", width: "auto", label: "Payment date" },
    { id: "12", align: "left", width: "auto", label: "Licence expiry" },
    { id: "13", align: "left", width: "auto", label: "Transcation ID" },
    { id: "14", align: "left", width: "auto", label: "Action" },
  ];

  const rows =
    threeCXInfo &&
    threeCXInfo.map((item) => (
      <TableRow key={item.payment_transaction_id}>
        <TableCell>{`${item.cus_firstname}`}</TableCell>
        <TableCell>{`${item.cus_lastname}`}</TableCell>
        <TableCell>{`${item.cus_company_name}`}</TableCell>
        <TableCell>{`${item.cus_phone_number}`}</TableCell>
        <TableCell>{`${item.three_cx_licenses.license_name}`}</TableCell>
        <TableCell>
          {item.customer_license_code === null ? "-" : maskString(item.customer_license_code)}
        </TableCell>
        <TableCell>{`${item.customer_licenses_simultaneous_calls}`}</TableCell>
        <TableCell>
          {item.payment_status === "Pending" ? "-" : item.customer_licenses_ordered_on}
        </TableCell>
        <TableCell sx={{ minWidth: "100px" }}>{`£ ${item.customer_license_price}`}</TableCell>
        <TableCell>
          {item.payment_status === "Success" ? (
            <SuiTypography fontSize={14} sx={{ color: "#2DC573 !important" }}>
              Completed
            </SuiTypography>
          ) : item.payment_status === "Pending" ? (
            <SuiTypography fontSize={14} sx={{ color: "#848484 !important" }}>
              Pending
            </SuiTypography>
          ) : item.payment_status === "Failed" ? (
            <SuiTypography fontSize={14} sx={{ color: "#C02B0A !important" }}>
              Failed
            </SuiTypography>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell>{item.payment_date === "" ? "-" : item.payment_date}</TableCell>
        <TableCell>
          {item.payment_status === "Pending" ? "-" : item.customer_licenses_expiry_date}
        </TableCell>
        <TableCell>
          {item.payment_transaction_id === "" ? "-" : item.payment_transaction_id}
        </TableCell>
        <TableCell align="right">
          <IconButton
            className="viewIcon"
            component={Link}
            onClick={() => viewData(item.customer_licenses_id)}
          >
            <ViewIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));

  const handleFormSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2} onSubmit={handleFormSubmit}>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettings={{ autoComplete: "off" }}
                  type="text"
                  placeholder="First name"
                  id="cus_firstname"
                  name="cus_firstname"
                  value={formik.values.cus_firstname}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettings={{ autoComplete: "off" }}
                  type="text"
                  placeholder="Last name"
                  id="cus_lastname"
                  name="cus_lastname"
                  value={formik.values.cus_lastname}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettings={{ autoComplete: "off" }}
                  type="text"
                  placeholder="Company Name"
                  id="cus_company_name"
                  name="cus_company_name"
                  value={formik.values.cus_company_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettings={{ autoComplete: "off" }}
                  type="text"
                  placeholder="Mobile number"
                  id="cus_phone_number"
                  name="cus_phone_number"
                  value={formik.values.cus_phone_number}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <AutocompleteSingleSelect
                  id="license_3cx_id"
                  value={formik.values.license_3cx_id}
                  setValue={(value) => {
                    formik.setFieldValue("license_3cx_id", value);
                    formik.setFieldValue("license_3cx_name", value.name);
                  }}
                  placeholder="Select Licence Edition"
                  isLoading={false}
                  disabled={false}
                  options={licenseEdition}
                  formik={formik}
                />
                {/* <LicenseEditionDropdown
                  name="license_3cx_id"
                  value={formik.values.license_3cx_id}
                  setValue={formik.handleChange}
                /> */}
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettings={{ autoComplete: "off" }}
                  type="text"
                  placeholder="Licence Key"
                  id="license_code"
                  name="license_code"
                  value={formik.values.license_code}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <CxPaymentStatusDropdown
                  name="payment_status"
                  value={formik.values.payment_status}
                  setValue={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <SuiInput
                  inputSettings={{ autoComplete: "off" }}
                  type="text"
                  placeholder="Transaction ID"
                  id="payment_transaction_id"
                  name="payment_transaction_id"
                  value={formik.values.payment_transaction_id}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4} xxl={3}>
                <DatePickerUi
                  placeholder="Date"
                  value={formik.values.payment_date}
                  color="primary"
                  formik={formik}
                  getValue={(value) => formik.setFieldValue("payment_date", value)}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={formik.handleReset}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title="List of 3CX Details"></TableToolbar>
          <EnhancedTable
            tableMinWidth={540}
            tablehead={pagesTableHeader}
            rowsData={rows}
            isLoading={isLoading}
          />
          {!isLoading && threeCXInfo.length > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    {/* Select dropdown for items per page */}
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default ThreeCXDetails;
