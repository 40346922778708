import { baseAPIService } from ".";

const CustomerStatusApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    updateCustomerNetworkStatus: build.mutation({
      query: (body) => {
        return {
          url: `admin/customer/network/${body.id}/${body.status}`,
          method: "PATCH",
        };
      },
    }),
    updateCustomerTwoFactorAuth: build.mutation({
      query: (body) => {
        return {
          url: `admin/customer/two-factor-auth/${body.id}/${body.status}`,
          method: "PATCH",
        };
      },
    }),
  }),
  overrideExisting: true,
});
export const { useUpdateCustomerNetworkStatusMutation, useUpdateCustomerTwoFactorAuthMutation } =
  CustomerStatusApi;
