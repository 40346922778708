import PropTypes from "prop-types";
import SuiDropDownList from "components/SuiDropDownList";
import { dateType } from "./dropdownArrays";

const DateSelectionDropdown = ({ name, value, setValue, handlePostPaidSelection }) => {
  const handleChange = (selectedValue) => {
    setValue(selectedValue);
    handlePostPaidSelection();
  };
  return (
    <SuiDropDownList
      data={dateType}
      select={{
        key: "id",
        valueKey: "id",
        labelName: "label",
        label: "Select",
        name: name,
        val: value,
        fn: handleChange,
      }}
    />
  );
};

DateSelectionDropdown.defaultProps = {
  name: "status_type",
};

DateSelectionDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
  handlePostPaidSelection: PropTypes.func,
};

export default DateSelectionDropdown;
