import { createBrowserRouter, RouterProvider } from "react-router-dom";
// import routes from "./routes.js";
import "./index.css";
import useRoutes from "hooks/useRoutes.js";

export default function App() {
  const { perMissionsroutes } = useRoutes();
  const router = createBrowserRouter(perMissionsroutes);
  return <RouterProvider router={router} />;
}
