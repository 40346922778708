import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  permission: "",
};

export const PermissionSlice = createSlice({
  initialState,
  name: "permissionSlice",
  reducers: {
    setPermission: (state, { payload }) => {
      state.permission = payload;
    },
  },
});

export const { setPermission } = PermissionSlice.actions;

export default PermissionSlice.reducer;
