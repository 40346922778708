import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  counts: {
    total_customer_count: 0,
    total_did_count: 0,
    total_number_port_in_count: 0,
    total_order_count: 0,
    total_payment: "0",
    total_sip_trunk_count: 0,
  },
  customerID: "",
  country: "",
  address: { address: "", city: "", postalCode: "" },
  checkCountry: "",
  countryName: "",
  AccountNoValue: "",
  selectedCustomerDetails: null,
  selectedAccountNo: null,
  dayOfplans: { id: "", name: "" },
  dayOfplanCatorys: { id: "", name: "" },
  checkTimeOfday: { id: "", name: "" },
  customerInfo: {},
  dateRange: [
    {
      startDate: new Date().getTime(),
      endDate: new Date().getTime(),
      key: "selection",
    },
  ],
};

export const dashboardSlice = createSlice({
  initialState,
  name: "dashboardSlice",
  reducers: {
    setDashboardCounts: (state, action) => {
      state.counts = action.payload;
    },
    setID: (state, action) => {
      state.customerID = action.payload;
    },
    setCountry: (state, action) => {
      state.country = action.payload;
    },
    setDateRange: (state, { payload }) => {
      state.dateRange = payload.map((item) => ({
        ...item,
        startDate: item.startDate.getTime(),
        endDate: item.endDate.getTime(),
      }));
    },
    setCountryName: (state, action) => {
      state.countryName = action.payload;
    },
    setDayOfPlan: (state, action) => {
      state.dayOfplans = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setCheckCountry: (state, { payload }) => {
      state.checkCountry = payload;
    },
    setCustomerInfo: (state, { payload }) => {
      state.customerInfo = payload;
    },
    setSelectedAccountNoValue: (state, { payload }) => {
      state.AccountNoValue = payload;
    },
    setSelectedcustomerDetails: (state, { payload }) => {
      state.selectedCustomerDetails = payload;
    },
    setSelectedAccountNo: (state, { payload }) => {
      state.selectedAccountNo = payload;
    },
    setDayOfPlanCatory: (state, { payload }) => {
      state.dayOfplanCatorys = payload;
    },
    setCheckTimeOfdayValue: (state, { payload }) => {
      state.checkTimeOfday = payload;
    },
  },
});

export const {
  setDashboardCounts,
  setCheckTimeOfdayValue,
  setID,
  setCountry,
  setAddress,
  setCountryName,
  setDayOfPlanCatory,
  setCheckCountry,
  setDayOfPlan,
  setSelectedAccountNoValue,
  setSelectedcustomerDetails,
  setDateRange,
  setSelectedAccountNo,
  setCustomerInfo,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
