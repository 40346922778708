import borders from "../base/borders";

const { borderRadius } = borders;

export default {
  styleOverrides: {
    root: {
      transition: "all 200ms ease-in-out",
      fontSize: "17px",
      fontWeight: "500",
    },

    rounded: {
      borderRadius: borderRadius.lg,
    },
  },
};
