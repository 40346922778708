import typography from "../../base/typography";
import colors from "../../base/colors";
// import pxToRem from "../../functions/pxToRem";

const { size, fontWeightRegular, fontWeightMedium } = typography;
const { dark, secondary, primary, accous } = colors;

export default {
  styleOverrides: {
    label: {
      fontWeight: fontWeightRegular,
      fontSize: size.regular,
      color: `${secondary.focus} !important`,
      padding: "7px 30px",
      border: `1px solid ${accous.main}`,
      borderRadius: "5px",
      position: "relative",

      "&.Mui-active": {
        fontWeight: `${fontWeightMedium} !important`,
        color: `${dark.main} !important`,
        backgroundColor: `${primary.main} !important`,
        border: `1px solid ${primary.main} !important`,
      },

      "&.Mui-completed": {
        fontWeight: `${fontWeightRegular} !important`,
        color: `${secondary.focus} !important`,
        backgroundColor: `transparent !important`,
        border: `1px solid ${primary.main} !important`,
      },
    },
  },
};
