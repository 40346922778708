import PropTypes from "prop-types";
import SuiDropDownList from "components/SuiDropDownList";
import { subscriptionStatus } from "./dropdownArrays";

const SubscriptionStatusDropdown = ({ name, value, setValue }) => {
  return (
    <SuiDropDownList
      data={subscriptionStatus}
      select={{
        key: "id",
        valueKey: "id",
        labelName: "label",
        label: "Select",
        name: name,
        val: value,
        fn: setValue,
      }}
    />
  );
};

SubscriptionStatusDropdown.defaultProps = {
  name: "cus_sub_status",
};

SubscriptionStatusDropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
};

export default SubscriptionStatusDropdown;
