import SuiTypography from "components/SuiTypography";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { emailValidationSchema } from "Validation";
import SuiInput from "components/SuiInput";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useForgotPasswordMutation } from "app/features/api/AuthApi";
import { useState } from "react";
import { CircularProgress, Grid } from "@mui/material";
import useSuiSnackbar from "hooks/useSuiSnackbar";

const EnterEmail = () => {
  const [forgotPassword] = useForgotPasswordMutation();
  const [error, setError] = useState("");
  const suiSnackbar = useSuiSnackbar();

  const formik = useFormik({
    initialValues: { email: "" },
    validationSchema: emailValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        email: values.email,
      };
      try {
        const response = await forgotPassword(body).unwrap();
        if (response.status === 200) {
          suiSnackbar(response.message, "success");
        }
      } catch (error) {
        setError(error);
      }
    },
  });

  return (
    <>
      <SuiTypography component="h1" variant="formHeading">
        Forgot password
      </SuiTypography>
      <SuiTypography component="p" textColor="text2" variant="formSubHeading">
        Please check your email ID to check the link
      </SuiTypography>
      <SuiBox mt={2} mb={3.5}>
        <SuiBox mb={0.5}>
          <SuiInput
            inputSettungs={{ autoComplete: "off", required: true }}
            type="email"
            placeholder="Email ID"
            id="email"
            name="email"
            value={formik.values.email}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
          <ErrorMessageComponent touched={formik.touched.email} error={formik.errors.email} />
        </SuiBox>
      </SuiBox>
      <SuiBox mt={2} mb={2}>
        <h6
          style={{
            fontSize: ".9em",
            color: "#FC3C3C",
            fontWeight: 400,
            transition: ".2s all",
          }}
        >
          {formik.errors.mobile_no}
        </h6>
      </SuiBox>
      <Grid>
        <ErrorMessageComponent touched={error?.data?.message} error={error?.data?.message} />
      </Grid>
      <Grid></Grid>
      <SuiBox my={2}>
        <SuiButton
          tabIndex={2}
          type="submit"
          variant="contained"
          buttonColor="primary"
          disabled={formik.isSubmitting || !formik.dirty}
          fullWidth
          onClick={formik.handleSubmit}
        >
          {formik.isSubmitting ? (
            <CircularProgress size="25px" sx={{ color: "blue" }} />
          ) : (
            "Send Reset Link"
          )}
        </SuiButton>
      </SuiBox>

      <SuiTypography
        textAlign="center"
        component="p"
        pt={2}
        textColor="text2"
        variant="formSubHeading"
      >
        Back to
        <SuiTypography
          component={Link}
          to="/"
          pl={1}
          textColor="info"
          fontWeight="semiBold"
          variant="formSubHeading"
          sx={{ "&.MuiTypography-root": { textDecoration: "underline !important" } }}
        >
          Sign in
        </SuiTypography>
      </SuiTypography>
    </>
  );
};
export default EnterEmail;
