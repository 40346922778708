import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import axios from "axios";
import { useSelector } from "react-redux";

const addressAPIkey = "PCWGS-DL68L-MV3FP-WJ2KS";

const AutoCompleteSingleAddress = ({ formik, name, setValue, type }) => {
  const [countries, setCountries] = useState([]);
  const [addressText, setAddressText] = useState("");
  const timeout = useRef();
  const { country } = useSelector((state) => state.dashboard);
  const { checkCountry, customerInfo } = useSelector((state) => state.dashboard);
  const [selectedCountry, setSelectedCountry] = useState({
    summaryline: "",
    city: "",
    postcode: "",
    fullAddress: "",
  });

  useEffect(() => {
    const fetchAddress = async () => {
      axios
        .get(`https://ws.postcoder.com/pcw/${addressAPIkey}/address/${country}/${addressText}`)
        .then((response) => {
          if (
            Array.isArray(response.data) &&
            response.data.every((item) => item && item.summaryline)
          ) {
            setCountries(response.data);
          } else {
            console.error("Invalid response format. Expected an array with summaryline property.");
          }
        })
        .catch((error) => {
          console.error("Error fetching countries:", error);
        });
    };
    if (addressText) {
      const trimmedAddressText = addressText.trim();
      if (trimmedAddressText) {
        timeout.current = setTimeout(() => {
          fetchAddress();
        }, 1000);
      }
    }
    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  }, [addressText, country, type]);
  const handleChange = (event, newValue) => {
    if (newValue) {
      const updatedAddress = {
        summaryline: newValue.summaryline?.split(",")[0],
        summaryline2: newValue.summaryline?.split(",")[1],
        summaryline3: newValue.summaryline?.split(",")[2],
        summaryline4: newValue.summaryline?.split(",")[3],
        city: newValue.posttown,
        postcode: newValue.postcode,
        fullAddress: newValue.summaryline,
      };

      setSelectedCountry(updatedAddress);
      setValue(updatedAddress);

      formik.setValues((prevValues) => ({
        ...prevValues,
        cus_address_line_1: updatedAddress.summaryline,
        cus_address_line_2: updatedAddress.summaryline2,
        cus_address_line_3: updatedAddress.summaryline3,
        cus_address_line_4: updatedAddress.summaryline4,
        cus_postal_code: updatedAddress.postcode,
      }));
    } else {
      setSelectedCountry({ summaryline: "" });
      setValue("");

      formik.setValues((prevValues) => ({
        ...prevValues,
        cus_address_line_1: "",
      }));
    }
  };
  useEffect(() => {
    if (type === "editProfile") {
      setSelectedCountry({ summaryline: customerInfo?.cus_address_line_1 });
      formik.setFieldValue("cus_address_line_1", customerInfo?.cus_address_line_1);
      if (selectedCountry.summaryline?.length <= 0) {
        formik.setFieldValue("cus_address_line_1", customerInfo?.cus_address_line_1);

        formik.setFieldValue("cus_address_line_2", customerInfo?.cus_address_line_2);
        formik.setFieldValue("cus_address_line_3", customerInfo?.cus_address_line_3);
        formik.setFieldValue("cus_address_line_4", customerInfo?.cus_address_line_4);

        // formik.setFieldValue("cus_postal_code", customerInfo?.cus_postal_code);
        formik.setFieldValue("cus_city", customerInfo?.cus_city);
      }
      if (checkCountry === true) {
        setSelectedCountry({ summaryline: "" });
        formik.setFieldValue("cus_address_line_1", "");
        formik.setFieldValue("cus_address_line_2", "");
        formik.setFieldValue("cus_address_line_3", "");
        formik.setFieldValue("cus_address_line_4", "");

        formik.setFieldValue("cus_postal_code", "");
        formik.setFieldValue("cus_city", "");
        setAddressText("");
      }
      return () => {
        if (timeout.current) {
          window.clearTimeout(timeout.current);
        }
      };
    }
  }, [type, checkCountry]);

  const getOptionLabel = (option) => {
    if (selectedCountry && option.summaryline === selectedCountry.summaryline) {
      return selectedCountry.summaryline?.split(",")[0];
    } else {
      return option.summaryline;
    }
  };

  return (
    <Autocomplete
      fullWidth
      freeSolo
      popupIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: 12, fill: "#A0B5BE" }} />}
      sx={{
        "& .MuiOutlinedInput-root": {
          flexWrap: "nowrap",
          position: "relative",
          py: 0,
          borderRadius: "0.625rem",
          border: "none",
          bgcolor: "#FFF",
          "& > .MuiOutlinedInput-input": {
            border: 0,
            "& + .MuiCircularProgress-root": {
              position: "absolute",
              top: "11.5px",
              right: "35px",
            },
          },
        },
      }}
      name={name}
      value={selectedCountry}
      onChange={(event, newValue) => handleChange(event, newValue)}
      onInputChange={(e, value) => {
        setAddressText(value);
        formik.setFieldValue(name, value);
        formik.setFieldTouched(name, true);
      }}
      options={countries}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Company Address Line 1"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {params.InputProps.endAdornment}
                {React.cloneElement(params.InputProps.endAdornment, {
                  onClick: () => {
                    formik.setFieldValue("cus_postal_code", "");
                    formik.setFieldValue("cus_address_line_1", "");
                    formik.setFieldValue("cus_address_line_2", "");
                    formik.setFieldValue("cus_address_line_3", "");
                    formik.setFieldValue("cus_address_line_4", "");
                  },
                })}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutoCompleteSingleAddress;
