import React from "react";
import { Box, Typography } from "@mui/material";

const Nodata = ({ message = "- No Data Found -" }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      width={"100%"}
      height={"100%"}
      py={4}
    >
      <Typography variant="body2" fontSize={{ xs: 14, md: 15, xl: 16 }}>
        {message}
      </Typography>
    </Box>
  );
};

export default Nodata;
