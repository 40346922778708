import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import { capitalize } from "helper";
import StatusSelector from ".";
import { requestStatus } from "components/SuiDropDownList/dropdownArrays";
import { requestDIDStatus } from "components/SuiDropDownList/dropdownArrays";

const RequestStatusSelector = ({ id, currentState, onClick, type }) => {
  return (
    <SuiBox
      maxWidth={100}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      columnGap={1.2}
    >
      <SuiTypography fontSize={14}>{capitalize(currentState)}</SuiTypography>
      <StatusSelector
        id={id}
        selectData={type === "didStatus" ? requestDIDStatus : requestStatus}
        onStateChange={onClick}
      />
    </SuiBox>
  );
};

export default RequestStatusSelector;
