const NotificationIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 19 22" fill="none">
      <path
        d="M9.45195 0.631836C5.28316 0.631836 1.90852 4.06253 1.90852 8.26706V11.9266C0.878386 12.3806 0.0522461 13.2257 0.0522461 14.4152C0.0522461 16.0112 1.35571 17.3424 2.95293 17.3424H15.951C17.5482 17.3424 18.8517 16.0112 18.8517 14.4152C18.8517 13.2257 18.0255 12.3806 16.9954 11.9266V8.26706C16.9954 4.06253 13.6207 0.631836 9.45195 0.631836ZM14.9066 8.26706C14.9087 9.65413 14.9076 11.1166 14.8982 12.4773C14.8679 13.0739 15.3432 13.5743 15.877 13.5746C16.4108 13.5748 16.7627 13.9244 16.7627 14.4152C16.7627 14.906 16.4108 15.2536 15.9508 15.2536H2.95272C2.49269 15.2536 2.14086 14.906 2.14086 14.4152C2.14086 13.9244 2.49269 13.5748 3.02606 13.5745C3.55943 13.5743 4.03437 13.0752 4.0053 12.4794C3.98379 11.0975 3.99695 9.66759 3.99695 8.26706C3.99695 5.16772 6.41994 2.72066 9.45155 2.72066C12.4832 2.72066 14.9066 5.16772 14.9066 8.26706ZM7.36313 19.4313C7.08613 19.4313 6.82048 19.5413 6.62462 19.7372C6.42875 19.933 6.31872 20.1987 6.31872 20.4757C6.31872 20.7527 6.42875 21.0183 6.62462 21.2142C6.82048 21.41 7.08613 21.5201 7.36313 21.5201H11.5408C11.8178 21.5201 12.0834 21.41 12.2793 21.2142C12.4752 21.0183 12.5852 20.7527 12.5852 20.4757C12.5852 20.1987 12.4752 19.933 12.2793 19.7372C12.0834 19.5413 11.8178 19.4313 11.5408 19.4313H7.36313Z"
        fill="#2B2B2B"
      />
    </svg>
  );
};

export default NotificationIcon;
