import borders from "../../base/borders";
import colors from "../../base/colors";
import pxToRem from "../../functions/pxToRem";
import linearGradient from "../../functions/linearGradient";
import { svgColorCode } from "../../../../helper";

const { borderWidth } = borders;
const { transparent, primary, info, text } = colors;

export default {
  styleOverrides: {
    root: {
      backgroundPosition: "center",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      width: pxToRem(20),
      height: pxToRem(20),
      marginRight: pxToRem(6),
      padding: 0,
      color: transparent.main,
      border: `${borderWidth[2]} solid ${text.focus}`,
      borderRadius: "50%",
      transition: "all 250ms ease",
      "&.Mui-disabled": {
        opacity: "0.5",
      },
      "&:hover": {
        backgroundColor: transparent.main,
      },

      "& .MuiSvgIcon-root": {
        fill: transparent.main,
      },

      "&.Mui-focusVisible": {
        border: `${borderWidth[2]} solid ${info.main}`,
      },
      "&.Mui-checked + .MuiTypography-root": {
        color: text.main,
      },
    },

    colorPrimary: {
      backgroundColor: transparent.main,

      "&.Mui-checked": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='4.5' fill='${svgColorCode(
          primary.main
        )}' /%3E%3C/svg%3E"), ${linearGradient(transparent.main, transparent.main)}`,
        borderColor: primary.main,
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },
      "&.Mui-focusVisible": {
        borderColor: primary.main,
      },
    },

    colorSecondary: {
      backgroundColor: transparent.main,

      "&.Mui-checked": {
        backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='15px' width='15px'%3E%3Ccircle cx='50%' cy='50%' r='6' fill='%239EE85D' /%3E%3C/svg%3E"), ${linearGradient(
          transparent.main,
          transparent.main
        )}`,
        borderColor: "#97AFB8",
      },

      "&:hover": {
        backgroundColor: transparent.main,
      },
    },
  },
};
