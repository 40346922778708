import colors from "../../base/colors";
import pxToRem from "../../functions/pxToRem";

const { white } = colors;

export default {
  styleOverrides: {
    root: {
      position: "relative",
      backgroundColor: white,
      minHeight: "unset",
      padding: pxToRem(0),
      "& .MuiTabScrollButton-root.Mui-disabled": {
        display: "none",
      },
      "& .MuiTabs-scroller": {
        padding: "0 2px",
      },
    },

    flexContainer: {
      height: "100%",
      gap: 0,
      width: "max-content",
      minWidth: "100%",
      border: 0,
      borderRadius: "0.7rem",
      "&.MuiTabs-flexContainerVertical": {
        "&> .MuiTab-root": {
          justifyContent: "start",
        },
      },
    },

    indicator: {
      height: "0",
      display: "none",
    },
  },
};
