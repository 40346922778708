import React, { useEffect, useState } from "react";
import { Card, Grid, FormLabel } from "@mui/material";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiTypography from "components/SuiTypography";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import ErrorMessageComponent from "components/FormikErrorMessage";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import { addSuppplierSchema } from "Validation";
import { useAddSupplierMutation } from "app/features/api/AuthApi";
import SuiDropDownList from "components/SuiDropDownList";
import { useGetSupplierByIdMutation } from "app/features/api/AuthApi";
import { useUpdateSupplierMutation } from "app/features/api/AuthApi";

const AddSupplier = () => {
  const id = localStorage.getItem("supplier_id");
  const [error, setError] = useState("");
  const [addSupplier] = useAddSupplierMutation();
  const [SupplierById] = useGetSupplierByIdMutation();
  const suiSnackbar = useSuiSnackbar();
  const [updateSupplier] = useUpdateSupplierMutation();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      did_supplier_name: "",
      did_supplier_status: "1",
    },
    validationSchema: addSuppplierSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      const body = {
        did_supplier_name: values.did_supplier_name,
        did_supplier_status: values.did_supplier_status,
        id,
      };
      try {
        console.log("edit id is::", id, body);
        if (id) {
          let response = await updateSupplier(body).unwrap();
          if (response.status === 200) {
            suiSnackbar(response.message, "success");
            navigate("/supplier");
            setError("");
          }
        } else {
          // Add new role if no ID is present
          let response = await addSupplier(body).unwrap();
          console.log(response);
          if (response.status === 200) {
            formik.resetForm();
            suiSnackbar(response.message, "success");
            navigate("/supplier");
            setError("");
            console.log(error);
          }
        }
      } catch (error) {
        if (error?.data?.errorMessages?.fieldErrors) {
          const fieldErrors = error.data.errorMessages.fieldErrors;
          for (const field in fieldErrors) {
            if (Object.hasOwn(fieldErrors, field)) {
              fieldErrors[field].forEach((errorMessage) => {
                console.error(`Error in ${field}: ${errorMessage}`);
                suiSnackbar(errorMessage || "An error occurred", "error");
              });
            }
          }
        } else {
          suiSnackbar(error?.data?.message || "An error occurred", "error");
        }
      }
    },
  });
  const getSupplierDetails = async () => {
    try {
      const response = await SupplierById(id).unwrap();
      console.log(response.data);
      const { did_supplier_name, did_supplier_status } = response.data;
      formik.setValues({
        did_supplier_name,
        did_supplier_status,
      });
    } catch (error) {
      console.error("Error fetching role:", error);
    }
  };

  useEffect(() => {
    if (id) {
      getSupplierDetails();
    }
  }, [id]);

  return (
    <>
      <Card sx={{ my: 3, p: 2 }}>
        <Grid
          component={"form"}
          container
          spacing={2}
          rowSpacing={3}
          onSubmit={formik.handleSubmit}
        >
          <Grid item xs={12}>
            <SuiTypography fontWeight="semiBold" fontSize={16}>
              {id ? "Edit Supplier" : "Add Supplier"}
            </SuiTypography>
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="role_name" required>
              Supplier Name
            </FormLabel>
            <SuiInput
              inputSettungs={{ autoComplete: "off", required: true }}
              type="text"
              placeholder="Enter Supplier Name"
              id="did_supplier_name"
              name="did_supplier_name"
              value={formik.values.did_supplier_name}
              onChange={formik.handleChange}
            />
            <ErrorMessageComponent
              touched={formik.touched.did_supplier_name}
              error={formik.errors.did_supplier_name}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <FormLabel htmlFor="Status" required>
              Status
            </FormLabel>
            <SuiDropDownList
              data={[
                { id: "1", label: "Active" },
                { id: "0", label: "Inactive" },
              ]}
              select={{
                key: "id",
                valueKey: "id",
                labelName: "label",
                label: "Status",
                name: "did_supplier_status",
                val: formik.values.did_supplier_status,
                fn: formik.handleChange,
              }}
            />
            <ErrorMessageComponent
              touched={formik.touched.did_supplier_status}
              error={formik.errors.did_supplier_status}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container pb={2} pt={4} columnSpacing={2} rowSpacing={1}>
              <Grid item>
                <SuiButton
                  size="small"
                  component={Link}
                  to="/supplier"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                >
                  Cancel
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem" }}
                  disabled={!formik.dirty}
                >
                  {id ? "Update" : "Add"}
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

export default AddSupplier;
