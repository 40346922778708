import { baseAPIService } from ".";

const CustomerApi = baseAPIService.injectEndpoints({
  endpoints: (build) => ({
    updateCustomerStatus: build.mutation({
      query: (body) => {
        return {
          url: `admin/customer/status/${body.id}/${body.status}`,
          method: "PATCH",
        };
      },
    }),
    customerBulkDiscount: build.mutation({
      query: (body) => {
        return {
          url: `admin/customer/discount`,
          method: "POST",
          body: body,
        };
      },
    }),
  }),
  overrideExisting: true,
});
export const { useUpdateCustomerStatusMutation, useCustomerBulkDiscountMutation } = CustomerApi;
