export const matchPermissions = (apiPermissions, requiredPermissions) => {
  const matchedPermissions = {};

  Object.keys(requiredPermissions).forEach((key) => {
    matchedPermissions[key] = {};
    Object.keys(requiredPermissions[key]).forEach((action) => {
      matchedPermissions[key][action] = apiPermissions?.includes(requiredPermissions[key][action]);
    });
  });

  return matchedPermissions;
};
