import { Grid, TableCell, TableRow, Card, IconButton, MenuItem, Select } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiButton from "components/SuiButton";
import SuiInput from "components/SuiInput";
import SuiSearchBox from "components/SuiSearchBox";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import TableToolbar from "components/SuiTable/TableToolbar";
import { useFormik } from "formik";
import useNavigateSearch from "hooks/useNavigateSearch";
import { pagesTableHeader } from "layouts/DID/data";
import EditIcon from "Container/Icons/EditIcon";
import { Link } from "react-router-dom";
import { useTermsConditionsMutation } from "app/features/api/AuthApi";
import { useEffect, useState } from "react";
import { useGetPagesByIdMutation } from "app/features/api/AuthApi";
import { matchPermissions } from "Permissions/commonPermissionFunc";
import { useSelector } from "react-redux";
import { Permission } from "Permissions/Permission";
import CommonPagination from "components/SuiTablePagination/CommonPagination";

const Terms = () => {
  const [termsConditions, { isLoading: termsLoading }] = useTermsConditionsMutation();
  const [pageData, setPageData] = useState("");
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [getPagesById] = useGetPagesByIdMutation();
  let page = new URLSearchParams(window.location.search).get("page");
  if (page === null) {
    page = 1;
  }
  const navigateSearch = useNavigateSearch();
  const formik = useFormik({
    initialValues: {
      page_name: "",
      page_slug: "",
    },
    enableReinitialize: true,
    onSubmit: () => {
      getTermsDetails(perPage, currentPage);
    },
  });
  const getPages = async (id) => {
    try {
      const response = await getPagesById(id).unwrap();
      if (response.status === 200) {
        console.log(response.data);
        // setCount(response.data.count);
      }
    } catch (error) {
      console.log(error);
      // setError(error?.data.message);
    }
  };

  const getTermsDetails = async (perPage, currentPage) => {
    try {
      let urlParamss = {
        results_per_page: perPage,
        page: currentPage,
      };
      if (formik.values.page_name) {
        urlParamss.page_name = formik.values.page_name;
      }
      const response = await termsConditions(urlParamss).unwrap();
      if (response.status === 200) {
        setPageData(response.data.rows);
        setCount(response.data.count);
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
      // setError(error?.data.message);
    }
  };
  const handleReset = async (pageNo) => {
    try {
      let urlParamss = {
        results_per_page: 10,
        page: pageNo,
      };
      const response = await termsConditions(urlParamss).unwrap();
      if (response.status === 200) {
        setPageData(response.data.rows);
        setCount(response.data.count);
      }
    } catch (error) {
      console.log(error);
      alert(error.message);
      // setError(error?.data.message);
    }
  };
  const [permissionInfo, setPermissionInfo] = useState();
  const { permissions } = useSelector((state) => state.auth);
  const handlePerPageChange = (event) => {
    setPerPage(event.target.value);
    setCurrentPage(1);
    navigateSearch("/pages", { page: 1 });
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    navigateSearch("/pages", { page: page });
  };
  useEffect(() => {
    const userPermissions = matchPermissions(permissions, Permission);
    setPermissionInfo(userPermissions);
  }, []);
  useEffect(() => {
    getTermsDetails(perPage, currentPage);
  }, [perPage, currentPage]);
  const options = [
    { value: 10, label: "10 per page" },
    { value: 20, label: "20 per page" },
    { value: 50, label: "50 per page" },
    { value: 100, label: "100 per page" },
  ];
  const isActions = permissionInfo?.sites_pages?.UPDATE
    ? {
        id: "11",
        align: "right",
        width: "auto",
        label: "ACTION",
      }
    : null;
  const rows =
    pageData && Array.isArray(pageData)
      ? pageData.map((item, i) => {
          const labelId = `item.cus_id_${i}`;

          return (
            <>
              <TableRow key={labelId}>
                <TableCell>{item.page_name}</TableCell>
                {permissionInfo?.sites_pages?.UPDATE ? (
                  <TableCell align="right">
                    <IconButton
                      onClick={() => getPages(item.page_id)}
                      component={Link}
                      to={`edit-pages/${item.page_id}`}
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                ) : null}
              </TableRow>
            </>
          );
        })
      : [];
  return (
    <>
      <SuiBox my={3}>
        <SuiSearchBox title="Search">
          <SuiBox mx={1}>
            <Grid container component="form" spacing={2}>
              <Grid item xs={12} md={6} lg={3} xl={4} xxl={3}>
                <SuiInput
                  inputSettungs={{ autoComplete: "off" }}
                  type="tel"
                  placeholder="Page Name"
                  id="page_name"
                  name="page_name"
                  value={formik.values.page_name}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <SuiButton
                  type="submit"
                  size="small"
                  buttonColor="primary"
                  sx={{ px: "1rem", marginRight: "14px" }}
                  onClick={formik.handleSubmit}
                  disabled={!formik.dirty}
                >
                  Search
                </SuiButton>
                <SuiButton
                  type="reset"
                  size="small"
                  variant="outlined"
                  buttonColor="dark"
                  sx={{ px: "1rem", py: "0.4rem" }}
                  onClick={() => {
                    formik.handleReset();
                    handleReset(1);
                    // getCustomerOnload(1);
                  }}
                >
                  Reset
                </SuiButton>
              </Grid>
            </Grid>
          </SuiBox>
        </SuiSearchBox>
      </SuiBox>
      <SuiBox my={3}>
        <Card sx={{ width: "100%" }}>
          <TableToolbar title={"List of Sites & pages "}>
            {/* <SuiButton
              component={Link}
              size="small"
              buttonColor="info"
              sx={{ fontWeight: "500", px: "12px", ml: 2 }}
              to={"new-pages"}
            >
              Add New Page
            </SuiButton> */}
          </TableToolbar>

          <EnhancedTable
            tableMinWidth={540}
            tablehead={isActions ? [...pagesTableHeader, isActions] : [...pagesTableHeader]}
            rowsData={rows}
            isLoading={termsLoading}
          />
          {!termsLoading && pageData.length > 0 && (
            <Grid
              container
              py={3}
              spacing={1.5}
              flexDirection={{ xs: "column", md: "row" }}
              justifyContent={{ xs: "center", md: "flex-end" }}
              alignItems="center"
            >
              <Grid item>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    <span>Show</span>
                  </Grid>
                  <Grid item>
                    <Select
                      value={perPage}
                      onChange={handlePerPageChange}
                      variant="standard"
                      sx={{ minWidth: 100 }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item>
                    <span>out of {count} Results</span>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <CommonPagination
                  mt={0}
                  totalData={count}
                  pageSize={perPage}
                  currentPage={Number(currentPage)}
                  onPageChange={handlePageChange}
                />
              </Grid>
            </Grid>
          )}
        </Card>
      </SuiBox>
    </>
  );
};

export default Terms;
