import { makeStyles } from "@mui/styles";

export default makeStyles(() => ({
  loading_text: {
    position: "absolute",
    width: "4rem",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      content: "'Loading'",
      fontWeight: "bold",
      animationName: "$loadingText",
      animationDuration: "2s",
      animationIterationCount: "infinite",
    },
  },
  "@keyframes loadingText": {
    "0%": {
      content: "'Loading'",
    },
    "25%": {
      content: "'Loading.'",
    },
    "50%": {
      content: "'Loading..'",
    },
    "75%": {
      content: "'Loading...'",
    },
  },
}));
