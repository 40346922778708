import React from "react";
const ErrorMessageComponent = ({ touched, error }) => {
  return (
    <h6
      style={{
        fontSize: ".9em",
        color: "#FC3C3C",
        fontWeight: 400,
        transition: ".2s all",
      }}
    >
      {touched && error ? error : ""}
    </h6>
  );
};

export default ErrorMessageComponent;
