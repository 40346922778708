/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { Box, Card, CircularProgress, Grid, TableCell, TableRow, Typography } from "@mui/material";
import SuiBox from "components/SuiBox";
import SuiTypography from "components/SuiTypography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import EmailIcon from "../../assets/images/email.png";
import CallIcon from "../../assets/images/call.png";
import TableToolbar from "components/SuiTable/TableToolbar";
import EnhancedTable from "components/SuiTable/EnhancedTable";
import { useViewCustomerDetailsByIDMutation } from "app/features/api/AuthApi";
import { formatDate } from "helper";
import Nodata from "layouts/customer/components/Nodata";
import { useNavigate } from "react-router-dom";
// import CommonSpinner from "components/CommonSpinner";

const ThreeCXView = () => {
  const pagesTableHeader = [
    { id: "1", align: "left", width: "auto", label: "Transaction ID" },
    { id: "2", align: "left", width: "auto", label: "Payment Date" },
    { id: "3", align: "left", width: "auto", label: "Payment Amount" },
    { id: "4", align: "left", width: "auto", label: "Payment Staus" },
  ];
  const [viewCustomerDetailsByID, { isLoading: isLoading }] = useViewCustomerDetailsByIDMutation();
  const id = localStorage.getItem("three_id");
  const [requestData, setRequestData] = useState("");
  const ThreeCXDetails = async () => {
    try {
      const resposne = await viewCustomerDetailsByID(id).unwrap();
      setRequestData(resposne.data);
      // setCount(resposne.data.count);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    ThreeCXDetails();
  }, []);
  const Val = [
    {
      transcation:
        requestData?.three_cx_orders?.payments !== null
          ? requestData?.three_cx_orders?.payments.payment_transaction_id
          : "-",
      paymentDate:
        requestData?.three_cx_orders?.payments !== null
          ? requestData?.three_cx_orders?.payments.createdAt
          : "-",
      paymentPrice:
        requestData?.three_cx_orders?.payments !== null ? requestData?.license_price : "-",
      paymentStatus:
        requestData?.three_cx_orders?.payments !== null
          ? requestData?.three_cx_orders?.payments?.payment_status
          : "-",
      paymentAmount:
        requestData?.three_cx_orders?.payments !== null
          ? requestData?.three_cx_orders?.payments?.payment_amount
          : "-",
    },
  ];
  const rows =
    Val &&
    Val.map((item) =>
      item.paymentStatus === "Success" ? (
        <TableRow key={item.id}>
          <TableCell>{`${item.transcation}`}</TableCell>
          <TableCell>{formatDate(item.paymentDate)}</TableCell>
          <TableCell>{`${item.paymentAmount}`}</TableCell>
          <TableCell>
            {`${item.paymentStatus}` === "Success" ? (
              <SuiTypography fontSize={14} sx={{ color: "#2DC573 !important" }}>
                Completed
              </SuiTypography>
            ) : `${item.paymentStatus}` === "Pending" ? (
              <SuiTypography fontSize={14} sx={{ color: "#848484 !important" }}>
                Pending
              </SuiTypography>
            ) : `${item.paymentStatus}` === "Failed" ? (
              <SuiTypography fontSize={14} sx={{ color: "#C02B0A !important" }}>
                Failed
              </SuiTypography>
            ) : (
              "-"
            )}
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell colSpan={4}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              py={4}
              sx={{
                width: "100%",
              }}
            >
              <Typography variant="body2" fontSize={{ xs: 14, md: 15, xl: 16 }}>
                - No Data Found -
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      )
    );
  const navigate = useNavigate();
  const testHandle = () => {
    navigate("/3cx-details");
  };
  const clors = requestData?.three_cx_licenses?.license_css_class?.split(" ")[1];
  return (
    <>
      {requestData == null ? (
        <Card sx={{ width: "100%", p: "15px 30px" }}>
          <Nodata />
        </Card>
      ) : (
        <SuiBox my={3}>
          <Card sx={{ width: "100%", p: "15px 30px" }}>
            {isLoading ? (
              <CircularProgress size="60px" color="primary" />
            ) : (
              <>
                <SuiTypography sx={{ fontSize: "18px", color: "#2B2B2B", fontWeight: "600" }}>
                  <ArrowBackIcon
                    onClick={testHandle}
                    sx={{
                      color: "#55DED0",
                      fontSize: "20px",
                      verticalAlign: "middle",
                      cursor: "pointer", // Use 'pointer' to indicate clickable
                    }}
                  />{" "}
                  View 3CX Details
                </SuiTypography>
                <SuiTypography
                  varient="h2"
                  sx={{ mt: 2, fontSize: "28px", color: "#2B2B2B", fontWeight: "600" }}
                >
                  {requestData?.customers?.cus_firstname} {requestData?.customers?.cus_lastname}
                </SuiTypography>
                <SuiTypography varient="p"> {requestData?.id}</SuiTypography>
                <SuiTypography>
                  {requestData?.customers && (
                    <>
                      ({requestData.customers.cus_account_number}){" "}
                      {requestData.customers.cus_company_name}
                    </>
                  )}
                </SuiTypography>
                <SuiTypography
                  sx={{ display: "flex", alignItems: "center", gap: "10px", fontSize: "16px" }}
                >
                  <img src={EmailIcon} alt="ICON" width="15" />
                  {requestData?.customers?.cus_email} | <img src={CallIcon} alt="ICON" width="15" />{" "}
                  {requestData?.customers?.cus_phone_number}
                </SuiTypography>
                <SuiTypography varient="h2" sx={{ mt: 3, mb: 1, fontWeight: "600" }}>
                  Licence Details
                </SuiTypography>
                <Grid container spacing={2} mb={1} alignItems="center">
                  <Grid item xs={6} md={3} lg={3}>
                    <SuiTypography sx={{ color: "#848484 !important", fontSize: "16px" }}>
                      Licence Edition:
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <div className={`${clors} gradient-box`}>
                      {requestData?.three_cx_licenses?.license_name}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={1} alignItems="center">
                  <Grid item xs={6} md={3} lg={3}>
                    <SuiTypography sx={{ color: "#848484 !important", fontSize: "16px" }}>
                      Licence Key:
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <SuiTypography sx={{ color: "#2B2B2B", fontSize: "16px" }}>
                      {requestData?.customer_license_code
                        ? requestData?.customer_license_code
                        : "-"}
                    </SuiTypography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} mb={1} alignItems="center">
                  <Grid item xs={6} md={3} lg={3}>
                    <SuiTypography sx={{ color: "#848484 !important", fontSize: "16px" }}>
                      Simultaneous calls:
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={6} md={3} lg={3}>
                    <SuiTypography sx={{ color: "#2B2B2B", fontSize: "16px" }}>
                      {requestData?.customer_licenses_simultaneous_calls}
                    </SuiTypography>
                  </Grid>
                </Grid>
              </>
            )}

            <Grid container spacing={2} mb={1} mt={2}>
              <Grid item xs={12} md={10} lg={8}>
                <Card
                  sx={{
                    border: "1px solid #E9E9E9",
                    boxShadow: "0px 6px 14px 0px #EDEDED !important",
                  }}
                >
                  <TableToolbar title="Payment Details" />
                  {/* {requestData?.three_cx_orders &&
                  requestData?.three_cx_orders?.payments !== null ? ( */}
                  <EnhancedTable
                    tableMinWidth={540}
                    tablehead={pagesTableHeader}
                    isLoading={isLoading}
                    rowsData={rows}
                  />
                  {/* ) : (
                    <Nodata />
                  )} */}
                </Card>
              </Grid>
            </Grid>
          </Card>
        </SuiBox>
      )}
    </>
  );
};

export default ThreeCXView;
