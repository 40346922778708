import { CircularProgress } from "@mui/material";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";

import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSelector } from "react-redux";
import { useDayOfPlanCategoryMutation } from "app/features/api/AuthApi";

const DayOfPlanSubCatory = ({ isLoading, name, setValue, type }) => {
  const [dayOfPlanCategory] = useDayOfPlanCategoryMutation();

  const [subCategory, setSubCategory] = useState({
    id: "",
    name: "",
  });
  const [CategoryData, setCategoryData] = useState([]);

  const timeout = useRef();
  const { dayOfplans, dayOfplanCatorys, customerInfo, checkTimeOfday } = useSelector(
    (state) => state.dashboard
  );

  const fetchCategory = async () => {
    try {
      const response = await dayOfPlanCategory(checkTimeOfday?.id).unwrap();
      setCategoryData(response.data);
    } catch (error) {
      console.log(error);
      setCategoryData([]);
    }
  };
  useEffect(() => {
    if (checkTimeOfday?.id) {
      fetchCategory();
    }
  }, [dayOfplans, checkTimeOfday]);
  useEffect(() => {
    if (type == "editProfile") {
      if (customerInfo.cus_time_of_day_category != checkTimeOfday?.id) {
        setSubCategory({ id: "", name: "" });
      } else {
        setSubCategory({ id: dayOfplanCatorys?.id, name: dayOfplanCatorys?.name });
      }
    }
  }, [checkTimeOfday]);
  useEffect(() => {
    if (type == "editProfile") {
      if (dayOfplans?.id == null && dayOfplans?.name == undefined) {
        setSubCategory({ id: "", name: "" });
      } else {
        setSubCategory({ id: dayOfplanCatorys?.id, name: dayOfplanCatorys?.name });
      }
    } else {
      setSubCategory({ id: "", name: "" });
    }
    // if (formik && formik.values) {
    //   if (subCategory.name?.length <= 0) {
    //     formik.setFieldValue("cus_time_of_day_sub_category", dayOfplanCatorys?.id);
    //   }
    // }
    return () => {
      if (timeout.current) {
        window.clearTimeout(timeout.current);
      }
    };
  }, [dayOfplans]);

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      //   setTImeOfDayCategory(newValue);
      setSubCategory(newValue);
      setValue(newValue.id);
    } else {
      //   setTImeOfDayCategory({
      //     id: "",
      //     name: "",
      //   });
      setSubCategory({
        id: "",
        name: "",
      });
      setValue("");
    }
  };

  return (
    <Autocomplete
      fullWidth
      popupIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: 12, fill: "#A0B5BE" }} />}
      sx={{
        "& .MuiOutlinedInput-root": {
          flexWrap: "nowrap",
          position: "relative",
          py: 0,
          borderRadius: "0.625rem",
          border: "none",
          bgcolor: isLoading ? "#E5EBED !important" : "#FFF",
          "&> .MuiOutlinedInput-input": {
            border: 0,
            "&+ .MuiCircularProgress-root": {
              position: "absolute",
              top: "11.5px",
              right: "35px",
            },
          },
        },
      }}
      name={name}
      value={subCategory}
      onChange={handleAutocompleteChange}
      options={CategoryData}
      getOptionLabel={(option) => option?.name}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Time of Day"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="info" size={18} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default DayOfPlanSubCatory;
