import pxToRem from "assets/theme/functions/pxToRem";
import colors from "assets/theme/base/colors";

const AlertIcon = ({ size = 17, color = "errro", type = "main" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={pxToRem(size)}
      fill={colors[color][type]}
      viewBox="0 0 20 20"
    >
      <path d="M18.7999 13.5834L12.4083 2.9834C12.1498 2.57908 11.7937 2.24634 11.3728 2.01585C10.9519 1.78536 10.4798 1.66455 9.99992 1.66455C9.52005 1.66455 9.04791 1.78536 8.62702 2.01585C8.20613 2.24634 7.85004 2.57908 7.59159 2.9834L1.19992 13.5834C0.974132 13.9598 0.851411 14.389 0.844097 14.8278C0.836783 15.2667 0.945133 15.6997 1.15825 16.0834C1.40465 16.5153 1.7613 16.874 2.19176 17.1229C2.62221 17.3718 3.11103 17.502 3.60825 17.5001H16.3916C16.8855 17.5053 17.3722 17.3802 17.8023 17.1373C18.2325 16.8945 18.591 16.5424 18.8416 16.1167C19.061 15.7291 19.1728 15.2898 19.1655 14.8444C19.1581 14.399 19.0319 13.9637 18.7999 13.5834ZM17.3833 15.2917C17.2831 15.4639 17.1378 15.6054 16.963 15.7009C16.7882 15.7963 16.5906 15.8422 16.3916 15.8334H3.60825C3.40927 15.8422 3.21168 15.7963 3.03689 15.7009C2.86209 15.6054 2.71674 15.4639 2.61659 15.2917C2.54345 15.1651 2.50494 15.0214 2.50494 14.8751C2.50494 14.7288 2.54345 14.5851 2.61659 14.4584L9.01659 3.85007C9.13159 3.69816 9.28023 3.57496 9.45084 3.49013C9.62144 3.40531 9.80939 3.36116 9.99992 3.36116C10.1905 3.36116 10.3784 3.40531 10.549 3.49013C10.7196 3.57496 10.8683 3.69816 10.9833 3.85007L17.3749 14.4501C17.4509 14.577 17.4917 14.7219 17.4932 14.8698C17.4946 15.0177 17.4567 15.1633 17.3833 15.2917Z" />
      <path d="M10.0003 14.1667C10.4606 14.1667 10.8337 13.7936 10.8337 13.3333C10.8337 12.8731 10.4606 12.5 10.0003 12.5C9.54009 12.5 9.16699 12.8731 9.16699 13.3333C9.16699 13.7936 9.54009 14.1667 10.0003 14.1667Z" />
      <path d="M10.0003 6.66675C9.77931 6.66675 9.56735 6.75455 9.41107 6.91083C9.25479 7.06711 9.16699 7.27907 9.16699 7.50008V10.8334C9.16699 11.0544 9.25479 11.2664 9.41107 11.4227C9.56735 11.579 9.77931 11.6667 10.0003 11.6667C10.2213 11.6667 10.4333 11.579 10.5896 11.4227C10.7459 11.2664 10.8337 11.0544 10.8337 10.8334V7.50008C10.8337 7.27907 10.7459 7.06711 10.5896 6.91083C10.4333 6.75455 10.2213 6.66675 10.0003 6.66675Z" />
    </svg>
  );
};

export default AlertIcon;
