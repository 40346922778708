import { Box, styled } from "@mui/material";
import CalendarIcon from "Container/Icons/CalendarIcon";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
const DateRangeStyled = styled(Box)(({ fullWidth }) => {
  return {
    "&> .rs-picker-toggle-wrapper": {
      display: `${fullWidth ? "block" : "inline-block"} !important`,
    },
    "& .rs-picker-disabled": {
      opacity: 0.7,
    },
    "& .rs-picker-toggle": {
      width: fullWidth ? "100%" : 265,
      backgroundColor: "#F2F4FF",
    },
    "& .rs-stack-item > .rs-picker-toggle-caret": {
      position: "relative",
      top: "unset !important",
      right: "unset !important",
      width: "28px !important",
      height: "28px !important",
      "& svg": {
        height: 16,
      },
    },
    [`& .rs-picker-daterange .rs-picker-toggle.rs-btn, 
    &.rs-picker-has-value.rs-picker-cleanable .rs-picker-toggle.rs-btn`]: {
      paddingRight: "11px",
    },
    "& .rs-picker-daterange .rs-picker-toggle.rs-btn": {
      backgroundColor: "#F2F4FF !important",
      borderColor: "#F2F4FF !important",
      boxShadow: "none",
    },
    [`& .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover, 
    & .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover, 
    &.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle-active`]: {
      backgroundColor: "#FFF !important",
      borderColor: "#151515 !important",
      boxShadow: "none",
    },
    [`& .rs-picker-has-value .rs-btn .rs-picker-toggle-value, 
    & .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value`]: {
      color: "#151515",
    },
    "& .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean": {
      width: 28,
      height: 28,
      background: "#fff",
      borderRadius: "50%",
      boxShadow: "1px 2px 0 0 #D9DBE7",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      position: "relative",
      top: 0,
      left: 22,
    },
  };
});

const DateRangePickerIcon = (props) => {
  return (
    <Box
      {...props}
      bgcolor="light.main"
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius="50%"
      width={35}
      height={35}
      boxShadow="1px 2px 0 0 #D9DBE7"
      sx={{ cursor: "pointer" }}
    >
      <CalendarIcon />
    </Box>
  );
};

const SuiDateRangePicker = ({
  disabled,
  onDateChange,
  fullWidth = false,
  placeholder = "Date Range",
  value,
}) => {
  return (
    <DateRangeStyled fullWidth={fullWidth}>
      <DateRangePicker
        disabled={disabled}
        caretAs={(props) => <DateRangePickerIcon {...props} />}
        character=" to "
        Placement="auto"
        format="dd/MM/yyyy"
        ranges={[]}
        placeholder={placeholder}
        showOneCalendar
        value={value}
        onChange={(value) => onDateChange(value)}
        // value
      />
    </DateRangeStyled>
  );
};

export default SuiDateRangePicker;
