import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import SuiBox from "components/SuiBox";

import styles from "Container/Sidenav/styles/sidenavLink";
import { Icon } from "@mui/material";

function SidenavLink({ icon, to, name, active, ...rest }) {
  const controller = useSelector((store) => store.uiControl);
  const { miniSidenav, transparentSidenav, sidenavColor } = controller;
  const classes = styles({
    active,
    miniSidenav,
    transparentSidenav,
    sidenavColor,
  });
  return (
    <SuiBox
      component={Link}
      to={to}
      {...rest}
      customClass={classes.collapse_item}
      alignItems="center"
    >
      {icon ? (
        <ListItemIcon className={classes.collapse_iconBox}>
          {typeof icon === "string" ? <Icon className={classes.collapse_icon}>{icon}</Icon> : icon}
        </ListItemIcon>
      ) : (
        <svg
          width="12"
          height="12"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="6.90332" cy="6.55127" r="5" fill="white" stroke="#55DED0" strokeWidth="3" />
        </svg>
      )}

      <ListItemText primary={name} classes={{ root: classes.collapse_text }} />
    </SuiBox>
  );
}

SidenavLink.defaultProps = {
  active: false,
};

SidenavLink.propTypes = {
  icon: PropTypes.any,
  to: PropTypes.any,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
};

export default SidenavLink;
