import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  AssignNum: [],
};

export const AssignSlice = createSlice({
  initialState,
  name: "assign",
  reducers: {
    setAssignNumber: (state, action) => {
      const data = action.payload;
      state.AssignNum = data;
    },
  },
});

export const { setAssignNumber } = AssignSlice.actions;

export default AssignSlice.reducer;
