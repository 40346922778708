import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { makeStyles } from "@mui/styles";
import colors from "../../assets/theme/base/colors";
import { ArrowUpward, ArrowDownward } from "@mui/icons-material";

const styles = makeStyles(() => ({
  tableContainer: {
    borderRadius: 0,
    boxShadow: "none",
    background: "transparent",
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#88888899",
      opacity: 0.5,
      borderRadius: "10px",
      "&:hover": {
        background: "#555555aa",
        opacity: 1,
      },
    },
    "&> table th": {
      fontSize: 14,
      fontWeight: 500,
      borderTop: "0.0625rem solid #D3E0E5",
      position: "relative",
      cursor: "pointer",
      textTransform: "uppercase",
    },
    "&> table td": {
      fontSize: 14,
      fontWeight: 400,
      color: colors.text.focus,
    },
    "&> table tr:last-child td": {
      border: 0,
    },
    arrowIcon: {
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      right: "8px",
    },
  },
}));

export default function BaseTableUi({
  tableHeadData,
  children,
  minWidth = 650,
  sortColumn,
  sortOrder,
  tableType,
  onSort,
}) {
  const classes = styles();
  const headId = React.useId();

  const handleSort = (title) => {
    onSort(title);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table sx={{ minWidth: minWidth, width: "100%" }}>
        <TableHead>
          <TableRow>
            {tableHeadData.map(
              ({ title, align = "left", width = "auto", maxWidth = "100%" }, i) => (
                <TableCell
                  component="th"
                  key={`${headId}_${i}`}
                  width={width}
                  sx={{ maxWidth: maxWidth }}
                  align={align}
                  {...(title === "Amount" || title === "Duration (S)"
                    ? { onClick: () => handleSort(title) }
                    : {})}
                >
                  {title}
                  {/* Arrow icons */}
                  {(title === "Amount" || title === "Duration (S)") && tableType == "cdr" && (
                    <>
                      <ArrowUpward
                        className={classes.arrowIcon}
                        style={{
                          color: sortColumn === title && sortOrder === "asc" ? "black" : "#ccc",
                        }}
                      />
                      <ArrowDownward
                        className={classes.arrowIcon}
                        style={{
                          color: sortColumn === title && sortOrder === "desc" ? "black" : "#ccc",
                        }}
                      />
                    </>
                  )}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}
