import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { useGetCustomerAccountNoMutation } from "app/features/api/AuthApi";
import { setSelectedAccountNoValue } from "app/features/DashboardSlice";
import { useSelector } from "react-redux";

const CustomerNo = ({ formik, placeholder, onBlur, type }) => {
  const [AccountNo, setCustomerAccountNo] = useState([]);
  const [getCustomerAccountNo] = useGetCustomerAccountNoMutation();
  const [selectedAccountNo, setSelectedAccountNo] = useState(null);
  const { AccountNoValue } = useSelector((state) => state.dashboard);
  const fetchAccountNo = async () => {
    try {
      const response = await getCustomerAccountNo().unwrap();
      setCustomerAccountNo(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (formik.values.customer_account_num === "") {
      setSelectedAccountNo(null);
    }
    if (formik.values.customer_acct_number === "") {
      setSelectedAccountNo(null);
    }
    if (formik.values.cus_account_number === "") {
      setSelectedAccountNo(null);
    }
  }, [formik.values]);
  useEffect(() => {
    fetchAccountNo();
  }, [AccountNoValue]);

  const handleAccountNoChange = (event, value) => {
    if (value) {
      setSelectedAccountNoValue(value.cus_account_number);
      setSelectedAccountNo(value);
      if (type == "assignTable") {
        formik.setFieldValue("customer_account_num", value.cus_account_number);
      }
      if (type == "requestTable") {
        formik.setFieldValue("customer_acct_number", value.cus_account_number);
      }
      if (type == "customerTable") {
        formik.setFieldValue("cus_account_number", value.cus_account_number);
      }
    } else {
      setSelectedAccountNoValue("");
      setSelectedAccountNo(null);
      if (type == "assignTable") {
        formik.setFieldValue("customer_account_num", "");
      }
      if (type == "requestTable") {
        formik.setFieldValue("customer_acct_number", "");
      }
      if (type == "customerTable") {
        formik.setFieldValue("cus_account_number", "");
      }
    }
  };

  return (
    <Autocomplete
      fullWidth
      popupIcon={<KeyboardArrowDownRoundedIcon sx={{ fontSize: 12, color: "#848484" }} />}
      sx={{
        "& .MuiOutlinedInput-root": {
          flexWrap: "nowrap",
          position: "relative",
          py: 0,
          borderRadius: "0.625rem",
          border: "0",
          "&> .MuiOutlinedInput-input": {
            border: 0,
            "&+ .MuiCircularProgress-root": {
              position: "absolute",
              top: "11.5px",
              right: "35px",
            },
          },
        },
        "& .MuiAutocomplete-popupIndicator": {
          fontSize: "1.4rem !important",
          marginRight: "-10px !important",
        },
      }}
      options={AccountNo}
      getOptionLabel={(option) =>
        `(${option.cus_account_number}) ${
          option.cus_company_name.charAt(0).toUpperCase() + option.cus_company_name.slice(1)
        } `
      }
      value={selectedAccountNo}
      onChange={handleAccountNoChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          onBlur={onBlur}
          InputProps={{
            ...params.InputProps,
            endAdornment: <>{params.InputProps.endAdornment}</>,
          }}
        />
      )}
    />
  );
};

export default CustomerNo;
