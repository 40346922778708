import { Grid } from "@mui/material";
import WrapCard from "Container/Cards/WrapCard";
import { pageSchema } from "Validation";
import { useGetPagesByIdMutation } from "app/features/api/AuthApi";
import { usePostPagesMutation } from "app/features/api/AuthApi";
import ErrorMessageComponent from "components/FormikErrorMessage";
import { useFormik } from "formik";
import useNavigateSearch from "hooks/useNavigateSearch";
import useSuiSnackbar from "hooks/useSuiSnackbar";
import JoditEditor from "jodit-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";

const AssignNewDID = () => {
  const editor = useRef(null);
  const [postPages] = usePostPagesMutation();
  const suiSnackbar = useSuiSnackbar();
  const navigateSearch = useNavigateSearch();
  const { id } = useParams();
  const [getPagesById] = useGetPagesByIdMutation();
  const [data, setData] = useState();
  const config = useMemo(
    () => ({
      readonly: false,
    }),
    []
  );

  const formik = useFormik({
    initialValues: {
      page_name: data?.page_name,
      page_content: data?.page_content,
    },
    enableReinitialize: true,
    validationSchema: pageSchema,
    onSubmit: async (values) => {
      const body = {
        page_content: values.page_content,
        id: id,
      };

      try {
        const response = await postPages(body).unwrap();
        navigateSearch("/pages", { page: 1 });
        suiSnackbar(response.message, "success");
      } catch (error) {
        // setError(error.data.message);
        console.log(error);
      }
    },
  });
  const getPages = async (id) => {
    try {
      const response = await getPagesById(id).unwrap();
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.log(error);
      // setError(error?.data.message);
    }
  };

  const handleEditorChange = (value) => {
    formik.setFieldValue("page_content", value);
  };
  useEffect(() => {
    getPages(id);
  }, []);

  return (
    <WrapCard
      title={data?.page_name}
      onClick={formik.handleSubmit}
      disabled={!formik.dirty || formik.isSubmitting}
      button="Update"
    >
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <JoditEditor
            id="page_content"
            name="page_content"
            ref={editor}
            value={formik.values.page_content}
            config={config}
            onChange={handleEditorChange}
            tabIndex={1}
            // onBlur={(e) => handleBlur(e)}
          />
          <ErrorMessageComponent
            touched={formik.touched.page_content}
            error={formik.errors.page_content}
          />
        </Grid>
        {/* <Grid item sx={{ pb: 1 }}>
          {Object.keys(formik.errors)?.length === 0 && err && (
            <ErrorMessageComponent touched={err} error={err}/>
          )}
        </Grid> */}
      </Grid>
    </WrapCard>
  );
};

export default AssignNewDID;
