import boxShadow from "../functions/boxShadow";

export default {
  styleOverrides: {
    paper: {
      borderRadius: "7px",
      display: "flex",
      margin: "1rem",
      maxHeight: "calc(100% - 22px)",
      width: "calc(100% - 16px)",
      border: "1px solid #EFF4F9",
      overflow: "unset",
      boxShadow: boxShadow("#000", 0.2, [0, 3], [0, 0]),
      "& .close-btn": {
        position: "absolute",
        padding: "2px",
        fontSize: "15px",
        right: "-9px",
        top: "-9px",
      },
    },
  },
};
