import colors from "assets/theme/base/colors";
import pxToRem from "assets/theme/functions/pxToRem";

const CalendarIcon = ({ size = 19, color = "primary", type = "main" }) => {
  return (
    <svg
      height={pxToRem(size)}
      fill={colors[color][type]}
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.8567 2.62605H13.5307V0.883055C13.5362 0.771964 13.519 0.660916 13.4803 0.556652C13.4416 0.452387 13.3821 0.35708 13.3054 0.276514C13.2287 0.195948 13.1364 0.131804 13.0342 0.0879751C12.932 0.0441459 12.8219 0.0215454 12.7107 0.0215454C12.5995 0.0215454 12.4894 0.0441459 12.3872 0.0879751C12.285 0.131804 12.1927 0.195948 12.116 0.276514C12.0393 0.35708 11.9798 0.452387 11.9411 0.556652C11.9024 0.660916 11.8852 0.771964 11.8907 0.883055V2.62605H6.6307V0.883055C6.63618 0.771964 6.61904 0.660916 6.58031 0.556652C6.54158 0.452387 6.48206 0.35708 6.40538 0.276514C6.3287 0.195948 6.23644 0.131804 6.13422 0.0879751C6.03199 0.0441459 5.92192 0.0215454 5.8107 0.0215454C5.69947 0.0215454 5.5894 0.0441459 5.48718 0.0879751C5.38495 0.131804 5.2927 0.195948 5.21601 0.276514C5.13933 0.35708 5.07981 0.452387 5.04108 0.556652C5.00235 0.660916 4.98521 0.771964 4.9907 0.883055V2.62605H2.6647C1.99735 2.64179 1.36351 2.92166 0.902307 3.40424C0.4411 3.88683 0.190206 4.53268 0.204696 5.20005V16.3371C0.190743 17.0041 0.441876 17.6494 0.903026 18.1316C1.36418 18.6137 1.99771 18.8933 2.6647 18.9091H15.8567C16.5237 18.8933 17.1572 18.6137 17.6184 18.1316C18.0795 17.6494 18.3306 17.0041 18.3167 16.3371V5.20005C18.3312 4.53268 18.0803 3.88683 17.6191 3.40424C17.1579 2.92166 16.524 2.64179 15.8567 2.62605ZM16.6737 16.3351C16.6777 16.5572 16.5938 16.7719 16.4402 16.9324C16.2866 17.093 16.0758 17.1863 15.8537 17.1921H2.6617C2.55151 17.1898 2.44285 17.1659 2.34195 17.1216C2.24104 17.0773 2.14988 17.0135 2.07369 16.9339C1.99751 16.8542 1.93779 16.7604 1.89798 16.6576C1.85817 16.5548 1.83904 16.4452 1.8417 16.3351V7.73505H16.6727L16.6737 16.3351ZM16.6737 6.02505H1.8407V5.20106C1.8364 4.97884 1.92024 4.76397 2.07389 4.60338C2.22754 4.4428 2.43851 4.34956 2.6607 4.34405H15.8527C16.0748 4.34982 16.2856 4.44314 16.4392 4.60366C16.5928 4.76419 16.6767 4.97892 16.6727 5.20106L16.6737 6.02505Z" />
      <path d="M4.89512 11.7831H5.22112C5.44348 11.7755 5.65419 11.6818 5.8088 11.5218C5.96341 11.3618 6.04984 11.148 6.04984 10.9256C6.04984 10.7031 5.96341 10.4893 5.8088 10.3293C5.65419 10.1693 5.44348 10.0756 5.22112 10.0681H4.89512C4.67276 10.0756 4.46205 10.1693 4.30744 10.3293C4.15283 10.4893 4.06641 10.7031 4.06641 10.9256C4.06641 11.148 4.15283 11.3618 4.30744 11.5218C4.46205 11.6818 4.67276 11.7755 4.89512 11.7831Z" />
      <path d="M9.09726 11.7831H9.42326C9.64562 11.7755 9.85633 11.6818 10.0109 11.5218C10.1656 11.3618 10.252 11.148 10.252 10.9256C10.252 10.7031 10.1656 10.4893 10.0109 10.3293C9.85633 10.1693 9.64562 10.0756 9.42326 10.0681H9.09726C8.8749 10.0756 8.66419 10.1693 8.50958 10.3293C8.35497 10.4893 8.26855 10.7031 8.26855 10.9256C8.26855 11.148 8.35497 11.3618 8.50958 11.5218C8.66419 11.6818 8.8749 11.7755 9.09726 11.7831Z" />
      <path d="M13.2965 11.7831H13.6225C13.8448 11.7755 14.0555 11.6818 14.2102 11.5218C14.3648 11.3618 14.4512 11.148 14.4512 10.9256C14.4512 10.7031 14.3648 10.4893 14.2102 10.3293C14.0555 10.1693 13.8448 10.0756 13.6225 10.0681H13.2965C13.0741 10.0756 12.8634 10.1693 12.7088 10.3293C12.5542 10.4893 12.4678 10.7031 12.4678 10.9256C12.4678 11.148 12.5542 11.3618 12.7088 11.5218C12.8634 11.6818 13.0741 11.7755 13.2965 11.7831Z" />
      <path d="M4.89512 14.8711H5.22112C5.44348 14.8635 5.65419 14.7698 5.8088 14.6098C5.96341 14.4498 6.04984 14.236 6.04984 14.0136C6.04984 13.7911 5.96341 13.5773 5.8088 13.4173C5.65419 13.2573 5.44348 13.1637 5.22112 13.1561H4.89512C4.67276 13.1637 4.46205 13.2573 4.30744 13.4173C4.15283 13.5773 4.06641 13.7911 4.06641 14.0136C4.06641 14.236 4.15283 14.4498 4.30744 14.6098C4.46205 14.7698 4.67276 14.8635 4.89512 14.8711Z" />
      <path d="M9.09726 14.8711H9.42326C9.64562 14.8635 9.85633 14.7698 10.0109 14.6098C10.1656 14.4498 10.252 14.236 10.252 14.0136C10.252 13.7911 10.1656 13.5773 10.0109 13.4173C9.85633 13.2573 9.64562 13.1637 9.42326 13.1561H9.09726C8.8749 13.1637 8.66419 13.2573 8.50958 13.4173C8.35497 13.5773 8.26855 13.7911 8.26855 14.0136C8.26855 14.236 8.35497 14.4498 8.50958 14.6098C8.66419 14.7698 8.8749 14.8635 9.09726 14.8711Z" />
      <path d="M13.2965 14.8711H13.6225C13.8448 14.8635 14.0555 14.7698 14.2102 14.6098C14.3648 14.4498 14.4512 14.236 14.4512 14.0136C14.4512 13.7911 14.3648 13.5773 14.2102 13.4173C14.0555 13.2573 13.8448 13.1637 13.6225 13.1561H13.2965C13.0741 13.1637 12.8634 13.2573 12.7088 13.4173C12.5542 13.5773 12.4678 13.7911 12.4678 14.0136C12.4678 14.236 12.5542 14.4498 12.7088 14.6098C12.8634 14.7698 13.0741 14.8635 13.2965 14.8711Z" />
    </svg>
  );
};

export default CalendarIcon;
